/* import __COLOCATED_TEMPLATE__ from './upgrade-install-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { PRODUCTS } from 'embercom/lib/billing';

export default class UpgradeInstallBanner extends Component {
  @service declare appService: $TSFixMe;

  get product() {
    return this.appService.app.smsProduct;
  }

  get billingFeatureKey() {
    return (PRODUCTS as any)[this.product.id].key;
  }

  /**
   * Paywalls shouldn't be visible to existing beta customers, who have been moved
   * from the "messages-sms" ff to the "messages-sms-beta" ff.
   * The "messages-sms" ff will be used for showing SMS globally and hence will always be
   * on post launch.
   * For displaying the paywall, a customer should meet the following criteria:
   * - have "messages-sms" ff enabled
   * - shouldn't have the "messages-sms-beta" ff enabled
   * - shouldn't have the "sms" billing feature key enabled
   */
  get paywallActiveOverride() {
    return !this.appService.app.canUseSmsBeta && !this.appService.app?.canUseFeature('sms');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sms::UpgradeInstallBanner': typeof UpgradeInstallBanner;
  }
}
