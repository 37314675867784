/* import __COLOCATED_TEMPLATE__ from './channel-options.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { IntegrationState } from 'embercom/objects/standalone/constants';
import { type ComponentLike } from '@glint/template';
import { type BotConfigTarget } from 'embercom/objects/operator/configuration/configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

interface Args {
  channels: Array<Channel>;
}

export interface Channel {
  icon: InterfaceIconName;
  title: string;
  externalTypeName: string;
  integrationState: IntegrationState;
  setupArgs: ChannelSetupArgs;
  summaryArgs: ChannelSummaryArgs;
}

export interface ChannelSetupArgs {
  description: string;
  route: string;
  imageAssetUrl: string;
  recommendedTooltip?: string;
  sectionTitle: string;
  articleId?: number;
  articleUrl?: string;
}

export interface ChannelSummaryArgs {
  workflowRoute: string;
  manageRoute: string;
  statusPill?: ComponentLike;
  workflowParams: {
    target: BotConfigTarget;
    objectType: number;
  };
}

type GroupedSetupOption = {
  sectionTitle: string;
  options: Channel[];
};

export default class StandaloneDeployOverviewChannelOptions extends Component<Signature> {
  get activatedChannels(): Channel[] {
    return this.args.channels.filter((channel) => !this.setupChannels.includes(channel));
  }

  get setupChannels(): Channel[] {
    return this.args.channels.filter((channel) =>
      [IntegrationState.NotConfigured, IntegrationState.Created].includes(channel.integrationState),
    );
  }

  get groupedSetupOptions(): GroupedSetupOption[] {
    let groupedSetupOptions: GroupedSetupOption[] = [];

    this.setupChannels.forEach((setupChannel) => {
      let section = groupedSetupOptions.find(
        (section) => section.sectionTitle === setupChannel.setupArgs.sectionTitle,
      );
      if (section) {
        section.options.push(setupChannel);
      } else {
        groupedSetupOptions.push({
          sectionTitle: setupChannel.setupArgs.sectionTitle,
          options: [setupChannel],
        });
      }
    });

    return groupedSetupOptions;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Deploy::Overview::ChannelOptions': typeof StandaloneDeployOverviewChannelOptions;
    'standalone/deploy/overview/channel-options': typeof StandaloneDeployOverviewChannelOptions;
  }
}
