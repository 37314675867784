/* import __COLOCATED_TEMPLATE__ from './workflow.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export interface Args {}

export default class Workflow extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get intercomMessengerConfig() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get workflowId() {
    return this.intercomMessengerConfig?.workflowRulesetId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::Workflow': typeof Workflow;
    'standalone/intercom-messenger/deploy/workflow': typeof Workflow;
  }
}
