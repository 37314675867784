/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type Channel } from '../deploy/overview/channel-options';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type IntlService from 'ember-intl/services/intl';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import {
  NEW_API_CONVERSATION_TARGET,
  NEW_CONVERSATION_TARGET,
  NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET,
  NEW_ZENDESK_TICKET_TARGET,
} from 'embercom/lib/operator/custom-bots/constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import StandaloneIntercomMessengerPill from 'embercom/components/standalone/intercom-messenger/deploy/pill';
import StandaloneZendeskTicketsPill from 'embercom/components/standalone/zendesk/deploy/tickets/pill';
import StandaloneZendeskSunshinePill from 'embercom/components/standalone/zendesk/deploy/sunshine/pill';
import type Store from '@ember-data/store';
import type ApiConfiguration from 'embercom/models/standalone/api-configuration';

interface Signature {
  Element: HTMLDivElement;
  Args: {};
}

export default class StandaloneDeployOverview extends Component<Signature> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare appService: { app: { canUseFeature: (feature: string) => boolean; id: string } };
  @service declare intl: IntlService;
  @service declare store: Store;

  get availableGenericChannels(): Channel[] {
    let availableChannels: Channel[] = [
      {
        // Intercom Messenger channel
        icon: 'messenger',
        title: this.intl.t('standalone.intercom-messenger.messenger-card-title'),
        externalTypeName: 'conversation',
        integrationState: this.finStandaloneService.intercomMessengerConfig.integrationState,
        setupArgs: {
          description: this.intl.t(
            'standalone.intercom-messenger.messenger-card-description-generic',
          ),
          route: 'apps.app.standalone.deploy.intercom-messenger',
          imageAssetUrl: assetUrl('/assets/images/standalone/ic-messenger.jpg'),
          sectionTitle: this.intl.t(
            'standalone.deploy.overview.generic.let-fin-answer-your-live-chats',
          ),
        },
        summaryArgs: {
          workflowRoute: 'apps.app.standalone.deploy.intercom-messenger.workflow.show',
          manageRoute: 'apps.app.standalone.deploy.intercom-messenger.go-live',
          statusPill: StandaloneIntercomMessengerPill,
          workflowParams: {
            target: NEW_CONVERSATION_TARGET,
            objectType: objectTypes.inboundCustomBot,
          },
        },
      },
    ];

    if (this.finOverApiChannel) {
      availableChannels.push(this.finOverApiChannel);
    }

    return availableChannels;
  }

  get availableZendeskChannels(): Channel[] {
    let availableChannels: Channel[] = [
      {
        // Intercom Messenger channel
        icon: 'messenger',
        title: this.intl.t('standalone.intercom-messenger.messenger-card-title'),
        externalTypeName: 'conversation',
        integrationState: this.finStandaloneService.intercomMessengerConfig.integrationState,
        setupArgs: {
          description: this.intl.t(
            'standalone.intercom-messenger.messenger-card-description-for-zendesk',
          ),
          route: 'apps.app.standalone.deploy.intercom-messenger',
          imageAssetUrl: assetUrl('/assets/images/standalone/ic-messenger.jpg'),
          sectionTitle: this.intl.t('standalone.deploy.overview.zendesk.setup-chat-channels'),
          articleId: 10725654,
          articleUrl:
            'https://fin.ai/help/en/articles/10725654-fin-in-the-intercom-messenger-how-does-it-work',
        },
        summaryArgs: {
          workflowRoute: 'apps.app.standalone.deploy.intercom-messenger.workflow.show',
          manageRoute: 'apps.app.standalone.deploy.intercom-messenger.go-live',
          statusPill: StandaloneIntercomMessengerPill,
          workflowParams: {
            target: NEW_CONVERSATION_TARGET,
            objectType: objectTypes.inboundCustomBot,
          },
        },
      },
      {
        // Zendesk Tickets channel
        icon: 'messenger-ticket-filled',
        title: this.intl.t('standalone.channels.overview.cards.zendesk-tickets.title'),
        externalTypeName: 'ticket',
        integrationState: this.finStandaloneService.zendeskConfig!.ticketState,
        setupArgs: {
          description: this.intl.t(
            'standalone.channels.overview.cards.zendesk-tickets.description',
          ),
          route: 'apps.app.standalone.deploy.zendesk.tickets',
          imageAssetUrl: assetUrl('/assets/images/standalone/zd-tickets.jpg'),
          sectionTitle: this.intl.t('standalone.deploy.overview.zendesk.setup-ticket-channels'),
          articleId: 10729234,
          articleUrl:
            'https://fin.ai/help/en/articles/10729234-fin-for-zendesk-tickets-how-does-it-work',
        },
        summaryArgs: {
          workflowRoute: 'apps.app.standalone.deploy.zendesk.tickets.workflow.show',
          manageRoute: 'apps.app.standalone.deploy.zendesk.tickets.go-live',
          statusPill: StandaloneZendeskTicketsPill,
          workflowParams: {
            target: NEW_ZENDESK_TICKET_TARGET,
            objectType: objectTypes.triggerableCustomBot,
          },
        },
      },
      {
        // Zendesk Messaging channel
        icon: 'zendesk-messenger',
        title: this.intl.t('standalone.channels.overview.cards.zendesk-messaging.title'),
        externalTypeName: 'conversation',
        integrationState:
          this.finStandaloneService.zendeskConfig!.sunshineIntegration.sunshineMessengerState,
        setupArgs: {
          description: this.intl.t(
            'standalone.channels.overview.cards.zendesk-messaging.description',
          ),
          route: 'apps.app.standalone.deploy.zendesk.messaging',
          imageAssetUrl: assetUrl('/assets/images/standalone/zd-messenger.jpg'),
          sectionTitle: this.intl.t('standalone.deploy.overview.zendesk.setup-chat-channels'),
          articleId: 10729663,
          articleUrl:
            'https://fin.ai/help/en/articles/10729663-fin-for-zendesk-messaging-how-does-it-work',
        },
        summaryArgs: {
          workflowRoute: 'apps.app.standalone.deploy.zendesk.messaging.workflow.show',
          manageRoute: 'apps.app.standalone.deploy.zendesk.messaging.go-live',
          statusPill: StandaloneZendeskSunshinePill,
          workflowParams: {
            target: NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET,
            objectType: objectTypes.triggerableCustomBot,
          },
        },
      },
    ];

    if (this.finOverApiChannel) {
      availableChannels.push(this.finOverApiChannel);
    }

    return availableChannels;
  }

  get finOverApiChannel(): Channel | undefined {
    if (this.appService.app.canUseFeature('team-standalone-fin-over-api')) {
      let apiConfig = this.store.peekRecord(
        'standalone/api-configuration',
        this.appService.app.id,
      ) as ApiConfiguration;
      return {
        // Fin over API channel
        icon: 'code',
        title: this.intl.t('standalone.deploy.api.title'),
        externalTypeName: 'fin-over-api',
        integrationState: apiConfig.integrationState,
        setupArgs: {
          description: this.intl.t('standalone.deploy.api.description'),
          route: 'apps.app.standalone.deploy.api',
          imageAssetUrl: assetUrl('/assets/images/standalone/fin-over-api.png'),
          sectionTitle: this.intl.t(
            'standalone.deploy.overview.generic.let-fin-answer-your-live-chats',
          ),
        },
        summaryArgs: {
          workflowRoute: 'apps.app.standalone.deploy.api.workflow',
          manageRoute: 'apps.app.standalone.deploy.api',
          workflowParams: {
            target: NEW_API_CONVERSATION_TARGET,
            objectType: objectTypes.triggerableCustomBot,
          },
        },
      };
    }

    return undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Deploy::Overview': typeof StandaloneDeployOverview;
    'standalone/deploy/overview': typeof StandaloneDeployOverview;
  }
}
