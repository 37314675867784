/* import __COLOCATED_TEMPLATE__ from './inbox-adapter.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const InboxAdapter = templateOnlyComponent<Signature>();
export default InboxAdapter;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Adapters::InboxAdapter': typeof InboxAdapter;
  }
}
