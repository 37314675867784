/* import __COLOCATED_TEMPLATE__ from './pill.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { IntegrationState } from 'embercom/objects/standalone/constants';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';

export interface Args {}

export default class Pill extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;

  get zendeskConfig(): ZendeskConfiguration | undefined {
    return this.finStandaloneService.zendeskConfig;
  }

  get integrationState(): IntegrationState {
    return this.zendeskConfig?.ticketState ?? IntegrationState.NotConfigured;
  }

  get stampText() {
    switch (this.integrationState) {
      case IntegrationState.Enabled:
        return this.intl.t('standalone.zendesk.deploy.tickets.integration-state.live');
      case IntegrationState.Paused:
        return this.intl.t('standalone.zendesk.deploy.tickets.integration-state.paused');
      default:
        return this.intl.t('standalone.zendesk.deploy.tickets.integration-state.not-set-up');
    }
  }

  get stampColor() {
    switch (this.integrationState) {
      case IntegrationState.Enabled:
        return 'green';
      case IntegrationState.Paused:
        return 'yellow';
      default:
        return 'gray';
    }
  }

  get stampIcon() {
    switch (this.integrationState) {
      case IntegrationState.Enabled:
        return 'rounded-check';
      case IntegrationState.Paused:
        return 'pause';
      default:
        return undefined;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::Pill': typeof Pill;
    'standalone/zendesk/deploy/tickets/pill': typeof Pill;
  }
}
