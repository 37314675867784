/* import __COLOCATED_TEMPLATE__ from './assign.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';

interface Args {
  onNext?: () => void;
}

export default class StandaloneZendeskAssign extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::Assign': typeof StandaloneZendeskAssign;
    'standalone/zendesk/deploy/tickets/assign': typeof StandaloneZendeskAssign;
  }
}
