/* import __COLOCATED_TEMPLATE__ from './color-customisation-panel.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type OutboundContent from 'embercom/models/customization-options/outbound-content';
import { BackgroundType } from 'embercom/models/customization-options/outbound-content';
import { type MediaHelper } from 'embercom/objects/media/media-helper';

export interface Args {
  customizationOptions: OutboundContent;
  mediaUploadHelper?: MediaHelper;
  backgroundImageUrl?: string;
  onBackgroundImageRemoved?: () => void;
  canUseGradient?: boolean;
  maxGradientSteps?: number;
  canUseBackgroundFade?: boolean;
  isLightMode?: boolean;
  backgroundImageRatio?: string;
  backgroundImageFileSize?: string;
  onOptionUpdate?: (option: OutboundContent) => void;
  customTooltipContentComponent?: string;
}

const DEFAULT_IMAGE_BACKGROUND_RATIO = '5:2';

export default class ColorCustomisationPanel extends Component<Args> {
  get selectedBackgroundType() {
    if (
      this.hasBackgroundImage() ||
      this.args.customizationOptions.backgroundType === BackgroundType.IMAGE
    ) {
      return BackgroundType.IMAGE;
    } else if (
      this.args.customizationOptions.hasGradient() ||
      this.args.customizationOptions.backgroundType === BackgroundType.GRADIENT
    ) {
      return BackgroundType.GRADIENT;
    } else {
      return BackgroundType.COLOR;
    }
  }

  set selectedBackgroundType(type: BackgroundType) {
    this.args.customizationOptions.backgroundType = type;
    if (type === BackgroundType.COLOR) {
      this.handleTypeColorClick();
    } else if (type === BackgroundType.GRADIENT) {
      this.handleTypeGradientClick();
    }
  }

  hasBackgroundImage(): boolean {
    return !!this.args.backgroundImageUrl || !!this.args.mediaUploadHelper?.hasFile;
  }

  get canUseImageBackground(): boolean {
    return !!this.args.mediaUploadHelper;
  }

  get maxGradientSteps(): number {
    return this.args.maxGradientSteps || 5;
  }

  handleTypeColorClick(): void {
    this.args.customizationOptions.useLastSelectedColor();
    this.removeBackgroundImage();
    this.args.customizationOptions.removeGradient();
  }

  handleTypeGradientClick(): void {
    if (!this.args.customizationOptions.backgroundGradient) {
      this.args.customizationOptions.createGradient();
    }
    this.args.customizationOptions.removeColor();
    this.removeBackgroundImage();
    this.emitChange();
  }

  removeBackgroundImage(): void {
    if (!this.args.mediaUploadHelper) {
      return;
    }
    if (this.args.backgroundImageUrl) {
      this.args.mediaUploadHelper.removeExistingFile();
      this.args.onBackgroundImageRemoved?.();
    } else {
      this.args.mediaUploadHelper.resetIfHasFile();
    }
    this.args.customizationOptions.removeImage();
    this.emitChange();
  }

  get backgroundImageRatio(): string {
    return this.args.backgroundImageRatio || DEFAULT_IMAGE_BACKGROUND_RATIO;
  }

  @action
  handleBackgroundImageChange(): void {
    this.args.customizationOptions.removeColor();
    this.args.customizationOptions.removeGradient();
    this.args.customizationOptions.backgroundImageUrl = this.args.mediaUploadHelper?.url || null; // this is needed for the preview to work
    this.emitChange();
  }

  get hasMultipleChoices() {
    return this.canUseImageBackground || this.args.canUseGradient;
  }

  @action
  toggleBackgroundFade() {
    this.args.customizationOptions.fadeToEdge = !this.args.customizationOptions.fadeToEdge;
    this.emitChange();
  }

  @action
  updateBackgroundColor(color: string) {
    this.args.customizationOptions.selectColor(color);
    this.emitChange();
  }

  @action
  emitChange() {
    if (!this.args.onOptionUpdate) {
      return;
    }

    this.args.onOptionUpdate(this.args.customizationOptions);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Appearance::ColorCustomisationPanel': typeof ColorCustomisationPanel;
  }
}
