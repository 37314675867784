/* import __COLOCATED_TEMPLATE__ from './conditions-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type Condition from 'embercom/models/conversation-attributes/condition';
import type ListOption from 'embercom/models/conversation-attributes/list-option';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import TruncateString from 'embercom/lib/truncate-string';

interface Args {
  targetAttribute: ConversationAttributeDescriptor;
  allAttributes: ConversationAttributeDescriptor[];
  onClose: () => void;
  onSave: () => void;
}

const MAX_OPTION_LABEL_LENGTH = 55;

export default class ConditionsSideDrawer extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: IntercomConfirmService;

  get availableControllingAttributes() {
    return this.args.allAttributes
      .rejectBy('archived')
      .rejectBy('id', this.args.targetAttribute.id)
      .filterBy('dataType', 'list')
      .map((attribute) => {
        return { text: attribute.displayName, value: attribute.id };
      });
  }

  get visibleConditions() {
    return this.args.targetAttribute.conditions.filterBy('isDeleted', false);
  }

  get isInvalid() {
    return this.args.targetAttribute.conditions.any((condition: Condition) => {
      return !condition.controllingDescriptorId || !condition.controllingListOptionId;
    });
  }

  get canAddCondition() {
    return this.visibleConditions.length < 100;
  }

  get hasUnsavedChanges() {
    return this.args.targetAttribute.conditions.any((condition: Condition) => {
      return condition.hasDirtyAttributes;
    });
  }

  get canSetConditionalValues() {
    return this.args.targetAttribute.dataType === 'list';
  }

  @action conditionalValues(condition: Condition) {
    if (this.args.targetAttribute.dataType !== 'list') {
      return [];
    }

    return [
      {
        text: this.intl.t('settings.conversation-attributes.conditions-side-drawer.all-options'),
        value: '',
        isSelected: condition.descriptorListOptionIds.length === 0,
      },
    ].concat(
      this.args.targetAttribute.listOptions.rejectBy('archived').map((option: ListOption) => {
        return {
          text: option.trimmedLabel,
          value: option.listOptionId,
          isSelected: condition.descriptorListOptionIds.includes(option.listOptionId),
        };
      }),
    );
  }

  @action async onClose() {
    if (!this.hasUnsavedChanges) {
      return this.args.onClose();
    }

    let confirmed = await this.intercomConfirmService.confirm({
      title: this.intl.t('settings.conversation-attributes.unsaved-changes-modal.label'),
      body: this.intl.t('settings.conversation-attributes.unsaved-changes-modal.description'),
      confirmButtonText: this.intl.t(
        'settings.conversation-attributes.unsaved-changes-modal.continue',
      ),
      cancelButtonText: this.intl.t(
        'settings.conversation-attributes.unsaved-changes-modal.cancel',
      ),
    });
    if (confirmed) {
      this.args.targetAttribute.conditions
        .toArray()
        .forEach((condition: Condition) => condition.rollbackAttributes());
      this.args.onClose();
    }
  }

  @action onAddCondition() {
    this.store.createRecord('conversation-attributes/condition', {
      controllingDescriptorId: undefined,
      controllingListOptionId: undefined,
      descriptorListOptionIds: [],
      descriptor: this.args.targetAttribute,
      descriptorId: this.args.targetAttribute.id,
    });
  }

  @action onRemoveCondition(index: number) {
    this.args.targetAttribute.conditions.objectAt(index).deleteRecord();
    this.args.targetAttribute.conditions = this.args.targetAttribute.conditions;
  }

  @action onSelectControllingAttribute(index: number, value: string) {
    this.args.targetAttribute.conditions.objectAt(index).controllingDescriptorId = value;
    this.args.targetAttribute.conditions.objectAt(index).controllingListOptionId = undefined;
  }

  @action onSelectControllingAttributeListOption(index: number, value: string) {
    this.args.targetAttribute.conditions.objectAt(index).controllingListOptionId = value;
  }

  @action onSelectConditionalValues(index: number, options: string[]) {
    let currentValue = this.args.targetAttribute.conditions
      .objectAt(index)
      .descriptorListOptionIds.toArray();
    if (options.includes('') && currentValue.length > 0) {
      this.args.targetAttribute.conditions.objectAt(index).descriptorListOptionIds = [];
    } else {
      this.args.targetAttribute.conditions.objectAt(index).descriptorListOptionIds = options.filter(
        (option) => option !== '',
      );
    }
  }

  @action conditionalValuesLabel(condition: Condition) {
    if (condition.descriptorListOptionIds.length === 0) {
      return this.intl.t('settings.conversation-attributes.conditions-side-drawer.all-options');
    }

    return TruncateString(
      condition.descriptorListOptionIds
        .map((optionId: string) => {
          let option = this.args.targetAttribute.listOptions.findBy('listOptionId', optionId);
          return option ? option.trimmedLabel : '';
        })
        .join(', '),
      MAX_OPTION_LABEL_LENGTH,
      false,
    );
  }

  @action controllingAttributeListOptionsItems(
    controllingDescriptorId: string,
    selectedValue: string,
  ) {
    if (!controllingDescriptorId || !this.args.allAttributes) {
      return [];
    }

    let selectedOption = this.args.allAttributes.findBy('id', controllingDescriptorId);
    if (!selectedOption) {
      return [];
    }
    let alreadyUsedOptions = this.args.targetAttribute.conditions
      .filterBy('controllingDescriptorId', controllingDescriptorId)
      .filterBy('isDeleted', false)
      .map((option: Condition) => option.controllingListOptionId);

    return selectedOption.listOptions.rejectBy('archived').map((option: ListOption) => {
      let additionalOptions = {};
      if (
        option.listOptionId !== selectedValue &&
        alreadyUsedOptions.includes(option.listOptionId)
      ) {
        additionalOptions = {
          isDisabled: true,
          tooltip: {
            text: this.intl.t(
              'settings.conversation-attributes.conditions-side-drawer.already-selected-error',
            ),
            isDelayed: false,
          },
        };
      }

      return {
        text: option.trimmedLabel,
        value: option.listOptionId,
        ...additionalOptions,
      };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::ConversationAttributes::ConditionsSideDrawer': typeof ConditionsSideDrawer;
    'settings/conversation-attributes/conditions-side-drawer': typeof ConditionsSideDrawer;
  }
}
