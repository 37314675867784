/* import __COLOCATED_TEMPLATE__ from './channel-setup-card.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { IntegrationState } from 'embercom/objects/standalone/constants';

export interface Args {
  title: string;
  description: string;
  image: string;
  recommendedTooltip?: string;
  setupRoute: string;
  integrationState: IntegrationState;
  channelIcon: InterfaceIconName;
  alternateBackground?: boolean;
  articleId?: number;
  articleUrl?: string;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class StandaloneDeployOverviewChannelSetupCard extends Component<Signature> {
  get isSettingUp() {
    return this.args.integrationState !== IntegrationState.NotConfigured;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Deploy::Overview::ChannelSetupCard': typeof StandaloneDeployOverviewChannelSetupCard;
    'standalone/deploy/overview/channel-setup-card': typeof StandaloneDeployOverviewChannelSetupCard;
  }
}
