/* import __COLOCATED_TEMPLATE__ from './conversion-type-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  showExpiredTrialCheckout: boolean;
  solutionId: string;
  planId: number;
  billingPeriod?: string;
  seatNumber?: number;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class ConversionTypeSwitcher extends Component<Signature> {
  @service declare intl: any;
  @service declare purchaseAnalyticsService: any;
  @service declare customerService: any;
  @service declare appService: any;

  get isLocalCustomer() {
    return (
      !this.customerService.isEarlyStageProgression &&
      !this.customerService.customer.isStripeCustomer
    );
  }

  get isEarlyStageProgression() {
    return this.customerService.isEarlyStageProgression;
  }

  get isValidEarlyStageCustomerWithoutEarlyStageSubscription() {
    return (
      this.customerService.customer.validEarlyStageApplicant &&
      !this.customerService.customer.currentlyOnEarlyStage
    );
  }

  get showExpiredTrialCheckout() {
    return this.args.showExpiredTrialCheckout;
  }

  get isP51SelfServeMonthlyCustomer() {
    return this.customerService.isP51SelfServeMonthlyCustomer;
  }

  get canPurchaseSelfServeStandalone() {
    return this.appService.app.isStandaloneApp && this.canConvertToPaid;
  }

  get canConvertToPaid() {
    return (
      !this.customerService.customer.hasActiveSubscription ||
      this.customerService.customer.inTrial ||
      this.customerService.customer.hasActiveBillingAdminTrialSubscription
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Checkouts::ConversionTypeSwitcher': typeof ConversionTypeSwitcher;
  }
}
