/* import __COLOCATED_TEMPLATE__ from './color-input.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}
interface Args {
  value: string;
  onColorChange: (newColor: string) => void;
  canRemove?: boolean;
  onColorRemove?: (event: MouseEvent) => void;
}

export default class ColorInput extends Component<Signature> {
  @service declare helpCenterService: any;
  @service declare appService: any;
  @service declare intercomEventService: any;

  get app() {
    return this.appService.app;
  }

  get hasSiteBrandColors() {
    return this.helpCenterService?.site?.customizationOptions?.global?.brand?.colors?.length > 0;
  }

  get siteBrandColors() {
    return this.helpCenterService.site.customizationOptions.global.brand.colors;
  }

  @action
  trackBrandColorSelectedEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'brand_color_selected',
      object: 'help_center_site',
      place: 'help_center_site_styling',
      app_id: this.app.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Appearance::ColorInput': typeof ColorInput;
    'settings/appearance/color-input': typeof ColorInput;
  }
}
