/* import __COLOCATED_TEMPLATE__ from './basket.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import type QuoteService from 'embercom/services/quote-service';
import type Quote from 'embercom/models/quote';
import { FIN_PRICE_PER_RESOLUTION } from 'embercom/lib/billing';

export interface Args {
  quote?: Quote;
  yearlyBillingPeriodSelected: boolean;
  showDiscount: boolean;
  selectedPlanId: string;
  seatNumber: number;
  additionalSeatNumber?: number;
  selectedProactiveAddon: boolean;
  selectedCopilotAddon: boolean;
  selectedNumberOfCopilotAdmins: number;
  fullSeatPrice: number;
  loading: boolean;
  discountedSeatPrice: number;
  fullProactiveAddonPrice: number;
  discountedProactiveAddonPrice: number;
  fullCopilotAddonPrice: number;
  discountedCopilotAddonPrice?: number;
  monthlyFullSeatPrice: number;
  monthlyDiscountedSeatPrice: number;
  monthlyAdditionalSeatPrice?: number;
  monthlyDiscountedAdditionalSeatPrice?: number;
  monthlyDiscountedTotal: number;
  monthlyCopilotPriceForSeats: number;
  monthlyTotal: number;
  hasCopilotEarlyStageDiscount?: boolean;
  totalCopy: string;
  totalPriceFormatted: string;
  showAdditionalSeats?: boolean;
  monthlyResolutionAllowance?: number;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
  Blocks: {
    header?: any;
    beforeTotalBlock: any;
    afterTotalBlock?: any;
    basketButton: any;
  };
}

export default class Basket extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: any;
  @service declare quoteService: QuoteService;

  get subHeading() {
    return this.intl.t(
      `signup.teams.pricing5.annual-plans.basket.subheading.${this.args.selectedPlanId}`,
    );
  }

  get resolutionPrice() {
    return FIN_PRICE_PER_RESOLUTION;
  }

  get actualSeatPrice() {
    return this.args.showDiscount ? this.args.discountedSeatPrice : this.args.fullSeatPrice;
  }

  get isProactiveAddonDiscounted() {
    return this.args.fullProactiveAddonPrice > this.args.discountedProactiveAddonPrice;
  }

  get yearlySavings() {
    return this.quoteService.formatPrice(
      this.args.monthlyTotal * 12 - this.args.monthlyDiscountedTotal * 12,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::Basket': typeof Basket;
  }
}
