/* import __COLOCATED_TEMPLATE__ from './pill.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { IntegrationState } from 'embercom/objects/standalone/constants';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';

interface StampConfig {
  label: string;
  color: 'yellow' | 'gray' | 'green' | 'red';
  icon?: InterfaceIconName;
}

interface Args {}

export default class StandaloneZendeskDeploySunshinePill extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }

  get sunshineIntegration() {
    return this.zendeskConfig.sunshineIntegration;
  }

  get stampConfig(): StampConfig {
    switch (this.sunshineIntegration.sunshineMessengerState) {
      case IntegrationState.TestMode:
        return {
          label: this.intl.t('standalone.zendesk.deploy.sunshine.testing-stamp'),
          color: 'gray',
          icon: 'test',
        };
      case IntegrationState.Enabled:
        return {
          label: this.intl.t('standalone.zendesk.deploy.sunshine.live'),
          color: 'green',
          icon: 'rounded-check',
        };
      case IntegrationState.Paused:
        return {
          label: this.intl.t('standalone.zendesk.deploy.sunshine.paused'),
          color: 'yellow',
          icon: 'pause',
        };
      default:
        return {
          label: this.intl.t('standalone.zendesk.deploy.sunshine.not-set-up'),
          color: 'gray',
        };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine::Pill': typeof StandaloneZendeskDeploySunshinePill;
    'standalone/zendesk/deploy/sunshine/pill': typeof StandaloneZendeskDeploySunshinePill;
  }
}
