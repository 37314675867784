/* import __COLOCATED_TEMPLATE__ from './deploy.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Tab } from 'embercom/components/standalone/common/tab-control';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';

export interface Args {
  activeStep: 'integrate' | 'workflow' | 'test' | 'go-live' | 'assign';
  openSection: string | null;
  setSection?: (section: string | null) => void;
}

export default class Deploy extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @tracked selectedStep: Tab;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.selectedStep =
      this.deploySteps.find((step) => step.value === args.activeStep) ?? this.deploySteps[0];
  }

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig;
  }

  get visualBuilderObject() {
    return this.zendeskConfig?.ticketsIntegration.ruleset.value?.rulesetLinks.firstObject.object
      .visualBuilderObject;
  }

  get isWorkflowStepComplete() {
    return (
      this.zendeskConfig?.isEnabledForTickets ||
      (this.visualBuilderObject?.validations.isValid && this.selectedStep?.stepNumber > 2)
    );
  }

  get deploySteps(): Array<Tab> {
    return [
      {
        stepNumber: 1,
        label: this.intl.t('standalone.zendesk.deploy.tickets.tabs.integrate'),
        value: 'integrate',
        path: 'apps.app.standalone.deploy.zendesk.tickets.integrate',
        isCompleted: this.zendeskConfig?.isConnected || false,
      },
      {
        stepNumber: 2,
        label: this.intl.t('standalone.zendesk.deploy.tickets.tabs.workflow'),
        value: 'workflow',
        path: 'apps.app.standalone.deploy.zendesk.tickets.workflow',
        isCompleted: this.isWorkflowStepComplete,
      },
      {
        stepNumber: 3,
        label: this.intl.t('standalone.zendesk.deploy.tickets.tabs.test'),
        value: 'test',
        path: 'apps.app.standalone.deploy.zendesk.tickets.test',
        isCompleted:
          this.selectedStep?.stepNumber > 3 || this.zendeskConfig?.isEnabledForTickets || false,
      },
      {
        stepNumber: 4,
        label: this.intl.t('standalone.zendesk.deploy.tickets.tabs.assign'),
        value: 'assign',
        path: 'apps.app.standalone.deploy.zendesk.tickets.assign',
        isCompleted:
          this.selectedStep?.stepNumber > 4 || this.zendeskConfig?.isEnabledForTickets || false,
      },
      {
        stepNumber: 5,
        label: this.intl.t('standalone.zendesk.deploy.tickets.tabs.go-live'),
        value: 'go-live',
        path: 'apps.app.standalone.deploy.zendesk.tickets.go-live',
        isCompleted: this.zendeskConfig?.isEnabledForTickets || false,
      },
    ];
  }

  @action
  setStep(stepValue: string) {
    let step = this.deploySteps.find((step) => step.value === stepValue);
    if (step) {
      this.selectedStep = step;
      if (step.path) {
        this.router.transitionTo(step.path);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Tickets::Deploy': typeof Deploy;
    'standalone/zendesk/tickets/deploy': typeof Deploy;
  }
}
