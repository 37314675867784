/* import __COLOCATED_TEMPLATE__ from './workflow.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { inject as service } from '@ember/service';

interface Args {
  changeTab: (tab: string) => void;
}

export default class StandaloneZendeskDeploySunshineTabsWorkflow extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine::Tabs::Workflow': typeof StandaloneZendeskDeploySunshineTabsWorkflow;
    'standalone/zendesk/deploy/sunshine/tabs/workflow': typeof StandaloneZendeskDeploySunshineTabsWorkflow;
  }
}
