/* import __COLOCATED_TEMPLATE__ from './role-editor-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { Promise as EmberPromise } from 'rsvp';

export default class RoleEditorButtons extends Component {
  @service notificationsService;
  @service appService;
  @service router;

  @tracked showConfirmModal = false;
  @tracked showApplyChangesModal = false;

  resolveAcceptedFunction;
  rejectAcceptedFunction;

  showRoleCantBeRemovedModal() {
    this.showConfirmModal = true;
  }

  get goToAfterCancel() {
    return 'apps.app.settings.workspace.teammates';
  }

  @action
  onCancel() {
    this.router.transitionTo(this.goToAfterCancel, {
      queryParams: {
        tab: 'roles',
      },
    });
  }

  @action
  closeConfirmModal() {
    this.showConfirmModal = false;
  }

  @action
  deleteRole() {
    if (this.args.role.admins.length > 0) {
      this.showRoleCantBeRemovedModal();
      return;
    }
    this.args.deleteRole();
  }

  async confirmApplyChanges() {
    let { role } = this.args;
    try {
      role.validate();
      if (role.numberOfPermissionsChanged() === 0 && !role.conversationAccessHasChanged) {
        return true;
      } else {
        this.showApplyChangesModal = true;

        return new EmberPromise((resolve, reject) => {
          this.resolveAcceptedFunction = () => {
            resolve(true);
          };
          this.rejectAcceptedFunction = () => {
            reject(false);
          };
        });
      }
    } catch (error) {
      this.notificationsService.notifyError(error.message);
    }
  }

  @action
  async applyChanges() {
    if (await this.confirmApplyChanges()) {
      this.args.saveChanges();
    }
  }

  @action
  onChangesAccepted() {
    this.resolveAcceptedFunction();
    this.showApplyChangesModal = false;
  }

  @action
  onChangesRejected() {
    this.rejectAcceptedFunction();
    this.showApplyChangesModal = false;
  }
}
