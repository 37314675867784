/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class Header extends Component {
  @service intercomEventService;
  @service appService;

  showTour = () => {
    let tourId = 530470;
    window.Intercom('startTour', tourId);
    this.triggerAnalytics('take_a_tour_option');
  };

  showManageArticle = () => {
    window.Intercom('showArticle', 6433193); // https://www.intercom.com/help/en/articles/6433193-creating-and-managing-macros
    this.triggerAnalytics('create_and_manage_macros_option');
  };

  showExampleArticle = () => {
    window.Intercom('showArticle', 7126418); // https://www.intercom.com/help/en/articles/7126418-example-macros-and-best-practices
    this.triggerAnalytics('example_macros_best_practises_option');
  };

  triggerAnalytics(object) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      context: `onboarding_guide_library.settings.macros.header.learn_dropdown`,
      place: 'settings.saved-replies-macros',
      section: 'learn_dropdown_item',
    });
  }
}
