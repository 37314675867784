/* import __COLOCATED_TEMPLATE__ from './language-select.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';

interface Signature {
  Args: {};
  Blocks: any;
}

export default class LanguageSelect extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare media: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  get selectedLocale() {
    return this.intl.primaryLocale;
  }

  get locale() {
    return this.localeList.filter(
      (item) => item.value.toUpperCase() === this.selectedLocale.toUpperCase(),
    )[0];
  }

  get localeList() {
    return this.intl.supportedLanguages.map(({ locale: value, language: text }) => {
      return {
        value,
        text,
        onSelectItem: () => {
          this.changeLocale(value);
        },
      };
    });
  }

  @action async changeLocale(newLocale: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'language_select_dropdown',
      place: 'language-select',
      initialLocale: this.selectedLocale,
      selectedLocale: newLocale,
    });

    await this.intl.switchLocale(newLocale);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::LanguageSelect': typeof LanguageSelect;
    'signup/teams/language-select': typeof LanguageSelect;
  }
}
