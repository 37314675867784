/* import __COLOCATED_TEMPLATE__ from './form-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import EmberObject, { observer } from '@ember/object';
import { alias, and, bool, not } from '@ember/object/computed';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { typeOf } from '@ember/utils';
import { getDomainName } from 'embercom/lib/email';
import Admin from 'embercom/models/admin';
import checkEmailIsPersonal from 'embercom/utils/check-email-domain';

export default Component.extend({
  purchaseAnalyticsService: service(),
  tagName: '',
  iamService: service(),
  router: service(),
  redirectService: service(),
  intl: service(),

  hasLocale: bool('locale'),
  hasEmailSubmissionId: bool('emailSubmissionId'),
  hasSignedUpWithSolutionId: bool('solutionId'),
  hasSignedUpFromOperatorPage: bool('operatorPage'),
  hasInviteToken: bool('inviteToken'),
  notViaSingleSignOn: not('viaSingleSignOn'),
  showSSO: and('notViaSingleSignOn', 'allowSSO'),
  context: 'signup',

  showOptionalFields: true,
  showNameField: true,
  showCompanyField: true,

  viaSingleSignOn: false,
  allowSSO: true,
  createApp: true,
  buttonCta: null,
  inviteToken: null,
  personalEmailDomain: false,

  allowSaml: false,
  samlAccountName: '',
  showSaml: and('allowSaml', 'samlAccountName'),

  allowEmailPassword: false,

  name: '',
  email: '',
  password: '',
  appName: '',
  companySize: null,
  department: null,

  adminErrors: EmberObject.create({
    name: null,
    email: null,
    password: null,
  }),
  appErrors: EmberObject.create({
    name: null,
  }),
  networkError: null,
  subscriptionErrors: null,
  cardError: null,
  appNameError: alias('appErrors.name'),
  emailError: alias('adminErrors.email'),
  nameError: alias('adminErrors.name'),
  passwordError: alias('adminErrors.password'),

  checkEmailDomainDebounce: observer({
    dependentKeys: ['email'],

    fn() {
      debounce(this, this.checkEmailDomain, 500);
    },

    sync: true,
  }),

  async checkEmailDomain() {
    let personal_email_domain = await checkEmailIsPersonal(getDomainName(this.email));
    this.set('personalEmailDomain', personal_email_domain);
  },

  resetErrors() {
    this.setProperties({
      adminErrors: {},
      appErrors: {},
      networkError: null,
      cardError: null,
    });
  },

  handleCreationError(response) {
    if (!response) {
      return;
    }

    this.resetErrors();

    response = response.jqXHR ? response.jqXHR : response;

    if (response.status >= 500) {
      return this.set('networkError', 'Something went wrong. Please contact support.');
    }

    if (response.status === 429) {
      return this.set('networkError', 'Too many signup attempts, please try again later.');
    }

    let responseJSON = response.responseJSON;

    if (!responseJSON) {
      return;
    }

    let { unsupported_locale, adblocker, admin, app, email, name, password, token } = responseJSON;

    if (adblocker) {
      return this.set('networkError', 'Please disable your ad blocker, refresh, and try again.');
    }

    if (unsupported_locale) {
      return this.set('networkError', 'The locale you have signed up with is not supported.');
    }

    if (admin) {
      this.set('adminErrors', admin);
      this.purchaseAnalyticsService.trackEvent({
        action: 'triggered',
        object: 'teammate_signup_error',
        context: this.context,
        place: 'signup',
        owner: 'growth',
        errors: admin,
        solutionId: this.solutionId,
      });
    }

    if (app) {
      this.set('appErrors', app);
      this.purchaseAnalyticsService.trackEvent({
        action: 'triggered',
        object: 'app_signup_error',
        context: this.context,
        place: 'signup',
        owner: 'growth',
        errors: app,
        solutionId: this.solutionId,
      });
    }

    if (email || name || password) {
      let emailError = email;
      if (emailError === 'Email has already been taken') {
        emailError = this.intl.t('signup.teams.start.form.errors.email_already_taken_cta', {
          htmlSafe: true,
        });
      }
      this.set('adminErrors', {
        email: emailError,
        name,
        password,
      });
    }

    if (token) {
      this.set('cardError', token);
    }
  },

  trackDeveloperWorkspaceClickedEvent() {
    if (this.get('router.currentRouteName') === 'developer-signup.index') {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        context: this.context,
        object: 'sign_up_button',
        place: 'developer_signup',
      });
    }
  },

  trackEvent(email) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'register',
      context: this.context,
      place: 'create_account',
      mobile: this.mobileDevice,
      gclid: this.gclid,
      solutionId: this.solutionId,
      emailSubmissionId: this.emailSubmissionId,
      email: email || this.email,
      developerSignup: this.isDeveloper,
    });
  },

  actions: {
    startGoogleAuth() {
      window.location.href = `/auth/google_oauth2${window.location.search}`;
    },
    submitForm(e) {
      if (this.authenticatedAdmin) {
        Admin.meWithoutRedirect().then((admin) => {
          this.trackEvent(admin.email);
        });
      } else {
        this.trackEvent();
      }

      this.trackDeveloperWorkspaceClickedEvent();
      e && typeOf(e.preventDefault) === 'function' && e.preventDefault();
      let formData = {
        name: this.name,
        email: this.email,
        password: this.password,
        appName: this.appName,
        companySize: this.companySize,
        department: this.department,
      };

      this.formTask.perform(formData, this.handleCreationError.bind(this));
    },
    startSamlAuth(samlAccountName, inviteToken) {
      this.redirectService.redirect(`/saml/${samlAccountName}?invite_token=${inviteToken}`);
    },
  },
});
