/* import __COLOCATED_TEMPLATE__ from './workflow-overview.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  workflowId: string | number;
  route: string;
  isFinEnabled?: boolean;
}

const WorkflowOverview = templateOnlyComponent<Args>();

export default WorkflowOverview;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Deploy::WorkflowOverview': typeof WorkflowOverview;
    'standalone/deploy/workflow-overview': typeof WorkflowOverview;
  }
}
