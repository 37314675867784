/* import __COLOCATED_TEMPLATE__ from './alias-filter.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  NO_FILTER,
  WITHOUT_FILTER_VALUE,
  ALIAS_FILTER_TYPE,
} from 'embercom/lib/settings/filters/constants';
import type Intl from 'embercom/services/intl';
interface Signature {
  updateFilterValues: (type: string, values: string[]) => void;
}

export default class AliasFilter extends Component<Signature> {
  @service declare intl: Intl;

  @tracked selectedFilter = NO_FILTER;

  get items() {
    return [
      {
        text: this.intl.t('settings.filters.alias-filter.any'),
        value: NO_FILTER,
      },
      {
        text: this.intl.t('settings.filters.alias-filter.off'),
        value: WITHOUT_FILTER_VALUE,
      },
      {
        text: this.intl.t('settings.filters.alias-filter.on'),
        value: ALIAS_FILTER_TYPE,
      },
    ];
  }

  get label() {
    return this.items.find((item) => item.value === this.selectedFilter)?.text;
  }

  @action
  onSelectItem(value: string) {
    this.selectedFilter = value;
    this.args.updateFilterValues(ALIAS_FILTER_TYPE, [this.selectedFilter]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::AliasFilter': typeof AliasFilter;
    'settings/filters/alias-filter': typeof AliasFilter;
  }
}
