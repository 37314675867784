/* import __COLOCATED_TEMPLATE__ from './sunshine-setup-sidedrawer.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  closeOnSave: () => void;
}

const StandaloneZendeskDeployCommonSunshineSetupSidedrawer = templateOnlyComponent<Args>();
export default StandaloneZendeskDeployCommonSunshineSetupSidedrawer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Common::SunshineSetupSidedrawer': typeof StandaloneZendeskDeployCommonSunshineSetupSidedrawer;
    'standalone/zendesk/deploy/common/sunshine-setup-sidedrawer': typeof StandaloneZendeskDeployCommonSunshineSetupSidedrawer;
  }
}
