/* import __COLOCATED_TEMPLATE__ from './zendesk-multiple-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { use } from 'ember-resources/util/function-resource';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { type PulseAccordion } from 'glint/pulse';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type { ConnectionOption } from 'embercom/components/standalone/common/connect-card';
import type SunshineMultipleConversation from 'embercom/models/standalone/sunshine-multiple-conversation';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export interface Args {
  accordion: PulseAccordion;
  onClose: () => void;
}

export default class ZendeskMultipleConversations extends Component<Args> {
  @service declare appService: { app: { id: string } };
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare store: Store;

  @use sunshineMultipleConversation = AsyncData<SunshineMultipleConversation>(async () => {
    return this.store.findRecord('standalone/sunshine-multiple-conversation', this.app.id);
  });

  get app() {
    return this.appService.app;
  }

  get isLoading() {
    return this.isSunshineConnected && this.sunshineMultipleConversation.isLoading;
  }

  get isEnabled() {
    return (this.isSunshineConnected && this.sunshineMultipleConversation.value?.enabled) || false;
  }

  get isSunshineConnected() {
    return this.zendeskConfig?.sunshineIntegration?.isSunshineConnected || false;
  }

  get setupUrl() {
    if (!this.zendeskSubdomain) {
      return;
    }
    return `https://${this.zendeskSubdomain}.zendesk.com/admin/channels/messaging_and_social/messaging/setup-multi-conversations`;
  }

  get manageUrl() {
    if (!this.zendeskSubdomain) {
      return;
    }
    return `https://${this.zendeskSubdomain}.zendesk.com/admin/channels/messaging_and_social/messaging/multi-conversations-menage-channels`;
  }

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig;
  }

  get zendeskSubdomain() {
    return this.zendeskConfig?.subdomain;
  }

  get multiConversationsConnection(): ConnectionOption {
    return {
      value: 'zendesk-multiple-conversations',
      icon: 'zendesk',
      label: this.t('multiple-conversations'),
      description: this.t('instructions'),
      isConnected: this.isEnabled,
      manage: () => this.openZendeskManage(),
      connect: () => this.openZendeskSetup(),
      stampConnected: this.t('enabled'),
      stampNotConnected: this.t('not-enabled'),
      buttonIcon: 'new-window',
      buttonConnected: this.t('manage-in-zendesk'),
      buttonNotConnected: this.t('manage-in-zendesk'),
      buttonDisabledTooltip: this.manageUrl ? undefined : this.t('disabled-tooltip'),
    };
  }

  t(key: string) {
    return this.intl.t(
      `standalone.intercom-messenger.deploy.integrate.zendesk-multiple-conversations.${key}`,
    );
  }

  @action
  openZendeskSetup() {
    safeWindowOpen(this.setupUrl);
  }

  @action
  openZendeskManage() {
    safeWindowOpen(this.manageUrl);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::Integrate::ZendeskMultipleConversations': typeof ZendeskMultipleConversations;
  }
}
