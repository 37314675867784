/* import __COLOCATED_TEMPLATE__ from './test-fin.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type PulseAccordion } from 'glint/pulse';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type IntlService from 'ember-intl/services/intl';

interface Signature {
  Args: {
    accordion: PulseAccordion;
    onContinue: () => void;
  };
}

export default class TestFinComponent extends Component<Signature> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;

  get zendeskConfig(): ZendeskConfiguration | undefined {
    return this.finStandaloneService.zendeskConfig;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData;
  }

  get emailAddress() {
    return this.finStandaloneService.zendeskTicketsSetupData?.email_addresses?.[0] || '';
  }

  @action
  continue() {
    this.args.onContinue?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::Test::TestFin': typeof TestFinComponent;
  }
}
