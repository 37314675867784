/* import __COLOCATED_TEMPLATE__ from './login.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import { action } from '@ember/object';
import openCenteredWindow from 'embercom/lib/open-centered-window';
import Component from '@glimmer/component';

interface Args {
  redirectUrl: string;
}

export default class StandaloneCopilotLogin extends Component<Args> {
  get baseUrl() {
    return new URL(this.args.redirectUrl).origin;
  }

  @action openLoginWindow() {
    let loginWindow = openCenteredWindow(`${this.baseUrl}/admins/sign_in`);

    window.addEventListener('message', (event) => {
      if (event.data.type === 'login_complete') {
        loginWindow!.close();
        window.location.href = this.args.redirectUrl;
      }
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Copilot::Login': typeof StandaloneCopilotLogin;
  }
}
