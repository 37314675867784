/* import __COLOCATED_TEMPLATE__ from './copilot-selector-modal.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import { isEqual } from 'underscore';

export interface Args {
  selectedAdmins: Array<string>;
  toggleModal: () => void;
  confirmChanges: () => void;
  copilotPrice: number;
  copilotInitialised: boolean;
  isPlanWithLiteSeatsSelected: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class CopilotSelectorModal extends Component<Signature> {
  @service declare appService: any;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare intl: any;
  @service declare permissionsService: any;
  @tracked declare startingAdmins: Array<string>;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.startingAdmins = [...this.args.selectedAdmins]; // create a copy of the array
  }

  get currentAdminCannotManageTeammates() {
    return !this.currentAdminCanManageTeammates;
  }

  get currentAdminCanManageTeammates() {
    return this.permissionsService.currentAdminCan('can_manage_teammates');
  }

  get adminsForTableView() {
    return this.appService.app.humanAdmins.map((admin: any) => {
      return {
        admin,
        isSelected: this.args.selectedAdmins.includes(admin.id),
        requiresFullSeat: admin.hasPricing5LiteSeat && this.args.isPlanWithLiteSeatsSelected,
        hasNoCoilotPermissions: !admin.canInboxAccessCopilot,
      };
    });
  }

  get allAvailableAdminIds() {
    return this.adminsForTableView
      .filter((admin: any) => {
        //Available admins must have full seats
        if (admin.requiresFullSeat) {
          return false;
        }
        //and admins with no copilot permissions if current admin CAN manage teammates
        else if (admin.hasNoCoilotPermissions && this.currentAdminCannotManageTeammates) {
          return false;
        }
        return true;
      })
      .mapBy('admin.id');
  }

  get originallySelectedAdminQuantity() {
    return this.args.copilotInitialised ? this.startingAdmins.length : 0;
  }

  get currentlySelectedAdminQuantity() {
    return this.args.selectedAdmins.length;
  }

  get quantityChange() {
    return this.currentlySelectedAdminQuantity - this.originallySelectedAdminQuantity;
  }

  get priceImpact() {
    return this.quantityChange * this.args.copilotPrice;
  }

  get absolutePriceImpact() {
    return Math.abs(this.priceImpact);
  }

  get selectedAdminsUnchanged() {
    return isEqual(this.args.selectedAdmins, this.startingAdmins);
  }

  get footerPrimaryButtonText() {
    if (!this.args.copilotInitialised && this.currentlySelectedAdminQuantity > 0) {
      return this.intl.t(
        'signup.teams.pricing5.annual-plans.upgrades.copilot.seat-selector-modal.assign',
        { priceImpact: this.absolutePriceImpact },
      );
    } else if (this.quantityChange !== 0) {
      return this.intl.t(
        'signup.teams.pricing5.annual-plans.upgrades.copilot.seat-selector-modal.update-with-value',
        {
          priceImpact: this.absolutePriceImpact,
          isIncrease: this.priceImpact > 0,
        },
      );
    } else {
      return this.intl.t(
        'signup.teams.pricing5.annual-plans.upgrades.copilot.seat-selector-modal.update',
      );
    }
  }

  get footerPrimaryButtonDisabled() {
    return (
      (this.selectedAdminsUnchanged && this.args.copilotInitialised) ||
      (this.currentlySelectedAdminQuantity === 0 && !this.args.copilotInitialised)
    );
  }

  get selectAllAvailableAdmins() {
    return this.args.selectedAdmins.length === 0;
  }

  @action
  toggleAdminSelectedState(admin_id: string) {
    if (this.args.selectedAdmins.includes(admin_id)) {
      this.args.selectedAdmins.removeObject(admin_id);
    } else {
      this.args.selectedAdmins.pushObject(admin_id);
    }
  }

  @action
  toggleAllAdminSelected() {
    if (this.selectAllAvailableAdmins) {
      this.args.selectedAdmins.clear();
      this.args.selectedAdmins.pushObjects(this.allAvailableAdminIds);
    } else {
      this.args.selectedAdmins.clear();
    }
  }

  @action
  abandonModal() {
    this.args.selectedAdmins.clear();
    this.args.selectedAdmins.pushObjects(this.startingAdmins);

    this.args.toggleModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::CopilotSelectorModal': typeof CopilotSelectorModal;
  }
}
