/* import __COLOCATED_TEMPLATE__ from './pill.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    color: 'green' | 'yellow' | 'gray' | 'red';
    icon?: InterfaceIconName;
    iconSet?: 'tiny' | 'standard'; // NB: Uses 'tiny' if not specified
    text: string;
    isLoading?: boolean;
    subtle?: boolean;
  };
  Element: HTMLElement;
}

export default class Pill extends Component<Signature> {
  get colorClasses() {
    switch (this.args.color) {
      case 'green':
        return 'bg-success-container text-default';
      case 'yellow':
        return 'bg-notice-container text-default';
      case 'gray':
        return 'bg-neutral-container text-muted';
      case 'red':
        return 'bg-error-container text-muted';
      default:
        return '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::Pill': typeof Pill;
  }
}
