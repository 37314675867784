/* import __COLOCATED_TEMPLATE__ from './agent-select.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';
import { RESPONSE_PROVIDED_ERRORS } from 'embercom/objects/standalone/constants';

interface Signature {
  Args: {
    setUpdatingFinAgent: (isLoading: boolean) => void;
  };
}

export default class AgentSelectComponent extends Component<Signature> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked newAgentId?: number;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData && !this.adminOptions.length;
  }

  get adminOptions() {
    let admins = this.ticketsSetupData.admins?.map((admin) => ({
      value: admin.id,
      text: admin.name,
    }));
    return admins || [];
  }

  get selectedAgentName(): string | undefined {
    if (this.isLoading) {
      return undefined;
    }

    return this.adminOptions.find((option) => option.value === this.selectedAgentId)?.text;
  }

  get selectedAgentId(): number | undefined {
    if (this.zendeskConfig.ticketsIntegration.finZendeskUserId) {
      return this.zendeskConfig.ticketsIntegration.finZendeskUserId;
    }
    return undefined;
  }

  get isSaving() {
    return taskFor(this.updateAgentId).isRunning;
  }

  get manageTeamLink() {
    if (!this.zendeskConfig?.adminBaseUrl) {
      return undefined;
    }

    return `${this.zendeskConfig.adminBaseUrl}/people/team/members`;
  }

  @action setAgentId(agentId: number) {
    this.newAgentId = agentId;
    taskFor(this.updateAgentId).perform();
  }

  @task({ restartable: true })
  *updateAgentId(): TaskGenerator<void> {
    if (!this.newAgentId) {
      this.notificationsService.notifyInfo(
        this.intl.t(
          'standalone.zendesk.deploy.tickets.integrate.fin-identity.agent-select.choose-an-agent-first',
        ),
      );
      return;
    }

    try {
      this.args.setUpdatingFinAgent(true);
      yield this.zendeskConfig.setFinUser(this.newAgentId);

      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'standalone.zendesk.deploy.tickets.integrate.fin-identity.agent-select.agent-set-success',
          {
            agentName: this.selectedAgentName,
          },
        ),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: this.intl.t(
            'standalone.zendesk.deploy.tickets.integrate.fin-identity.agent-select.error-setting-agent',
          ),
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e);
    } finally {
      this.args.setUpdatingFinAgent(false);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::Integrate::FinIdentity::AgentSelect': typeof AgentSelectComponent;
  }
}
