/* import __COLOCATED_TEMPLATE__ from './permissions.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { confirmMultipleSeatOveragesIncrease } from 'embercom/lib/admins/multiple-seats-info';
import {
  TEAMMATE_USAGE as TEAMMATE,
  COLLABORATOR_SEAT_TYPE as COLLABORATOR,
  PRICING_5_X_LITE_SEAT_TYPE as LITE,
  PRICING_5_X_FULL_SEAT_TYPE as FULL,
  COPILOT_SEAT_TYPE as COPILOT,
} from 'embercom/lib/settings/seats/constants';
import { Promise as EmberPromise } from 'rsvp';
import { difference, isEqual } from 'underscore';
import { isEmpty, isPresent } from '@ember/utils';
import { PERMISSIONS_EXCLUDED_FROM_TOGGLED_PERMISSIONS } from 'embercom/models/model-with-permissions';
import { captureException } from 'embercom/lib/sentry';

const NON_BLOCKING_SEAT_TYPES = [COPILOT];

export default class Permissions extends Component {
  @service appService;
  @service notificationsService;
  @service intercomConfirmService;
  @service customerService;
  @service router;
  @service intl;
  @tracked reassignmentsAreValid = true;
  @tracked showApplyChangesModal = false;
  @tracked showReassignmentModal = false;
  @tracked seatTypes = [...this.args.teammate.seats];

  usageType = TEAMMATE;
  reassignments = { teams: {} };
  resolveAcceptedFunction;
  rejectAcceptedFunction;
  initialUsage;
  updatedUsage;

  constructor() {
    super(...arguments);
    if (this.appService.app.onPricing5 && this.seatTypes.length === 0) {
      this.seatTypes = [FULL];
    }
  }

  @action
  changeReassignments(isValid, reassignments) {
    this.reassignmentsAreValid = isValid;
    this.args.changeReassignments(reassignments);
  }

  get isUpdateValid() {
    return (
      this.hasUpdatedSeats ||
      (this.args.permissionsObject?.hasDirtyAttributes && this.reassignmentsAreValid)
    );
  }

  get canSaveChanges() {
    // For PPP we use a different logic
    if (this.appService.app.canUsePerProductPricingFlow) {
      if (!this.args.permissionsObject.has_inbox_access && this.reassignmentsAreValid) {
        return true;
      }

      if (this.hasCopilotSeatChanged && this.args.permissionsObject.has_inbox_access) {
        return true;
      }

      return this.isUpdateValid;
    }

    // Normal pricing model logic
    return this.isUpdateValid || this.hasCopilotSeatChanged;
  }

  async confirmApplyChanges() {
    let { permissionsObject } = this.args;
    try {
      permissionsObject.validate();
      let userConfirmed = true;

      if (!this.shouldHideConfirmationModal) {
        this.showApplyChangesModal = true;
        userConfirmed = await this.modalConfirmationPromise;
        this.showApplyChangesModal = false;

        if (!userConfirmed) {
          return false;
        }
      }

      if (this.teammateRequiresOwnershipReassignment) {
        this.showReassignmentModal = true;
        userConfirmed = await this.modalConfirmationPromise;
        this.showReassignmentModal = false;
      }

      return userConfirmed;
    } catch (error) {
      console.error('Error in confirmApplyChanges:', error);
      this.notificationsService.notifyError(error.message);
      return false;
    }
  }

  get shouldHideConfirmationModal() {
    let { permissionsObject } = this.args;
    let numberOfPermissionsChanged = permissionsObject.numberOfPermissionsChanged();

    PERMISSIONS_EXCLUDED_FROM_TOGGLED_PERMISSIONS.forEach((permission) => {
      if (permissionsObject.hasPermissionChanged(permission)) {
        numberOfPermissionsChanged--;
      }
    });
    return (
      numberOfPermissionsChanged <= 0 &&
      !(
        permissionsObject.conversationAccessHasChanged ||
        permissionsObject.copilotUsageAccessHasChanged
      )
    );
  }

  get modalConfirmationPromise() {
    return new EmberPromise((resolve, reject) => {
      // Nolaneo – this linter error seems genuine: why are we mutating state in this getter?
      // eslint-disable-next-line
      this.resolveAcceptedFunction = () => {
        resolve(true);
      };
      // eslint-disable-next-line
      this.rejectAcceptedFunction = () => {
        resolve(false);
      };
    });
  }

  @action
  async saveChanges() {
    if (
      (await this.confirmMultipleSeatOveragesIncreaseIfHasSeats()) &&
      (await this.confirmCopilotSeat()) &&
      (await this.confirmApplyChanges())
    ) {
      this.args.saveChanges(this.args.teammates, this.seatTypes, this._copilotSeatAdded());
    }
  }

  @action
  onCancel() {
    this.router.transitionTo('apps.app.settings.workspace.teammates.index');
  }

  async confirmCopilotSeat() {
    if (!this._displayCopilotConfirmationModal() || !this._copilotSeatRevoked()) {
      return true;
    }
    return this.confirmRevokeCopilotSeat();
  }

  _displayCopilotConfirmationModal() {
    return this.hasCopilotSeatChanged;
  }

  get hasCopilotSeatChanged() {
    return this._copilotSeatRevoked() || this._copilotSeatAdded();
  }

  _copilotSeatRevoked() {
    return difference(this.args.teammate.seats, this.seatTypes).includes(COPILOT);
  }

  _copilotSeatAdded() {
    return difference(this.seatTypes, this.args.teammate.seats).includes(COPILOT);
  }

  _onlyGivenSeatTypeChanged(seatType) {
    let difference = this.args.teammate.seats
      .filter((seat) => !this.seatTypes.includes(seat))
      .concat(this.seatTypes.filter((seat) => !this.args.teammate.seats.includes(seat)));

    let isOnlyGivenSeatTypeChanged = difference.length === 1 && difference[0] === seatType;

    if (this.appService.app.canUsePerProductPricingFlow) {
      return (
        isOnlyGivenSeatTypeChanged &&
        !this.args.permissionsObject.hasPermissionChanged('has_inbox_access')
      );
    }

    return isOnlyGivenSeatTypeChanged;
  }

  async confirmRevokeCopilotSeat() {
    if (!this._onlyGivenSeatTypeChanged(COPILOT)) {
      return true;
    }

    return await this.showConfirmModal({
      title: this.intl.t('settings.teammates.edit.revoke-copilot-seat-modal.title'),
      confirmButtonText: this.intl.t(
        'settings.teammates.edit.revoke-copilot-seat-modal.confirm-button-text',
      ),
      body: this.intl.t('settings.teammates.edit.revoke-copilot-seat-modal.body'),
    });
  }

  async confirmMultipleSeatOveragesIncreaseIfHasSeats() {
    if (this.appService.app.hasMultipleSeatTypes) {
      return await confirmMultipleSeatOveragesIncrease(
        this.appService.app,
        {
          seatTypes: this.seatTypes,
          initialUsage: this.initialUsage,
          updatedUsage: this.updatedUsage,
          teammateCount: 1,
          isInvite: false,
          haveSeatsChanged: this.hasUpdatedSeats,
          copilotSeatChanged: this.hasCopilotSeatChanged,
          isOnlyChangeCopilot: this._onlyGivenSeatTypeChanged(COPILOT),
        },
        this.showConfirmModal,
      );
    }
    return true;
  }

  @action
  async showConfirmModal(options) {
    return this.intercomConfirmService.confirm(options);
  }

  @action
  onSeatsChanged() {
    this.args.seatsUpdated(this.hasUpdatedSeats);
  }

  @action
  onChangesAccepted() {
    this.resolveAcceptedFunction();
    this.showApplyChangesModal = false;
  }

  @action
  onChangesRejected() {
    this.rejectAcceptedFunction();
    this.showApplyChangesModal = false;
    this.showReassignmentModal = false;
  }

  @action
  setInitialUsage(usage) {
    this.initialUsage = usage;
  }

  @action
  setUpdatedUsage(usage) {
    this.updatedUsage = usage;
  }

  seatArrayWithoutNonBlockingSeats(seatArray) {
    return seatArray.filter((seat) => !NON_BLOCKING_SEAT_TYPES.includes(seat));
  }

  get hasUpdatedSeats() {
    let teammate = this.args.teammate;
    let newSeatTypesWithoutNonBlockingSeats = this.seatArrayWithoutNonBlockingSeats(this.seatTypes);
    let initialSeatsWithoutNonBlockingSeats = this.seatArrayWithoutNonBlockingSeats(teammate.seats);

    return (
      difference(initialSeatsWithoutNonBlockingSeats, newSeatTypesWithoutNonBlockingSeats)
        .length !== 0 ||
      difference(newSeatTypesWithoutNonBlockingSeats, initialSeatsWithoutNonBlockingSeats)
        .length !== 0
    );
  }

  get teammateRequiresOwnershipReassignment() {
    return this._hasSeatWithoutOwnership() && this._initiallyHadSeatsWithOwnership();
  }

  _hasSeatWithoutOwnership() {
    let seatTypesWithoutNonBlockingSeats = this.seatArrayWithoutNonBlockingSeats(this.seatTypes);

    return (
      isEmpty(seatTypesWithoutNonBlockingSeats) ||
      isEqual(seatTypesWithoutNonBlockingSeats, [COLLABORATOR]) ||
      isEqual(seatTypesWithoutNonBlockingSeats, [LITE])
    );
  }

  _initiallyHadSeatsWithOwnership() {
    let teammate = this.args.teammate;
    let initialSeatsWithoutNonBlockingSeats = this.seatArrayWithoutNonBlockingSeats(teammate.seats);

    return (
      isPresent(initialSeatsWithoutNonBlockingSeats) &&
      initialSeatsWithoutNonBlockingSeats.length > 0 &&
      !isEqual(initialSeatsWithoutNonBlockingSeats, [COLLABORATOR]) &&
      !isEqual(initialSeatsWithoutNonBlockingSeats, [LITE])
    );
  }

  @action
  async handleClickDeleteTeammate() {
    if (this._canDirectlyDeleteTeammate) {
      let isConfirmed = await this._removeTeammateConfirmationModal();

      if (isConfirmed) {
        await this._removeTeammate();
        return this.router.transitionTo('apps.app.settings.workspace.teammates');
      }
    } else {
      let id = this.args.teammate.id;
      this.router.transitionTo('apps.app.settings.workspace.teammates.teammate.remove', id);
    }
  }

  async _removeTeammateConfirmationModal() {
    let confirmationModelContent = {
      title: this.intl.t('settings.teammates.edit.remove-teammate-modal.title'),
      body: this.intl.t('settings.teammates.edit.remove-teammate-modal.body'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t(
        'settings.teammates.edit.remove-teammate-modal.confirm-button-text',
      ),
    };

    let confirm = await this.intercomConfirmService.confirm(confirmationModelContent);
    return confirm;
  }

  async _removeTeammate() {
    let teammate = this.args.teammate;
    let permission = this.args.teammate.currentAppPermissions;
    try {
      await permission.destroyRecord({
        adapterOptions: { skipValidation: true, reassignments: undefined },
      });
      this.appService.app.admins.removeObject(teammate);
      permission.unloadRecord();
      teammate.unloadRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammates.remove-teammate.notification.successful-removal', {
          teammateName: teammate.displayAs,
          appName: this.appService.app.name,
        }),
      );
    } catch (error) {
      if (error.jqXHR.status === 403) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.teammates.remove-teammate.notification.unexpected-failure'),
        );
        captureException(error);
      }
    }
  }

  get _canDirectlyDeleteTeammate() {
    return (
      this.appService.app.hasMultipleSeatTypes &&
      (this._hasNoSeat || this._hasCollaboratorSeat || this._hasLiteSeat)
    );
  }

  get _hasCollaboratorSeat() {
    return this.args.teammate.seats.length === 1 && this.args.teammate.seats[0] === COLLABORATOR;
  }

  get _hasLiteSeat() {
    return this.args.teammate.seats.length === 1 && this.args.teammate.seats[0] === LITE;
  }

  get _hasNoSeat() {
    return this.args.teammate.seats.length === 0;
  }
}
