/* import __COLOCATED_TEMPLATE__ from './csv-import-example.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  columns: any;
  data: any;
}

export default class CsvImportExample extends Component<Args> {
  @tracked openSectionId: string | null = null;

  @action
  onOpenSectionChange(sectionId: string): void {
    this.openSectionId = sectionId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::ConversationAttributes::CsvImportExample': typeof CsvImportExample;
    'settings/conversation-attributes/csv-import-example': typeof CsvImportExample;
  }
}
