/* import __COLOCATED_TEMPLATE__ from './user-menu.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-component-inheritance */

import UserMenu from 'embercom/components/inbox2/user-menu';

export default class StandaloneUserMenu extends UserMenu {
  get groupList() {
    let topItems = [];

    topItems.push({
      model: this.session.teammate,
      component: 'inbox2/user-menu/workspaces-switcher',
      componentAttrs: {
        workspacesList: this.workspacesList,
        hideAddNewWorkspace: true,
      },
    });
    if (this.args.showThemeSwitcher) {
      topItems.push({ component: 'inbox2/user-menu/theme-switcher' });
    }

    topItems.push({ component: 'inbox2/user-menu/locale-switcher' });

    return [
      {
        items: [
          {
            text: this.session.teammate.name,
            value: this.session.teammate,
            isActive: this.isActive,
            app: this.session.workspace.id,
            component: 'inbox2/user-menu/consistent-nav/avatar',
          },
        ],
      },
      {
        items: topItems,
      },
      {
        items: this.logoutLinkItems,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Navbar::UserMenu': typeof StandaloneUserMenu;
    'standalone/navbar/user-menu': typeof StandaloneUserMenu;
  }
}
