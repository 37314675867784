/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { isPresent } from '@ember/utils';
import type { OrganizationField } from 'embercom/objects/standalone/data-fields/common';
import { StandaloneCompanyAttribute } from 'embercom/objects/standalone/data-fields/common';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';

interface Args {
  archivedAttributes: any[];
}

export default class CompanyAttributesMain extends Component<Args> {
  @service declare modalService: any;
  @service declare attributeService: any;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get attributes() {
    return this.unmappedStandaloneCustomCompanyAttributes
      .map(
        (attributeWithSettings: any) =>
          new StandaloneCompanyAttribute({
            intercomAttribute: attributeWithSettings.attribute,
          }),
      )
      .concat(
        this.standaloneSyncedCompanyAttributes.map(
          (attribute: OrganizationField) =>
            new StandaloneCompanyAttribute({
              standalonePlatform: this.standalonePlatform!,
              organizationField: attribute,
              intercomAttribute: this.attributeService.displayableCompanyAttributes.find(
                (attributeWithSettings: any) =>
                  attributeWithSettings.attribute.identifier === attribute.intercom_cda?.identifier,
              ).attribute,
            }),
        ),
        this.standaloneRequiredCompanyAttributes.map(
          (attribute: OrganizationField) =>
            new StandaloneCompanyAttribute({
              standalonePlatform: this.standalonePlatform!,
              organizationField: attribute,
            }),
        ),
      );
  }

  private get unmappedStandaloneCustomCompanyAttributes() {
    return this.attributeService.displayableCompanyAttributes
      .filter(
        (attributeWithSettings: any) =>
          attributeWithSettings.attribute.isCustomData &&
          !this.standaloneSyncedCompanyAttributes.any(
            (attribute) =>
              attribute.intercom_cda?.identifier === attributeWithSettings.attribute.identifier,
          ),
      )
      .concat(this.archivedCompanyAttributes);
  }

  private get standaloneSyncedCompanyAttributes() {
    return this.standalonePlatformCompanyAttributes.filter((field) =>
      isPresent(field.intercom_cda?.identifier),
    );
  }

  private get standaloneRequiredCompanyAttributes() {
    return this.standalonePlatformCompanyAttributes.filter((field) => field.required);
  }

  private get standalonePlatform() {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return 'zendesk';
    } else if (this.appService.app.hasStandalonePlatform('salesforce')) {
      return 'salesforce';
    }

    return null;
  }

  private get standalonePlatformCompanyAttributes() {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return this.finStandaloneService.zendeskDataFields.organizationFields;
    } else if (this.appService.app.hasStandalonePlatform('salesforce')) {
      // we currently don't sync company attributes to salesforce
      return [];
    }

    return [];
  }

  private get archivedCompanyAttributes() {
    return this.archivedAttributesWithSettings.filterBy('attribute.isCompany');
  }

  private get archivedAttributesWithSettings() {
    return this.attributeService.attributesWithSettings(this.args.archivedAttributes);
  }

  get emptyStateDescription() {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return this.intl.t('standalone.settings.data.companies.description.zendesk');
    }

    return;
  }

  get emptyStateButtonLabel() {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return this.intl.t('standalone.settings.data.companies.sync-via-platform.zendesk');
    }

    return this.intl.t('new-settings.data.companies.create-attribute');
  }

  @action
  emptyStateOnButtonClick() {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return this.router.transitionTo('apps.app.settings.standalone.integration');
    }

    return this.openAttributeCreationModal();
  }

  @action
  openAttributeCreationModal() {
    this.modalService.openModal('settings/modals/attribute-details', {
      attribute: null,
      allowArchival: false,
      showPeopleAndCompanyToggleTab: false,
      typeToCreate: 'company',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::DataAttributes::Company::Main': typeof CompanyAttributesMain;
    'standalone/company-attributes/main': typeof CompanyAttributesMain;
  }
}
