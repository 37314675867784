/* import __COLOCATED_TEMPLATE__ from './early-stage-application-form-component.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  solutionId: string;
  submitApplication: (formData: any) => Promise<void>;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class EarlyStageApplicationFormComponent extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare cardlessTrialService: $TSFixMe;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare earlyStageService: $TSFixMe;
  @service declare intl: $TSFixMe;

  @tracked didSubmit = false;
  @tracked fundingLevel: string | undefined;
  @tracked companySize: string | undefined;
  @tracked companyWebsite: string | undefined;
  @tracked selectedPartner: string | undefined;

  context = 'early_stage_signup';
  place = 'early_stage_application';

  get app() {
    return this.appService.app;
  }

  get formSubmitTaskRunning() {
    return taskFor(this.submitApplicationTask).isRunning;
  }

  @action
  setFundingLevel(value: string) {
    this.fundingLevel = value;
    this._trackInteraction('selected', 'fundingLevel', value);
  }
  get showFundingLevelError() {
    return this.didSubmit && !this.fundingLevel;
  }

  get fundingLevelOptions() {
    if (this.earlyStageService.isPartnerProgram) {
      return [
        { text: this.intl.t('teams-checkout.early_stage.labels.pre_seed_seed'), value: 14 },
        { text: this.intl.t('teams-checkout.early_stage.labels.series_a'), value: 15 },
        { text: this.intl.t('teams-checkout.early_stage.labels.series_b_plus'), value: 16 },
      ];
    } else {
      return [
        {
          text: this.intl.t('teams-checkout.early_stage.funding_options.less_than_500000'),
          value: 10,
        },
        {
          text: this.intl.t(
            'teams-checkout.early_stage.funding_options.between_500000_and_1000000',
          ),
          value: 11,
        },
        {
          text: this.intl.t(
            'teams-checkout.early_stage.funding_options.between_1000001_and_3000000',
          ),
          value: 12,
        },
        {
          text: this.intl.t(
            'teams-checkout.early_stage.funding_options.between_3000001_and_10000000',
          ),
          value: 17,
        },
        {
          text: this.intl.t('teams-checkout.early_stage.funding_options.more_than_10000000'),
          value: 18,
        },
      ];
    }
  }

  @action
  setCompanySize(value: string) {
    this.companySize = value;
    this._trackInteraction('selected', 'companySize', value);
  }
  get showCompanySizeError() {
    return this.didSubmit && !this.companySize;
  }

  get companySizeOptions() {
    // https://github.com/intercom/intercom/issues/334573#issuecomment-2222952249
    // We deviate from the standard set of ranges here to meet the ES Partner criteria of < 25 employees
    return [
      {
        text: this.intl.t('teams-checkout.early_stage.employees_number_options.one_to_five'),
        value: '1-5',
      },
      {
        text: this.intl.t('teams-checkout.early_stage.employees_number_options.six_to_nine'),
        value: '6-9',
      },
      ...(this.earlyStageService.isPartnerProgram
        ? [
            {
              text: this.intl.t(
                'teams-checkout.early_stage.employees_number_options.ten_to_twenty-five',
              ),
              value: '10-25',
            },
          ]
        : [
            {
              text: this.intl.t(
                'teams-checkout.early_stage.employees_number_options.ten_to_fifteen',
              ),
              value: '10-15',
            },
            {
              text: this.intl.t(
                'teams-checkout.early_stage.employees_number_options.sixteen_to_twenty-five',
              ),
              value: '16-25',
            },
          ]),
      {
        text: this.intl.t(
          'teams-checkout.early_stage.employees_number_options.twenty-six_to_forty-nine',
        ),
        value: '26-49',
      },
      {
        text: this.intl.t(
          'teams-checkout.early_stage.employees_number_options.fifty_to_one-hundred-ninety-nine',
        ),
        value: '50-199',
      },
      {
        text: this.intl.t(
          'teams-checkout.early_stage.employees_number_options.two-hundred_to_nine-hundred-ninety-nine',
        ),
        value: '200-999',
      },
      {
        text: this.intl.t('teams-checkout.early_stage.employees_number_options.one-thousand_plus'),
        value: '1000+',
      },
    ];
  }

  get showCompanyWebsiteError() {
    return this.didSubmit && !this.companyWebsite;
  }

  get shouldShowPartnerSelection() {
    return this.earlyStageService.isPartnerProgram && !this.earlyStageService.isPartnerValid;
  }

  get partnerItems() {
    return this.earlyStageService.partners.sort().map((partner: string) => {
      return { text: partner, value: partner };
    });
  }

  @action
  setSelectedPartner(value: string) {
    this.selectedPartner = value;
    this._trackInteraction('selected', 'selectedPartner', value);
  }
  get showPartnerError() {
    return this.shouldShowPartnerSelection && this.didSubmit && !this.selectedPartner;
  }

  _trackInteraction(action = 'entered', object: string, value?: string) {
    let event = {
      action,
      object,
      context: this.context,
      place: this.place,
      solutionId: this.args.solutionId,
      value,
    };

    this.purchaseAnalyticsService.trackEvent(event);
  }

  @dropTask *submitApplicationTask(): TaskGenerator<void> {
    this.didSubmit = true;
    if (
      this.showCompanySizeError ||
      this.showFundingLevelError ||
      this.showCompanyWebsiteError ||
      this.showPartnerError
    ) {
      return;
    }

    if (this.shouldShowPartnerSelection) {
      this.earlyStageService.partnerSource = this.selectedPartner;
    }

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'early_stage_signup',
      place: 'early_stage_application',
      object: 'early_stage_application',
      solutionId: this.args.solutionId,
    });

    let formData = {
      app_id: this.app.id,
      customer_id: this.app.customer_id,
      company_name: this.app.name,
      company_website: this.companyWebsite,
      funding_level: this.fundingLevel,
      company_size: this.companySize,
      partner_source: this.earlyStageService.partnerSource,
    };

    yield this.args.submitApplication(formData);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::EarlyStageApplicationFormComponent': typeof EarlyStageApplicationFormComponent;
    'signup/teams/early-stage-application-form-component': typeof EarlyStageApplicationFormComponent;
  }
}
