/* import __COLOCATED_TEMPLATE__ from './copilot-adapter.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable no-console */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type PublicAPI } from 'embercom/components/inbox2/conversation-reply-composer';
import {
  BlocksDocument,
  BaseConfig,
  type ComposerConfig,
  type ComposerPublicAPI,
} from '@intercom/embercom-prosemirror-composer';
import { type AiAssistPromptKey } from 'embercom/resources/inbox2/composer/ai-assist';
import AiAssistApi from 'embercom/resources/inbox2/composer/ai-assist';
import { type BlockList } from '@intercom/interblocks.ts';
import type CopilotApi from 'embercom/services/copilot-api';
import IntercomAdapter from 'embercom/lib/frame-adapter';

//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export type FrameOutwardEvents =
  | 'IC_FRAME_ADD_COMPOSER_PUBLIC_CONTENT'
  | 'IC_FRAME_ADD_COMPOSER_PRIVATE_CONTENT'
  | 'IC_FRAME_CLEAR_COMPOSER';

export type FrameInwardEvents = 'IC_FRAME_CONVERSATION_CHANGED';

interface Args {
  externalId?: string;
  externalType?: string;
  updateParams: (paramName: string, paramValue: string) => void;
}

export default class CopilotAdapter extends Component<Args> {
  @tracked declare composerApi: PublicAPI;
  @tracked blocksDoc: BlocksDocument;
  @tracked composerConfig: ComposerConfig;
  @service declare appService: $TSFixMe;
  @service declare copilotApi: CopilotApi;

  declare intercomAdapter: IntercomAdapter;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.blocksDoc = new BlocksDocument();
    this.composerConfig = {
      ...new BaseConfig(),
    };

    this.copilotApi.setExternalReference(
      args.externalId,
      this.getExternalTypeMapping(args.externalType),
    );

    // Initialize the IntercomAdapter
    console.log('initializing intercom adapter');
    this.intercomAdapter = new IntercomAdapter({
      listenWindow: window,
      sendWindow: window.parent,
      developmentFrameUrl: '*',
    });

    // Set up event listeners using the adapter
    this.intercomAdapter.on('IC_FRAME_CONVERSATION_CHANGED', (payload: any) => {
      console.log('Conversation changed payload:', payload);
      let { external_id, external_type } = payload;
      this.copilotApi.setExternalReference(external_id, this.getExternalTypeMapping(external_type));
    });
  }

  willDestroy() {
    super.willDestroy();
    // Clean up the adapter when component is destroyed
    this.intercomAdapter.destroy();
  }

  getExternalTypeMapping(type?: string) {
    return this.typeMapping[type as keyof typeof this.typeMapping];
  }

  typeMapping = {
    ZENDESK_TICKET: 1,
    SALESFORCE_CASE: 6,
  };

  @action async getExternalComposerContent() {
    return this.intercomAdapter.send(
      'IC_FRAME_GET_CURRENT_COMPOSER_CONTENT',
      {},
      'IC_FRAME_CURRENT_COMPOSER_CONTENT',
    );
  }

  @action setComposerApi(api: ComposerPublicAPI) {
    this.composerApi = {
      focus: () => {
        console.log('composer api focus');
      },
      setPane: () => {
        console.log('composer api setPane');
      },
      setActiveReplyPane: () => {
        console.log('composer api setActiveReplyPane');

        return this.blocksDoc.blocks;
      },
      insertBlocks: () => {
        console.log('composer api insertBlocks');
      },
      replaceBlocks: (blocks: BlockList) => {
        console.log('composer api replaceBlocks', blocks);
        this.addBlocksToComposer(blocks);
      },
      setActiveNotePane: () => {
        console.log('composer api setActiveNotePane');
        return this.blocksDoc.blocks;
      },
      insertMacroActions: () => {
        console.log('composer api insertMacroActions');
      },
      api,
      aiAssistCompletion: this.aiAssistCompletion.bind(this),
    };
  }

  @action addBlocksToComposer(blocks: any[]) {
    this.composerApi.api.composer.commands.replaceAllWithBlocks(blocks);

    let text = this.blocksToText(blocks);

    this.intercomAdapter.send('IC_FRAME_ADD_COMPOSER_PUBLIC_CONTENT', {
      text,
    });
  }

  blocksToText(blocks: any[]): string {
    let text = blocks
      .reject((block) => block.type === 'sources')
      .map((block) => {
        if (block.items && block.type === 'unorderedList') {
          return block.items.map((item: any) => `• ${item}`).join('<br>');
        }
        if (block.items && block.type === 'orderedList') {
          return block.items
            .map((item: any, index: number) => `${index + 1}. ${item}`)
            .join('<br>');
        }
        return block.text ?? '';
      })
      .join('<br><br>');

    text = this.sanitizeEmptyInlineCitations(text);
    text = sanitizeHtml(text).string;

    return text;
  }

  sanitizeEmptyInlineCitations(input: string): string {
    return input.replace(/<a[^>]*class="inline-citation"[^>]*><\/a>/g, '');
  }

  @action async aiComposeForExternalContent(func: (blocks: BlockList) => Promise<any>) {
    let externalContent = (await this.getExternalComposerContent()) as {
      text: string;
      type: 'public' | 'private';
    };

    let blocks = [{ type: 'paragraph', text: externalContent.text }];

    let aiComposeResult = await func(blocks);

    if (externalContent.type === 'public') {
      this.addBlocksToComposer(aiComposeResult);
    } else {
      this.addNoteBlocks(aiComposeResult);
    }
  }
  @action addNoteBlocks(blocks: any[]) {
    this.blocksDoc.blocks = blocks;

    this.composerApi.api.composer.commands.replaceAllWithBlocks(blocks);

    let text = this.blocksToText(blocks);

    this.intercomAdapter.send('IC_FRAME_ADD_COMPOSER_PRIVATE_CONTENT', {
      text,
    });
  }

  @action updateBlocks(blocks: any[]) {
    this.blocksDoc.blocks = blocks;
  }

  private aiAssistApi = AiAssistApi.from(this, () => {
    return {
      composerApi: this.composerApi?.api,
      currentBlocks: this.blocksDoc.blocks,
      conversationId: '-1',
    };
  });

  get isRunningAiAssist() {
    return this.aiAssistApi?.isWorking;
  }

  @action async generateSummaryForExternalConversation() {
    let response = await this.aiAssistApi.generateSummaryForExternalConversation();

    this.addNoteBlocks(response.summary);
  }

  @action aiAssistCompletion(promptKey: AiAssistPromptKey, blocks: BlockList) {
    return this.aiAssistApi.complete(
      promptKey,
      undefined, // This should be the conversation ID
      blocks,
      {
        action: 'replace_selection',
        section: 'composer',
        object: 'composer',
        source: 'composer_toolbar',
      },
      '-1', // This should be the user ID
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Adapters::CopilotAdapter': typeof CopilotAdapter;
  }
}
