/* import __COLOCATED_TEMPLATE__ from './color-picker.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  value?: string;
  setColor?: (color: string) => void;
  colpickLayout?: string;
  colorOptions?: string[];
  class?: string;
  disabled?: boolean;
  baseColor?: string;
}

export default class ColorPicker extends Component<Args> {
  @tracked color = this.args.value ?? '#FFFFFF';

  get value(): string {
    return this.args.value?.replace('#', '') || this.color;
  }

  set value(value: string) {
    this.color = value;
    this.setColor(value);
  }

  get colorWithHashtag(): string {
    let color = this.args.value || '';
    if (!color) {
      return '#FFFFFF';
    } else if (color.startsWith('#')) {
      return color;
    }
    return `#${color}`;
  }

  @action
  updateValue(color: string): void {
    if (this.args.setColor) {
      let formattedColor = color.startsWith('#') ? color : `#${color}`;
      formattedColor = formattedColor.toLowerCase();
      this.args.setColor(formattedColor);
    }
  }

  @action
  setColor(color: string): void {
    if (this.args.setColor) {
      let formattedColor = color.startsWith('#') ? color : `#${color}`;
      formattedColor = formattedColor.toLowerCase();
      if (formattedColor !== this.args.value) {
        this.args.setColor(formattedColor);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Appearance::ColorPicker': typeof ColorPicker;
    'settings/appearance/color-picker': typeof ColorPicker;
  }
}
