/* import __COLOCATED_TEMPLATE__ from './test-mode.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import isValidUrl, { urlWithHttpsPrefix } from 'embercom/lib/url-validator';
import { IntegrationState } from 'embercom/objects/standalone/constants';
import type IntlService from 'ember-intl/services/intl';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type { PulseAccordion } from 'glint/pulse';
import { type MessengerVisibility } from 'embercom/models/standalone/intercom-messenger-configuration';

export interface Args {
  accordion: PulseAccordion;
  onClose: () => void;
}

export default class TestMode extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;

  @tracked isSaving = false;
  @tracked url?: string;

  get config() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get isDisabled(): boolean {
    return this.isSaving || Boolean(this.tooltipContent);
  }

  get isUrlValid(): boolean {
    return isValidUrl(this.url);
  }

  get tooltipContent(): string {
    switch (this.config.integrationState) {
      case IntegrationState.NotConfigured:
        return this.intl.t(
          'standalone.intercom-messenger.deploy.test.test-mode.install-the-messenger-first',
        );
      case IntegrationState.Enabled:
        return this.intl.t('standalone.intercom-messenger.deploy.test.test-mode.disabled-tooltip');
      case IntegrationState.TestMode:
        return '';
      default:
        if (this.hasLauncherRules) {
          return this.intl.t(
            'standalone.intercom-messenger.deploy.test.test-mode.pre-existing-launcher-rules',
          );
        }
        return '';
    }
  }

  private get hasLauncherRules() {
    let visibilitySettings: MessengerVisibility | undefined =
      this.finStandaloneService.messengerSettings?.visibility;
    return (
      isPresent(visibilitySettings?.launcherPredicatesForAnonymous) ||
      isPresent(visibilitySettings?.launcherPredicatesForUsers)
    );
  }

  @action
  async onSwitchClick() {
    try {
      this.isSaving = true;
      if (this.config.isMessengerInTestMode) {
        await this.config.deactivate();
      } else {
        await this.config.activateTestMode();
      }
    } finally {
      this.isSaving = false;
    }
  }

  @action
  async onLaunchTest() {
    safeWindowOpen(`${urlWithHttpsPrefix(this.url)}?intercomTest=true`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::Test::TestMode': typeof TestMode;
    'standalone/intercom-messenger/deploy/test/test-mode': typeof TestMode;
  }
}
