/* import __COLOCATED_TEMPLATE__ from './hand-off.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';
import type { ConnectionOption } from 'embercom/components/standalone/common/connect-card';
import type { PulseAccordion } from 'glint/pulse';
import { HandoffBehavior } from 'embercom/objects/standalone/constants';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface BehaviorOption {
  value: HandoffBehavior;
  icon: InterfaceIconName;
  label: string;
  description: string;
}

export interface Args {
  accordion: PulseAccordion;
  onClose: () => void;
}

export default class HandOff extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;

  @tracked openedConnectionDrawer: string | null = null;

  get app() {
    return this.finStandaloneService.app;
  }

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig;
  }

  get intercomMessengerConfig() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get behaviorOptions(): BehaviorOption[] {
    if (this.app.hasStandalonePlatform('zendesk')) {
      return this.zendeskBehaviorOptions;
    } else {
      return this.genericBehaviorOptions;
    }
  }

  get genericBehaviorOptions(): BehaviorOption[] {
    return [
      {
        value: HandoffBehavior.NotConfigured,
        icon: 'stop',
        label: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.generic.not-configured.title',
        ),
        description: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.generic.not-configured.description',
        ),
      },
      {
        value: HandoffBehavior.GenericJs,
        icon: 'code-block',
        label: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.generic.generic-js.title',
        ),
        description: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.generic.generic-js.description',
        ),
      },
      {
        value: HandoffBehavior.GenericUrl,
        icon: 'new-window',
        label: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.generic.generic-url.title',
        ),
        description: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.generic.generic-url.description',
        ),
      },
      {
        value: HandoffBehavior.GenericCustomAction,
        icon: 'webhook',
        label: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.generic.custom-action.title',
        ),
        description: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.generic.custom-action.description',
        ),
      },
    ];
  }

  get zendeskBehaviorOptions(): BehaviorOption[] {
    return [
      {
        value: HandoffBehavior.ZendeskLiveAgent,
        icon: 'person',
        label: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.zendesk.hand-off.behavior.options.live-agent.title',
        ),
        description: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.zendesk.hand-off.behavior.options.live-agent.description',
        ),
      },
      {
        value: HandoffBehavior.ZendeskTicket,
        icon: 'messenger-ticket-filled',
        label: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.zendesk.hand-off.behavior.options.ticket.title',
        ),
        description: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.zendesk.hand-off.behavior.options.ticket.description',
        ),
      },
      {
        value: HandoffBehavior.ZendeskHybrid,
        icon: 'branch',
        label: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.zendesk.hand-off.behavior.options.both.title',
        ),
        description: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.zendesk.hand-off.behavior.options.both.description',
        ),
      },
    ];
  }

  get connectionOptions(): Array<ConnectionOption> {
    return [
      {
        value: 'zendesk-api',
        icon: 'zendesk',
        label: this.intl.t('standalone.common.connect-card.zendesk.api.title'),
        description: this.intl.t('standalone.common.connect-card.zendesk.api.description'),
        isConnected: !this.zendeskConfig?.isPendingSetupForTickets,
        manage: () => this.openConnectionDrawer('zendesk-api'),
        connect: () => this.openConnectionDrawer('zendesk-api'),
      },
      {
        value: 'zendesk-conversation-api',
        icon: 'zendesk',
        label: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.zendesk.hand-off.connect.conversation-api.title',
        ),
        description: this.intl.t(
          'standalone.intercom-messenger.deploy.integrate.zendesk.hand-off.connect.conversation-api.description',
        ),
        isConnected: !(
          this.zendeskConfig?.sunshineIntegration &&
          this.zendeskConfig?.sunshineIntegration?.isSunshineDisconnected
        ),
        manage: () => this.openConnectionDrawer('zendesk-conversation-api'),
        connect: () => this.openConnectionDrawer('zendesk-conversation-api'),
      },
    ];
  }

  get availableConnectionOptions(): Array<ConnectionOption> {
    switch (this.intercomMessengerConfig.handoffBehavior) {
      case HandoffBehavior.ZendeskLiveAgent:
        return this.connectionOptions;
      case HandoffBehavior.ZendeskTicket:
        return this.connectionOptions.filter((option) => option.value === 'zendesk-api');
      case HandoffBehavior.ZendeskHybrid:
        return this.connectionOptions;
      default:
        return [];
    }
  }

  get isConnected(): boolean {
    return this.availableConnectionOptions.every((option) => option.isConnected);
  }

  get isSavingIntercomMessengerConfig(): boolean {
    return taskFor(this.saveIntercomMessengerConfig).isRunning;
  }

  @action
  async setHandoffBehavior(handoffBehavior: HandoffBehavior) {
    this.intercomMessengerConfig.handoffBehavior = handoffBehavior;
    taskFor(this.saveIntercomMessengerConfig).perform();
  }

  @dropTask
  *saveIntercomMessengerConfig(): TaskGenerator<void> {
    yield this.intercomMessengerConfig.save();
    yield this.intercomMessengerConfig.ruleset.reload({ refresh: true });
  }

  @action
  openConnectionDrawer(connectionOption: string) {
    this.openedConnectionDrawer = connectionOption;
  }

  @action
  onClose() {
    this.openedConnectionDrawer = null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::Integrate::HandOff': typeof HandOff;
    'standalone/intercom-messenger/deploy/integrate/hand-off': typeof HandOff;
  }
}
