/* import __COLOCATED_TEMPLATE__ from './validate-setup-zendesk.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnly from '@ember/component/template-only';
import type { PulseAccordion } from 'glint/pulse';

export interface Args {
  accordion: PulseAccordion;
  onClose: () => void;
}

let ValidateSetupZendesk = templateOnly<Args>();
export default ValidateSetupZendesk;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::Test::ValidateSetupZendesk': typeof ValidateSetupZendesk;
    'standalone/intercom-messenger/deploy/test/validate-setup-zendesk': typeof ValidateSetupZendesk;
  }
}
