/* import __COLOCATED_TEMPLATE__ from './install.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { INSTALLATION_PLATFORMS } from 'embercom/components/installation-new/constants';

export interface Args {}

type AccordionSectionId =
  | 'web-messenger-setup'
  | 'mobile-messenger-setup'
  | 'web-idv-setup'
  | 'mobile-idv-setup'
  | 'push-notifications'
  | '';

export default class Install extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked selectedAccordion: AccordionSectionId;
  @tracked selectedTab: INSTALLATION_PLATFORMS;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.selectedAccordion = '';
    this.selectedTab = INSTALLATION_PLATFORMS.WEB;
  }

  get canAccessWorkspaceSettings() {
    return this.appService.app.currentAdmin.canAccessWorkSpaceSettings;
  }

  @action
  setSelectedAccordion(accordion: AccordionSectionId) {
    // permission neeeded for idv step, don't let user interact with the accordion section
    if (
      ['web-idv-setup', 'mobile-idv-setup'].includes(accordion) &&
      !this.canAccessWorkspaceSettings
    ) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_access_workspace_settings',
      );
      return;
    }

    this.selectedAccordion = accordion;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'accordion_clicked',
      metadata: {
        item: accordion,
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  @action
  setSelectedTab(tab: INSTALLATION_PLATFORMS) {
    this.selectedTab = tab;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::Install': typeof Install;
    'standalone/intercom-messenger/deploy/install': typeof Install;
  }
}
