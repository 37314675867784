/* import __COLOCATED_TEMPLATE__ from './channel-summary.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { type ComponentLike } from '@glint/template';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import CustomBotListData from 'embercom/objects/operator/configuration/custom-bots/custom-bot-list-data';
import type { BotConfigTarget } from 'embercom/objects/operator/configuration/configuration';
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface Args {
  title: string;
  channelIcon: InterfaceIconName;
  manageRoute: string;
  workflowRoute: string;
  workflowParams: {
    target: BotConfigTarget;
    objectType: number;
  };
  statusPill?: ComponentLike;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class StandaloneDeployOverviewChannelSummary extends Component<Signature> {
  @service declare appService: any;
  listData: CustomBotListData;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.listData = new CustomBotListData(
      owner,
      this.appService.app.id,
      this.args.workflowParams.target,
      this.args.workflowParams.objectType,
      [statisticKeys.receipts, statisticKeys.completions],
    );

    taskFor(this.listData.setupInitialState).perform();
  }

  get isLoadingInitialData() {
    return taskFor(this.listData.setupInitialState).isRunning;
  }

  get workflowTargetConfig() {
    return CUSTOM_BOT_CONFIG[this.args.workflowParams.target];
  }

  get workflows() {
    return (
      this.listData.bots ??
      ([] as Array<{
        id: string;
        contentWrapperId: string;
        title: string;
        contents: Array<{ contentData: any }>;
      }>)
    );
  }

  get sentStatKey() {
    return statisticKeys.receipts;
  }

  get completedStatKey() {
    return statisticKeys.completions;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Deploy::Overview::ChannelSummary': typeof StandaloneDeployOverviewChannelSummary;
    'standalone/deploy/overview/channel-summary': typeof StandaloneDeployOverviewChannelSummary;
  }
}
