/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { isPresent } from '@ember/utils';
import type { UserField } from 'embercom/objects/standalone/data-fields/common';
import { StandaloneUserAttribute } from 'embercom/objects/standalone/data-fields/common';
import { ATTRIBUTE_TYPES_TO_HIDE } from 'embercom/components/new-settings/data/people/attributes';

interface Args {
  archivedAttributes: any[];
}

export default class PeopleAttributesMain extends Component<Args> {
  @service declare modalService: any;
  @service declare attributeService: any;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare appService: any;

  get attributes() {
    return this.unmappedStandaloneUserAttributes
      .map(
        (attributeWithSettings: any) =>
          new StandaloneUserAttribute({
            intercomAttribute: attributeWithSettings.attribute,
          }),
      )
      .concat(
        this.standaloneSyncedUserFields.map(
          (userField: UserField) =>
            new StandaloneUserAttribute({
              standalonePlatform: this.standalonePlatform!,
              userField,
              intercomAttribute: this.displayableUserAttributes.find(
                (attributeWithSettings: any) =>
                  attributeWithSettings.attribute.identifier === userField.intercom_cda?.identifier,
              ).attribute,
            }),
        ),
        this.standaloneRequiredUserFields.map(
          (userField: UserField) =>
            new StandaloneUserAttribute({
              standalonePlatform: this.standalonePlatform!,
              userField,
            }),
        ),
      );
  }

  private get unmappedStandaloneUserAttributes() {
    return this.displayableUserAttributes
      .filter(
        (attributeWithSettings: any) =>
          !this.standalonePlatformUserFields.any(
            (userField) =>
              userField.intercom_cda?.identifier === attributeWithSettings.attribute.identifier ||
              userField.intercom_sda?.identifier === attributeWithSettings.attribute.identifier,
          ),
      )
      .concat(this.archivedUserAttributes);
  }

  private get displayableUserAttributes() {
    return this.attributeService.allUserAttributes
      .filter((setting: any) => !ATTRIBUTE_TYPES_TO_HIDE.includes(setting.attribute.type))
      .concat(this.attributeService.userRelationshipDisplayableAttributes);
  }

  private get standaloneSyncedUserFields() {
    return this.standalonePlatformUserFields.filter((field) =>
      isPresent(field.intercom_cda?.identifier),
    );
  }

  private get standaloneRequiredUserFields() {
    return this.standalonePlatformUserFields.filter((field) => field.required);
  }

  private get standalonePlatform() {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return 'zendesk';
    } else if (this.appService.app.hasStandalonePlatform('salesforce')) {
      return 'salesforce';
    }

    return null;
  }

  private get standalonePlatformUserFields() {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return this.finStandaloneService.zendeskDataFields.userFields;
    } else if (this.appService.app.hasStandalonePlatform('salesforce')) {
      return this.finStandaloneService.salesforceDataFields.userFields;
    }

    return [];
  }

  private get archivedUserAttributes() {
    return this.archivedAttributesWithSettings.filterBy('attribute.isUser');
  }

  private get archivedAttributesWithSettings() {
    return this.attributeService.attributesWithSettings(this.args.archivedAttributes);
  }

  @action
  openAttributeCreationModal() {
    this.modalService.openModal('settings/modals/attribute-details', {
      attribute: null,
      allowArchival: false,
      showPeopleAndCompanyToggleTab: false,
      typeToCreate: 'people',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::DataAttributes::People::Main': typeof PeopleAttributesMain;
    'standalone/people-attributes/main': typeof PeopleAttributesMain;
  }
}
