/* import __COLOCATED_TEMPLATE__ from './integrate.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type RouterService from '@ember/routing/router-service';
interface Args {
  changeTab: (tab: string) => void;
  setSection?: (section: string | null) => void;
  openSection: string | null;
}

export default class StandaloneZendeskDeploySunshineTabsIntegrate extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare router: RouterService;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }

  @action
  setSection(section: string | null) {
    this.args.setSection?.(section);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine::Tabs::Integrate': typeof StandaloneZendeskDeploySunshineTabsIntegrate;
    'standalone/zendesk/deploy/sunshine/tabs/integrate': typeof StandaloneZendeskDeploySunshineTabsIntegrate;
  }
}
