/* import __COLOCATED_TEMPLATE__ from './integrate.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type RouterService from '@ember/routing/router-service';
import { HandoffBehavior } from 'embercom/objects/standalone/constants';
import { action } from '@ember/object';

export interface Args {
  section: string | null;
  setSection?: (section: string | null) => void;
}

export default class Integrate extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare finStandaloneService: FinStandaloneService;

  get intercomMessengerConfig() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get hasSelectedZendeskTicketsHandoffBehavior() {
    return this.intercomMessengerConfig.handoffBehavior === HandoffBehavior.ZendeskTicket;
  }

  @action
  setSection(section: string | null): void {
    this.args.setSection?.(section);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::Integrate': typeof Integrate;
    'standalone/intercom-messenger/deploy/integrate': typeof Integrate;
  }
}
