/* import __COLOCATED_TEMPLATE__ from './api-setup-sidedrawer.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  closeOnSave: () => void;
}

const StandaloneZendeskDeployCommonApiSetupSidedrawer = templateOnlyComponent<Args>();
export default StandaloneZendeskDeployCommonApiSetupSidedrawer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Common::ApiSetupSidedrawer': typeof StandaloneZendeskDeployCommonApiSetupSidedrawer;
    'standalone/zendesk/deploy/common/api-setup-sidedrawer': typeof StandaloneZendeskDeployCommonApiSetupSidedrawer;
  }
}
