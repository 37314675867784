/* import __COLOCATED_TEMPLATE__ from './metrics-list.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type Quote from 'embercom/models/quote';

interface Args {
  quote?: Quote;
  loading: boolean;
  selectedPlanId: string;
  selectedProactiveAddon: boolean;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class MetricsList extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare customerService: any;
  @service declare purchaseAnalyticsService: any;

  get finMonthlyAllowance() {
    let baseUsage = this.args.quote?.metricBaseUsage(
      Number(this.args.selectedPlanId),
      Metric.resolutions_with_custom_answers,
    );
    return baseUsage
      ? this.intl.formatNumber(baseUsage, {
          minimumFractionDigits: 0,
        })
      : '';
  }

  get proactiveSupportBaseUsage() {
    return this.args.quote?.proactiveSupportBaseUsage;
  }

  get phoneMetricPrice() {
    return this.args.quote?.phoneMetricPrice();
  }

  get finMetricPrice() {
    return this.args.quote?.finMetricPrice(Number(this.args.selectedPlanId));
  }

  get emailMetricPrice() {
    return this.args.quote?.emailMetricPrice(Number(this.args.selectedPlanId));
  }

  get smsMetricPrice() {
    return this.args.quote?.smsMetricPrice(Number(this.args.selectedPlanId));
  }

  get whatsAppMetricPrice() {
    return this.args.quote?.whatsAppMetricPrice(Number(this.args.selectedPlanId));
  }

  get proactiveSupportMetricPrice() {
    return this.args.quote?.proactiveSupportMetricPrice;
  }

  get canReadUsageFromStripe() {
    return this.customerService.customer.canReadUsageFromStripe;
  }

  @action showMeteredMessagesArticle() {
    window.Intercom('showArticle', this.appService.app.meteredMessagesArticleId);
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'metered_messages_article',
      place: 'p5-checkout-metric-list',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::MetricsList': typeof MetricsList;
  }
}
