/* import __COLOCATED_TEMPLATE__ from './testing.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type PulseAccordion } from 'glint/pulse';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import OpenCenteredWindow from 'embercom/lib/open-centered-window';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';

interface Args {
  accordion: PulseAccordion;
  onClose: () => void;
}

export default class SunshineTesting extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked manualMode = false;

  @trackedInLocalStorage({ keyName: 'fin-standalone-zd-messaging-key' })
  zendeskMessagingKey = '';

  @trackedInLocalStorage({ keyName: 'fin-standalone-zd-messaging-url' })
  urlWithMessagingKey = '';

  @action async findZendeskMessagingKey() {
    let response = await get(
      `/ember/standalone/zendesk_configurations/${this.appService.app.id}/find_zendesk_messenger_key`,
      {
        app_id: this.appService.app.id,
        url: this.urlWithMessagingKey,
      },
    );

    if (response.key) {
      this.zendeskMessagingKey = response.key;
      this.previewZendeskMessaging();
    } else {
      this.notificationsService.notifyError(
        this.intl.t('standalone.zendesk.deploy.sunshine.testing.error.no-messenger'),
      );
    }
  }

  get testingURL() {
    let prefix = 'https://standalone.intercom.com';

    if (ENV.environment === 'development') {
      prefix = '';
    }

    return `${prefix}/standalone/zendesk/preview_messenger/${this.zendeskMessagingKey}`;
  }

  @action previewZendeskMessaging() {
    OpenCenteredWindow(
      this.testingURL,
      700,
      380,
      this.intl.t('standalone.zendesk.deploy.sunshine.testing.preview.window-title'),
    );
  }

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  @action resetURL() {
    this.urlWithMessagingKey = '';
    this.zendeskMessagingKey = '';
  }

  @action enterManualMode() {
    this.manualMode = true;
    this.urlWithMessagingKey = '';
    this.zendeskMessagingKey = '';
  }

  get zendeskChannelsUrl() {
    return `https://${this.zendeskConfig.subdomain}.zendesk.com/admin/channels/messaging_and_social/channels_list`;
  }

  @action
  onClose() {
    this.args.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine::Testing': typeof SunshineTesting;
    'standalone/zendesk/deploy/sunshine/testing': typeof SunshineTesting;
  }
}
