/* import __COLOCATED_TEMPLATE__ from './zendesk.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';

export interface Args {
  isSaving?: boolean;
  hideIcon?: boolean;
  showRed?: boolean;
}

export default class ZendeskPill extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig(): ZendeskConfiguration | undefined {
    return this.finStandaloneService.zendeskConfig;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Pills::Zendesk': typeof ZendeskPill;
    'standalone/zendesk/pills/zendesk': typeof ZendeskPill;
  }
}
