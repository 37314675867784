/* import __COLOCATED_TEMPLATE__ from './header-component.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class HeaderComponent extends Component {
  @service media;
  @service appService;
  @service intl;
  @service cardlessTrialService;
  @service router;

  get app() {
    return this.appService.app;
  }

  get showBackButton() {
    return this.cardlessTrialService.canUseEarlyStageDetection;
  }

  get backButtonLabel() {
    return this.intl.t('signup.teams.header.back');
  }

  get isOnEarlyStageApplicationPage() {
    return this.router.currentRouteName === 'apps.app.teams-checkout.early-stage-application';
  }

  get alreadySignedIn() {
    return this.args.authenticatedAdmin;
  }

  backToOnboardingHomeAndOpenConversionModal() {
    let queryParams = { action: 'buy_intercom' };

    this.router.transitionTo(this.app.onboardingHomeRoute, { queryParams });
  }

  @action
  onBackButtonClick() {
    this.backToOnboardingHomeAndOpenConversionModal();
  }
}
