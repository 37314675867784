/* import __COLOCATED_TEMPLATE__ from './tab-control.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';

export interface Tab {
  value: string;
  label: string;
  isCompleted: boolean;
  stepNumber: number;
  path?: string;
}

interface Args {
  selectedTab: string;
  tabs: Array<Tab>;
  onSelectionChange: (tab: string) => void;
  class?: string;
}

interface Signature {
  Args: Args;
}

const TabControl = templateOnlyComponent<Signature>();
export default TabControl;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::TabControl': typeof TabControl;
  }
}
