/* import __COLOCATED_TEMPLATE__ from './accordion-section.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { type PulseAccordion, type PulseAccordionApi } from 'glint/pulse';

interface Signature {
  Args: {
    sectionName: string;
    accordion: PulseAccordion;
    isDisabled?: boolean;
    tooltipContent?: string;
  };
  Blocks: {
    header: [];
    body: [];
    footer?: [];
  };
}

export default class StandaloneAccordionSection extends Component<Signature> {
  get accordionAPI() {
    return this.args.accordion.accordionAPI as PulseAccordionApi;
  }

  get isOpen() {
    return (
      this.accordionAPI.openSectionId && this.accordionAPI.openSectionId === this.args.sectionName
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::AccordionSection': typeof StandaloneAccordionSection;
  }
}
