/* import __COLOCATED_TEMPLATE__ from './development.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable no-console */

import { action } from '@ember/object';
import { ref } from 'ember-ref-bucket';
import { inject as service } from '@ember/service';

import Component from '@glimmer/component';
import IntercomAdapter from 'embercom/lib/frame-adapter';

interface Args {
  externalId: string;
  externalType: string;
  accessToken: string;
  setParams: (paramName: string, paramValue: string) => void;
}

export default class StandaloneCopilotDevelopment extends Component<Args> {
  @ref('public-textarea') declare publicTextarea: HTMLTextAreaElement;
  @ref('private-textarea') declare privateTextarea: HTMLTextAreaElement;

  @service declare notificationsService: any;

  originalExternalType = this.args.externalType;
  originalExternalId = this.args.externalId;

  declare intercomAdapter: IntercomAdapter;

  @action setupFrame(iframe: HTMLIFrameElement) {
    this.intercomAdapter = new IntercomAdapter({
      listenWindow: window,
      sendWindow: iframe.contentWindow,
      developmentFrameUrl: '*',
    });

    this.intercomAdapter.on('IC_FRAME_ADD_COMPOSER_PUBLIC_CONTENT', (payload: any) => {
      let { text } = payload;
      console.log('Received text:', text);
      this.publicTextarea.value = text;
    });

    this.intercomAdapter.on('IC_FRAME_ADD_COMPOSER_PRIVATE_CONTENT', (payload: any) => {
      let { text } = payload;
      console.log('Received text:', text);
      this.privateTextarea.value = text;
    });

    this.intercomAdapter.on('IC_FRAME_GET_CURRENT_COMPOSER_CONTENT', () => {
      this.intercomAdapter.send('IC_FRAME_CURRENT_COMPOSER_CONTENT', {
        text: this.publicTextarea.value,
        type: 'public',
      });
    });
  }

  @action onConversationChange() {
    this.intercomAdapter.send('IC_FRAME_CONVERSATION_CHANGED', {
      external_id: this.args.externalId,
      external_type: this.args.externalType,
    });

    this.notificationsService.notifyConfirmation(
      `Conversation changed to ${this.args.externalId} ${this.args.externalType}`,
    );
  }

  @action setExternalId(id: string) {
    this.args.setParams('external_id', id);
  }

  @action setExternalType(type: string) {
    this.args.setParams('external_type', type);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Copilot::Development': typeof StandaloneCopilotDevelopment;
  }
}
