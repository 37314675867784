/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
/* eslint-disable ember/no-empty-glimmer-component-classes */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { type NavItem } from 'embercom/objects/standalone/nav';

// @ts-expect-error - @intercom/pulse/lib/sanitize is not typed
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

interface Args {
  navItem: NavItem;
}

const CHILD_HEIGHT = 32;

export default class StandaloneNavbarLink extends Component<Args> {
  get childHeightStyle() {
    if (
      this.args.navItem.eligibleChildren.length &&
      (this.args.navItem.isActive || this.args.navItem.hasActiveChild)
    ) {
      return sanitizeHtml(`height: ${this.args.navItem.eligibleChildren.length * CHILD_HEIGHT}px;`);
    } else {
      return sanitizeHtml('height: 0px;');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Navbar::Link': typeof StandaloneNavbarLink;
    'standalone/navbar/link': typeof StandaloneNavbarLink;
  }
}
