/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import type {
  StandaloneCompanyAttribute,
  StandaloneUserAttribute,
} from 'embercom/objects/standalone/data-fields/common';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  archivedAttributes: any[];
  attributes: (StandaloneCompanyAttribute | StandaloneUserAttribute)[];
}

export default class StandaloneDataAttributesTableComponent extends Component<Args> {
  @service declare appService: any;
  @service declare attributeService: any;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare modalService: any;

  @tracked filterSearchTerm = '';

  get filteredAttributes() {
    return this.sortedAttributes.filter((attribute) =>
      attribute.name.toLowerCase().includes(this.filterSearchTerm.toLowerCase()),
    );
  }

  private get sortedAttributes() {
    return this.args.attributes.sort((a, b) => a.name.localeCompare(b.name));
  }

  @action
  openDetailsView(attribute: any) {
    if (attribute) {
      this.modalService.openModal('settings/modals/attribute-details', {
        attribute,
        allowArchival: attribute.isCustomData,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::DataAttributes::Table': typeof StandaloneDataAttributesTableComponent;
    'standalone/data-attributes/table': typeof StandaloneDataAttributesTableComponent;
  }
}
