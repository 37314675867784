/* import __COLOCATED_TEMPLATE__ from './test.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { action } from '@ember/object';

export interface Args {
  section: string | null;
  setSection?: (section: string | null) => void;
}

export default class Test extends Component<Args> {
  @action
  setSection(section: string | null) {
    this.args.setSection?.(section);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::Test': typeof Test;
    'standalone/intercom-messenger/deploy/Test': typeof Test;
  }
}
