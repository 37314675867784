/* import __COLOCATED_TEMPLATE__ from './deploy.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { type Tab } from 'embercom/components/standalone/common/tab-control';
import type RouterService from '@ember/routing/router-service';

type TabValue = 'integrate' | 'workflow' | 'test' | 'go-live';

interface Args {
  activeTab: TabValue;
  setSection?: (section: string | null) => void;
  openSection: string | null;
}

type SunshineTab = Required<Tab> & {
  value: TabValue;
};

export default class StandaloneZendeskMessagingDeploy extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @tracked selectedTabValue = this.args.activeTab;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }

  get sunshineIntegration() {
    return this.zendeskConfig.sunshineIntegration;
  }

  get isIntegrated() {
    return (
      this.sunshineIntegration.isSunshineConnected &&
      this.sunshineIntegration.supportedChannelIntegrationIds.length > 0
    );
  }

  get isSunshineEnabled() {
    return this.sunshineIntegration.isEnabledForSunshineMessenger;
  }

  get visualBuilderObject() {
    return this.sunshineIntegration.ruleset.value?.rulesetLinks.firstObject.object
      .visualBuilderObject;
  }

  get isWorkflowStepComplete() {
    return (
      this.isSunshineEnabled ||
      (this.visualBuilderObject?.validations.isValid &&
        ['test', 'go-live'].includes(this.selectedTabValue))
    );
  }

  get tabs(): SunshineTab[] {
    return [
      {
        value: 'integrate',
        label: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.integrate.name'),
        isCompleted: this.isIntegrated,
        path: 'apps.app.standalone.deploy.zendesk.messaging.integrate',
        stepNumber: 1,
      },
      {
        value: 'workflow',
        label: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.workflow.name'),
        isCompleted: this.isWorkflowStepComplete,
        path: 'apps.app.standalone.deploy.zendesk.messaging.workflow',
        stepNumber: 2,
      },
      {
        value: 'test',
        label: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.test.name'),
        isCompleted: this.selectedTabValue === 'go-live' || this.isSunshineEnabled,
        path: 'apps.app.standalone.deploy.zendesk.messaging.test',
        stepNumber: 3,
      },
      {
        value: 'go-live',
        label: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.go-live.name'),
        isCompleted: this.isSunshineEnabled,
        path: 'apps.app.standalone.deploy.zendesk.messaging.go-live',
        stepNumber: 4,
      },
    ];
  }

  get selectedTab(): SunshineTab {
    return this.tabs.find((tab) => tab.value === this.selectedTabValue) || this.tabs[0];
  }

  @action
  onTabSelectionChange(value: TabValue) {
    this.selectedTabValue = value;
    if (this.selectedTab) {
      this.router.transitionTo(this.selectedTab.path);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Messaging::Deploy': typeof StandaloneZendeskMessagingDeploy;
    'standalone/zendesk/messaging/deploy': typeof StandaloneZendeskMessagingDeploy;
  }
}
