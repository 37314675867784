/* import __COLOCATED_TEMPLATE__ from './manage-synced-data-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    standalonePlatform: string;
    testIdentifier?: string;
  };
  Blocks: {
    opener: [];
  };
}

const ManageSyncedDataTooltip = templateOnlyComponent<Signature>();
export default ManageSyncedDataTooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Data::Standalone::ManageSyncedDataTooltip': typeof ManageSyncedDataTooltip;
  }
}
