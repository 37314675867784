/* import __COLOCATED_TEMPLATE__ from './alias-email-address-warning.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  toggleDisplayCustomWarningModal: () => void;
  showAliasEmailAddressWarning: boolean;
  onOpenEmailSideDrawer: () => void;
}

export default class AliasEmailAddressWarning extends Component<Args> {
  @service intercomEventService: $TSFixMe;

  @action
  closeAliasEmailAddressWarning() {
    this.args.toggleDisplayCustomWarningModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Senders::Modals::AliasEmailAddressWarning': typeof AliasEmailAddressWarning;
    'settings/senders/modals/alias-email-address-warning': typeof AliasEmailAddressWarning;
  }
}
