/* import __COLOCATED_TEMPLATE__ from './early-stage-progression-checkout.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { keepLatestTask, dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { captureException } from 'embercom/lib/sentry';
import {
  BILLING_PERIODS,
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
} from 'embercom/lib/billing';
import { PricingModelIdentifier } from 'embercom/lib/billing/pricing-models';
import { DEFAULT_REQUEST_PARAMS } from 'embercom/services/quote-service';

import type Router from '@ember/routing/router-service';
import { type TaskGenerator } from 'ember-concurrency';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type Plan from 'embercom/models/plan';
import type QuoteService from 'embercom/services/quote-service';

interface Args {
  planId: number;
}
interface Signature {
  Element: any;
  Args: Args;
}

export default class EarlyStageProgressionCheckout extends Component<Signature> {
  @service declare appService: any;
  @service declare quoteService: QuoteService;
  @service declare customerService: any;
  @service declare intl: any;
  @service declare notificationsService: any;
  @service declare router: Router;

  @service declare purchaseAnalyticsService: any;

  @tracked billingPeriod = BILLING_PERIODS.Monthly;
  @tracked selectedSeatNumber = 1;
  @tracked seatNumber = 1;
  @tracked minimumSeatNumber = 1;
  @tracked selectedProactiveAddon = false;
  @tracked copilotInitialised = false;
  @tracked selectedCopilotAdmins: string[] = [];
  @tracked selectedPlanId = String(this.args.planId || PRICING_5_X_CORE_ADVANCED_ID);
  @tracked showEarlyStageProgressionModal = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.setSeatsToHumanAdminsOrCoreSeatLength();
    taskFor(this.getQuotes).perform();
    this.selectedCopilotAdmins = this.adminIdsWithCopilotSeats;
  }

  private setSeatsToHumanAdminsOrCoreSeatLength() {
    let hasAssignedCoreSeats = this.appService.app.humanAdminsWithCoreSeat.length;
    let viewingAdvanced = this.selectedPlanId === PRICING_5_X_CORE_ADVANCED_ID;

    this.minimumSeatNumber =
      hasAssignedCoreSeats && viewingAdvanced
        ? this.appService.app.humanAdminsWithCoreSeat.length
        : this.appService.app.humanAdmins.length;

    this.seatNumber = this.minimumSeatNumber;
    this.selectedSeatNumber = this.minimumSeatNumber;
  }

  get queryParamsForRedirect() {
    return {
      plan_id: this.selectedPlanId,
      billing_period: this.billingPeriod,
      seat_number: this.seatNumber,
      proactive_addon: this.selectedProactiveAddon,
      copilot_addon: this.selectedCopilotAddon,
    };
  }

  get redirectUrl() {
    let baseUrl = window.location.origin;
    let url = this.router.urlFor('apps.app.teams-checkout', this.appService.app.id, {
      queryParams: this.queryParamsForRedirect,
    });
    return `${baseUrl}${url}`;
  }

  get adminIdsWithCopilotSeats() {
    return this.appService.app.humanAdminsWithCopilotSeat.map((admin: any) => admin.id);
  }

  get selectedPlanIds() {
    let planIds = [Number(this.selectedPlanId)];
    if (this.selectedProactiveAddon) {
      planIds.push(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID));
    }
    if (this.selectedCopilotAddon) {
      planIds.push(Number(FIN_AI_COPILOT_BASE_ID));
    }
    return planIds;
  }

  get copilotSeatNumber() {
    return this.copilotInitialised ? this.selectedCopilotAdmins.length : 0;
  }

  get selectedCopilotAddon() {
    return this.copilotSeatNumber > 0;
  }

  get sendCopilotAdminIds() {
    return this.copilotSeatNumber > 0;
  }

  get loading() {
    return this.quoteService.loading;
  }

  get quote() {
    let planIds = [Number(this.selectedPlanId), ...this.nonCorePlanIdsToFetch];
    return this.quoteService.getQuoteById(planIds);
  }

  get fullSeatPrice() {
    return this.quote?.fullSeatPrice(Number(this.selectedPlanId), Metric.core_seat_count) || 0;
  }

  get fullProactiveAddonPrice() {
    return this.quote?.fullBreakdownAmount(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID)) || 0;
  }

  get perSeatCopilotAddonPrice() {
    return (
      this.quote?.fullSeatPrice(Number(FIN_AI_COPILOT_BASE_ID), Metric.copilot_seat_count) || 0
    );
  }

  get fullCopilotAddonPrice() {
    return this.perSeatCopilotAddonPrice * this.selectedCopilotAdmins.length;
  }

  get monthlyCopilotPriceForSeats() {
    return this.perSeatCopilotAddonPrice * this.copilotSeatNumber;
  }

  get totalPrice() {
    return this.monthlyTotal;
  }

  get totalPriceInCents() {
    return this.totalPrice * 100;
  }

  get totalPriceFormatted() {
    return this.intl.t('signup.teams.pricing5.annual-plans.basket.due-today-price', {
      yearly: false,
      price: this.intl.formatNumber(this.totalPrice, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }),
      htmlSafe: true,
    });
  }

  get monthlyFullSeatPrice() {
    return this.fullSeatPrice * this.seatNumber;
  }

  get monthlyTotal() {
    let total = this.monthlyFullSeatPrice;
    if (this.selectedProactiveAddon) {
      total += this.fullProactiveAddonPrice;
    }
    if (this.selectedCopilotAddon) {
      total += this.fullCopilotAddonPrice;
    }
    return total;
  }

  get backButtonLabel() {
    return this.intl.t('signup.teams.header.back');
  }

  get corePlans() {
    let plansOnPricingModel = this.appService.app.allPlansOnPricingModel;
    if (!this.appService.app.onPricing5) {
      plansOnPricingModel = this.customerService.earlyStageGraduation.plans;
    }
    return plansOnPricingModel.filter((plan: Plan) => !plan.product.addon);
  }

  get nonCorePlanIdsToFetch() {
    // is there a way to get this from this.customerService.earlyStageGraduation.plans
    if (this.appService.app.copilotProduct) {
      return [Number(PROACTIVE_SUPPORT_PLUS_BASE_ID), Number(FIN_AI_COPILOT_BASE_ID)];
    } else {
      return [Number(PROACTIVE_SUPPORT_PLUS_BASE_ID)];
    }
  }

  get planIdsForQuote() {
    return this.corePlans.map((plan: Plan) => [plan.idAsNumber, ...this.nonCorePlanIdsToFetch]);
  }

  get planIdsForInitialQuote() {
    return [Number(this.selectedPlanId), ...this.nonCorePlanIdsToFetch];
  }

  get proactiveSupportBaseUsage() {
    return this.quote?.proactiveSupportBaseUsage;
  }

  @action
  onBackButtonClick() {
    this.router.transitionTo('apps.app.settings.subscription.billing.update-subscription');
  }

  @action showProactiveSupportProArticle() {
    window.Intercom('showArticle', this.appService.app.proactiveSupportProArticleId);
    this.sendAnalyticsEvent({
      action: 'clicked',
      object: 'metered_proactive_support_plus_article',
    });
  }

  @action
  setSelectedCopilotAdmins(selectedAdmins: string[]) {
    this.selectedCopilotAdmins = selectedAdmins;
  }

  @action
  setCopilotAsInitialised(value: boolean) {
    this.copilotInitialised = value;
  }

  @action
  setSelectedProactiveAddon(value: boolean) {
    this.selectedProactiveAddon = value;
    this.sendAnalyticsEvent({
      action: value ? 'selected' : 'unselected',
      object: 'proactive_support_addon_selector',
    });
  }

  @action
  updateSelectedPlanId(plan: Plan) {
    this.selectedPlanId = plan.id;
    this.setSeatsToHumanAdminsOrCoreSeatLength();
    this.sendAnalyticsEvent({
      action: 'selected',
      object: 'plan_selector',
      context: { plan: this.selectedPlanId },
    });
  }

  @action
  updateSeatNumber(seatNumber: number) {
    this.selectedSeatNumber = seatNumber;
    this.seatNumber = this.selectedSeatNumber;
    this.sendAnalyticsEvent({
      action: 'inputed',
      object: 'seat_number_input',
      context: { seat_number: `${this.seatNumber}` },
    });
  }

  @action openEarlyStageProgressionModal() {
    this.showEarlyStageProgressionModal = true;
  }

  @action closeEarlyStageProgressionModal() {
    this.showEarlyStageProgressionModal = false;
  }

  @action async updateEarlyStageProgressionPlans() {
    let earlyStageGraduation = this.customerService.earlyStageGraduation;
    earlyStageGraduation.planIds = this.selectedPlanIds;
    earlyStageGraduation.priceSetIdentifier =
      earlyStageGraduation.pricingModelForNonEarlyStagePlans;

    this.sendAnalyticsEvent({
      action: 'clicked',
      object: 'configure_new_subscription_modal',
      context: {
        new_progression_experience: true,
        plan_ids: earlyStageGraduation.planIds,
        price_set_identifier: earlyStageGraduation.priceSetIdentifier,
        proactive_addon: `${this.selectedProactiveAddon}`,
        copilot_addon: `${this.selectedCopilotAddon}`,
        copilot_seat_count: `${this.copilotSeatNumber}`,
      },
    });
    await taskFor(this.saveEarlyStageGraduation).perform();
    this.closeEarlyStageProgressionModal();
    return this.router.transitionTo('apps.app.billing.summary', this.customerService.app.id, {
      queryParams: {
        esChoiceConfirmation: true,
      },
    });
  }

  @action sendAnalyticsEvent({
    action,
    object,
    context,
  }: {
    action: string;
    object: string;
    context?: Record<string, any>;
  }) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object,
      context,
      place: 'p5-early-stage-progression',
    });
  }

  @dropTask
  *saveEarlyStageGraduation(): TaskGenerator<void> {
    try {
      yield this.customerService.earlyStageGraduation.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('billing.summary.current-and-future-price-component.success-saving-plan'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('billing.summary.current-and-future-price-component.error-saving-plan'),
      );
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase',
          responsible_team: 'team-purchase',
        },
      });
      console.error(err);
    }
  }

  @keepLatestTask
  *getQuotes(): TaskGenerator<void> {
    let params = this.planIdsForQuote.map((planIds: string[]) => {
      return {
        ...DEFAULT_REQUEST_PARAMS,
        planIds,
        pricingModelIdentifier: PricingModelIdentifier.PRICING_5_1,
        source: 'pricing-five-early-stage-progression-checkout',
        coreSeatCount: this.seatNumber,
      };
    });
    yield taskFor(this.quoteService.getQuotes).perform(params);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Checkouts::EarlyStageProgressionCheckout': typeof EarlyStageProgressionCheckout;
  }
}
