/* import __COLOCATED_TEMPLATE__ from './fin-identity.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type { PulseAccordion } from 'glint/pulse';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export interface Args {
  accordion: PulseAccordion;
  closeAccordion: () => void;
}

export default class FinIdentity extends Component<Args> {
  @service declare router: RouterService;
  @service declare finStandaloneService: FinStandaloneService;

  get isIncomplete() {
    return !this.finStandaloneService.operatorIdentity;
  }

  get zendeskMessagingUrl() {
    return this.router.urlFor('apps.app.standalone.deploy.zendesk.messaging.integrate');
  }

  @action async saveOperatorIdentity() {
    await this.finStandaloneService.updateOperatorIdentity();
    this.args.closeAccordion();
  }

  @action goToMessengerSettings() {
    safeWindowOpen(this.router.urlFor('apps.app.settings.channels.messenger.general'), '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::Integrate::FinIdentity': typeof FinIdentity;
    'standalone/intercom-messenger/deploy/integrate/fin-identity': typeof FinIdentity;
  }
}
