/* import __COLOCATED_TEMPLATE__ from './connect-zendesk-apis.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { type PulseAccordion } from 'glint/pulse';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type SunshineConfiguration from 'embercom/models/standalone/sunshine-configuration';
import { action } from '@ember/object';
import type { ConnectionOption } from 'embercom/components/standalone/common/connect-card';
import type IntlService from 'embercom/services/intl';

export interface Args {
  accordion: PulseAccordion;
  hideSunshine?: boolean;
}

export default class ConnectZendeskApis extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;

  @tracked isZendeskApiSetupDrawerOpen = false;
  @tracked isSunshineApiSetupDrawerOpen = false;

  get zendeskApiConnectionCard(): ConnectionOption {
    return {
      value: 'zendesk-api',
      icon: 'zendesk',
      label: this.intl.t('standalone.zendesk.deploy.zendesk-api'),
      description: this.intl.t('standalone.zendesk.deploy.connect-zendesk-api-description'),
      isConnected: !this.zendeskConfig?.isPendingSetupForTickets,
      manage: () => {
        this.openSetupDrawer('zendesk');
      },
      connect: () => {
        this.openSetupDrawer('zendesk');
      },
    };
  }

  get sunshineApiConnectionCard(): ConnectionOption {
    return {
      value: 'sunshine-api',
      icon: 'zendesk',
      label: this.intl.t('standalone.zendesk.deploy.sunshine-api'),
      description: this.intl.t('standalone.zendesk.deploy.connect-sunshine-api-description'),
      isConnected: !this.sunshineConfig?.isSunshineDisconnected,
      manage: () => {
        this.openSetupDrawer('sunshine');
      },
      connect: () => {
        this.openSetupDrawer('sunshine');
      },
    };
  }

  get zendeskConfig(): ZendeskConfiguration | undefined {
    return this.finStandaloneService.zendeskConfig;
  }

  get sunshineConfig(): SunshineConfiguration | undefined {
    return this.finStandaloneService.zendeskConfig?.sunshineIntegration;
  }

  get isSunshineDisconnected() {
    return !this.sunshineConfig || this.sunshineConfig.isSunshineDisconnected;
  }

  get isIncomplete() {
    return this.zendeskConfig?.isPendingSetupForTickets || this.isSunshineDisconnected;
  }

  @action
  openSetupDrawer(sideDrawer: 'zendesk' | 'sunshine') {
    if (sideDrawer === 'zendesk') {
      this.isZendeskApiSetupDrawerOpen = true;
    } else {
      this.isSunshineApiSetupDrawerOpen = true;
    }
  }

  @action
  onContinue() {
    this.args.accordion.accordionAPI.openSection('zendesk-setup-fin-messenger-identity');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::ConnectZendeskApis': typeof ConnectZendeskApis;
    'standalone/zendesk/deploy/connect-zendesk-apis': typeof ConnectZendeskApis;
  }
}
