/* import __COLOCATED_TEMPLATE__ from './fin-identity.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { type PulseAccordion } from 'glint/pulse';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';

interface Signature {
  Args: {
    accordion: PulseAccordion;
    setUpdatingFinAgent: (isLoading: boolean) => void;
    onContinue: () => void;
  };
}

export default class FinIdentityComponent extends Component<Signature> {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }

  get isComplete() {
    return Boolean(this.zendeskConfig.ticketsIntegration.finZendeskUserId);
  }

  @action
  continue() {
    if (this.args.onContinue) {
      this.args.onContinue();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::Integrate::FinIdentity': typeof FinIdentityComponent;
  }
}
