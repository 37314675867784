/* import __COLOCATED_TEMPLATE__ from './conditions-setting-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    helpArticleId?: string;
    conditionsUnavailableErrorMessage?: string;
  };
  Blocks: {
    default: [];
  };
}

const ConditionsSettingTooltip = templateOnlyComponent<Signature>();
export default ConditionsSettingTooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::ConversationAttributes::ConditionsSettingTooltip': typeof ConditionsSettingTooltip;
    'settings/conversation-attributes/conditions-setting-tooltip': typeof ConditionsSettingTooltip;
  }
}
