/* import __COLOCATED_TEMPLATE__ from './connect.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { RESPONSE_PROVIDED_ERRORS } from 'embercom/objects/standalone/constants';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  stackVertically?: boolean;
  hideBorder?: boolean;
  hideTitle?: boolean;
  closeOnSave?: () => void;
}

export default class StandaloneZendeskConnect extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;
  @service declare notificationsService: {
    notifyConfirmation: (message: string) => void;
    notifyResponseError: (
      e: Error,
      messages: { default: string },
      options: { responseProvidedErrors: number[] },
    ) => void;
  };

  @tracked isSaving = false;

  @action async save() {
    this.isSaving = true;
    try {
      await this.finStandaloneService.saveZendeskConfig();
      this.args.closeOnSave?.();
    } finally {
      this.isSaving = false;
    }
  }

  @action async revokeAccess() {
    let isConfirmed = await this.intercomConfirmService.confirm({
      body: this.intl.t('standalone.zendesk.settings.connect.confirm'),
      confirmButtonText: this.intl.t('standalone.zendesk.settings.connect.confirm-button'),
    });

    try {
      if (isConfirmed) {
        await this.zendeskConfig.revoke();
        this.notificationsService.notifyConfirmation(
          this.intl.t('standalone.zendesk.settings.connect.revoke-success-notify'),
        );
      }
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        { default: this.intl.t('standalone.zendesk.settings.connect.revoke-failure-notify') },
        { responseProvidedErrors: RESPONSE_PROVIDED_ERRORS },
      );
      console.error(e.message || e.jqXHR);
    }
  }

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Settings::Connect': typeof StandaloneZendeskConnect;
    'standalone/zendesk/settings/connect': typeof StandaloneZendeskConnect;
  }
}
