/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { type TaskGenerator } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type AiContentSegment from 'embercom/models/ai-content-segment';
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import Ruleset from 'embercom/models/matching-system/ruleset';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { tracked } from '@glimmer/tracking';
import { EVERYONE_PREDICATE } from 'embercom/lib/operator/custom-bots/constants';
import { action } from '@ember/object';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { type EmberCPValidationsResult } from 'embercom/validations/typescript/ember-cp-validations-compat';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import type ContentImportService from 'embercom/services/content-import-service';
import { isPresent } from '@ember/utils';
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';
import type { Router } from '@ember/routing';
import { validateSegmentWithRuleset } from 'embercom/validations/typescript/ai-content-segments/segment';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import type IntlService from 'ember-intl/services/intl';

interface Args {}

export default class Editor extends Component<Args> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare contentEditorService: any;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare intercomEventService: any;
  @service declare helpCenterService: any;
  @service declare contentImportService: ContentImportService;
  @service declare permissionsService: $TSFixMe;
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;

  @tracked selectedRuleset?: any;
  @tracked selectedSectionId = '';
  @tracked segments: AiContentSegment[] = [];

  owner: unknown;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.owner = owner;
    this.segments = this.aiContentSegmentsService.segments.sortBy('createdAt').reverse();
  }

  get audienceEditorConfiguration() {
    return new StandardAudienceEditorConfiguration({
      container: this.owner,
      showClientPredicates: false,
      showMatchBehavior: false,
      showSendToUnsubscribedToggle: false,
      showAudiencePreview: this.showAudiencePreview,
      showEventRules: false,
      showDateEvents: false,
      includeConversationAttributes: this.appService.app.canUseAudienceForCopilot,
      showAudiencePreviewHidden: !this.showAudiencePreview,
      audiencePreviewHiddenText: this.audiencePreviewHiddenText,
      showUnsupportedAttributesBanner: this.hasInvalidConversationPredicates,
      unsupportedAttributesBannerText: this.intl.t(
        'settings.audience.settings.invalid-conversation-predicates',
      ),
      useSupportedPredicateAttributes: !this.appService.app.canUseAudienceForCopilot,
    });
  }

  get segment(): AiContentSegment {
    return this.contentEditorService.activeObject as AiContentSegment;
  }

  get sources(): ContentImportSource[] {
    return this.contentImportService.contentImportSources ?? [];
  }

  get validations(): EmberCPValidationsResult {
    return validateSegmentWithRuleset(this.segment, this.selectedRuleset);
  }

  get helpCenters(): HelpCenterSite[] {
    return this.helpCenterService.allSites;
  }

  get emptyStateHeader() {
    return this.appService.app.canUseAudienceForCopilot
      ? this.intl.t('settings.audience.settings.empty-state.heading')
      : this.intl.t('settings.audience.empty-state.heading');
  }

  get emptyStateDescription() {
    return this.appService.app.canUseAudienceForCopilot
      ? this.intl.t('settings.audience.settings.empty-state.description')
      : this.intl.t('settings.audience.empty-state.description');
  }

  @action openManageKnowledge() {
    this.router.transitionTo('apps.app.knowledge-hub');
  }

  @action async exitEditMode() {
    this.contentEditorService.unregister(this.selectedRuleset);
    this.selectedRuleset = undefined;
    await this.reloadSegments();
    this.selectedSectionId = '';
  }

  private async reloadSegments(shouldUnload = true) {
    if (shouldUnload) {
      this.store.unloadAll('ai-content-segment');
    }
    let results = await this.store.findAll('ai-content-segment');

    await this.aiContentSegmentsService.refreshSegments();

    this.segments = results.toArray().sortBy('createdAt').reverse();
  }

  @action async onCancel() {
    await this.contentEditorService.cancelRulesetChanges.perform();
    await this.exitEditMode();
  }

  @action async onSave() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    await this.contentEditorService.saveRuleset.perform();
    this.trackAnalyticsEvent('clicked', 'save_button', {
      brand_predicate_selected: this.rulesetContainsBrandPredicate,
    });
    await this.exitEditMode();
  }

  @action async onDelete() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    let segment = this.segment;
    await this.contentEditorService.deleteRuleset.perform({ transitionRoute: false });
    segment.unloadRecord();
    await this.reloadImportServiceAndHelpCenter();
    await this.exitEditMode();
    this.trackAnalyticsEvent('clicked', 'delete_button');
  }

  private async reloadImportServiceAndHelpCenter() {
    await Promise.all([
      this.contentImportService.forceFetchContentImportSources(),
      this.helpCenterService.forceFetchSites(),
    ]);
  }

  get segmentsLoading() {
    return taskFor(this.aiContentSegmentsService.loadContentSegments).isRunning;
  }

  get isLoading() {
    return taskFor(this.selectSegment).isRunning || taskFor(this.newSegment).isRunning;
  }

  @restartableTask *selectSegment(segment: AiContentSegment): TaskGenerator<void> {
    if (isPresent(this.selectedRuleset)) {
      if (this.segmentAlreadySelected(segment)) {
        return;
      }
    }
    let selectedSegment;

    try {
      selectedSegment = yield this.store.findRecord('ai-content-segment', segment.id, {
        reload: true,
      });
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('settings.audience.segment-removed'));
      yield this.reloadSegments();
      this.selectedSectionId = '';
      return;
    }

    this.selectedRuleset = yield this.store.findRecord(
      'matching-system/ruleset',
      selectedSegment.rulesetId,
    );
    this.contentEditorService.register({
      ruleset: this.selectedRuleset,
      mode: 'edit',
    });
  }

  private segmentAlreadySelected(segment: AiContentSegment) {
    return segment.rulesetId === Number(this.selectedRuleset.id);
  }

  @restartableTask *newSegment(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    if (isPresent(this.selectedRuleset)) {
      yield this.onCancel();
    }

    let ruleset = yield Ruleset.createForType(this.store, {
      app_id: this.appService.app.id,
      object_type: objectTypes.aiContentSegment,
      match_behavior: matchBehaviors.transient,
      object_data: {},
      role_predicate_group: { predicates: [EVERYONE_PREDICATE] },
    });

    this.selectedRuleset = ruleset;
    yield this.contentEditorService.register({
      ruleset: this.selectedRuleset,
      mode: 'edit',
    });
    yield this.reloadSegments(false);
    this.selectedSectionId = 'section-0';
    this.trackAnalyticsEvent('clicked', 'new_segment_button');
  }

  @action
  async onOpenSectionChange(sectionId: string) {
    if (isPresent(this.selectedRuleset) && sectionId === null) {
      // Do not close we are in edit mode
      return;
    }

    if (isPresent(this.selectedRuleset)) {
      this.selectedSectionId = '';
      await this.onCancel();
    }
    this.selectedSectionId = sectionId;
  }

  get rulesetHasConversationAttributes() {
    let predicates = this.selectedRuleset.predicateGroup.predicates.serialize();
    return this.filterForPredicates(predicates, ['conversation', 'brand']);
  }

  get showAudiencePreview() {
    return !this.appService.app.canUseStandalone && !this.rulesetHasConversationAttributes;
  }

  get audiencePreviewHiddenText() {
    return !this.appService.app.canUseStandalone
      ? this.intl.t('settings.audience.no-preview-conversation')
      : '';
  }

  @action
  segmentContainsConversationAttributes(segment: AiContentSegment) {
    return this.filterForPredicates(segment.predicateGroup?.predicates.serialize(), [
      'conversation',
      'brand',
    ]);
  }

  get rulesetContainsBrandPredicate() {
    let predicates = this.selectedRuleset.predicateGroup.predicates.serialize();
    return this.filterForPredicates(predicates, ['brand']);
  }

  get hasInvalidConversationPredicates() {
    return !this.appService.app.canUseAudienceForCopilot && this.rulesetHasConversationAttributes;
  }

  private filterForPredicates(predicates: $TSFixMe, attributeNames: string[] = []) {
    return predicates?.any((predicate: $TSFixMe) => {
      if (predicate.predicates) {
        return this.filterForPredicates(predicate.predicates, attributeNames);
      } else {
        return attributeNames.any((attribute) => predicate.attribute.startsWith(attribute));
      }
    });
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'operator',
      context: 'manage_audience_settings',
      place: 'settings',
      ...metadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Audience::Editor': typeof Editor;
  }
}
