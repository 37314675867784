/* import __COLOCATED_TEMPLATE__ from './ensure-fin-ready-item.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import templateOnlyComponent from '@ember/component/template-only';

export interface EnsureFinReadyItem {
  id: string;
  icon: InterfaceIconName;
  title: string;
  description: string;
  hideBorder?: boolean;
  stamp?: {
    color: 'green' | 'gray';
    text: string;
  };
  onClick: () => void;
}

const StandaloneCommonEnsureFinReadyItem = templateOnlyComponent<EnsureFinReadyItem>();
export default StandaloneCommonEnsureFinReadyItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::EnsureFinReadyItem': typeof StandaloneCommonEnsureFinReadyItem;
    'standalone/common/ensure-fin-ready-item': typeof StandaloneCommonEnsureFinReadyItem;
  }
}
