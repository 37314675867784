/* import __COLOCATED_TEMPLATE__ from './accordion-section-header.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  isLoading?: boolean;
  isIncomplete?: boolean;
  isComplete?: boolean;
  isRecommended?: boolean;
  isRequired?: boolean;
  isOptional?: boolean;
  title: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const AccordionSectionHeader = templateOnlyComponent<Signature>();
export default AccordionSectionHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::AccordionSectionHeader': typeof AccordionSectionHeader;
  }
}
