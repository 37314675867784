/* import __COLOCATED_TEMPLATE__ from './ensure-fin-ready.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { type PulseAccordion } from 'glint/pulse';
import { inject as service } from '@ember/service';
import type ContentImportService from 'embercom/services/content-import-service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { EntityType } from 'embercom/models/data/entity-types';
import type Store from '@ember-data/store';
import CustomBotListData from 'embercom/objects/operator/configuration/custom-bots/custom-bot-list-data';
import { taskFor } from 'ember-concurrency-ts';
import { AI_AGENT_WORKFLOW_TARGET } from 'embercom/lib/operator/custom-bots/constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { type EnsureFinReadyItem } from 'embercom/components/standalone/common/ensure-fin-ready-item';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router';

export interface Args {
  accordion: PulseAccordion;
  disableContinue?: boolean;
  onClose: () => void;
}

export interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class StandaloneCommonEnsureFinReady extends Component<Signature> {
  listData: CustomBotListData;

  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare appService: { app: { id: string } };
  @service declare finStandaloneService: FinStandaloneService;
  @service declare contentImportService: ContentImportService;

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.listData = new CustomBotListData(
      owner,
      this.appService.app.id,
      AI_AGENT_WORKFLOW_TARGET,
      objectTypes.triggerableCustomBot,
    );

    taskFor(this.listData.setupInitialState).perform();
  }

  get isIncomplete() {
    return !this.knowledgeContentCount && !this.guidelinesCount && !this.tasksCount;
  }

  get knowledgeContentCount() {
    let summary = this.finStandaloneService.knowledgeSourceSummary;

    let contentCount = [
      EntityType.ArticleContent,
      EntityType.ContentSnippet,
      EntityType.FileSourceContent,
      EntityType.Answer,
    ].reduce((total, type) => total + (summary[type]?.used_by_fin_count ?? 0), 0);

    let activeImportCount =
      this.contentImportService.contentImportSources?.filter((source) => source.isInUse).length ??
      0;

    return contentCount + activeImportCount;
  }

  get guidelinesCount() {
    let guidelines = this.store.peekAll('ai-agent/guidelines').toArray();
    return guidelines.filter((guideline) => !guideline.isDeleted).length;
  }

  get tasksCount() {
    return this.listData.bots?.length || 0;
  }

  get items(): Array<EnsureFinReadyItem> {
    let noneAddedText = this.intl.t('standalone.common.ensure-fin-ready.pills.none-added');

    return [
      {
        id: 'content',
        icon: 'knowledge',
        title: this.intl.t('standalone.common.ensure-fin-ready.content'),
        description: this.intl.t('standalone.common.ensure-fin-ready.content-description'),
        stamp: {
          color: this.knowledgeContentCount ? 'green' : 'gray',
          text: this.knowledgeContentCount ? this.knowledgeContentCount.toString() : noneAddedText,
        },
        onClick: () => this.router.transitionTo('apps.app.knowledge-hub.overview'),
      },
      {
        id: 'guidance',
        icon: 'note',
        title: this.intl.t('standalone.common.ensure-fin-ready.guidance'),
        description: this.intl.t('standalone.common.ensure-fin-ready.guidance-description'),
        stamp: {
          color: this.guidelinesCount ? 'green' : 'gray',
          text: this.guidelinesCount ? this.guidelinesCount.toString() : noneAddedText,
        },
        onClick: () => this.router.transitionTo('apps.app.standalone.guidance'),
      },
      {
        id: 'tasks',
        icon: 'custom-attribute',
        title: this.intl.t('standalone.common.ensure-fin-ready.tasks'),
        description: this.intl.t('standalone.common.ensure-fin-ready.tasks-description'),
        stamp: {
          color: this.tasksCount ? 'green' : 'gray',
          text: this.tasksCount ? this.tasksCount.toString() : noneAddedText,
        },
        onClick: () => this.router.transitionTo('apps.app.standalone.tasks'),
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::EnsureFinReady': typeof StandaloneCommonEnsureFinReady;
    'standalone/common/ensure-fin-ready': typeof StandaloneCommonEnsureFinReady;
  }
}
