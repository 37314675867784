/* import __COLOCATED_TEMPLATE__ from './checkout-header.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
  Element: never;
  Blocks: {
    default: [];
  };
}

const CheckoutHeaderComponent = templateOnlyComponent<Signature>();
export default CheckoutHeaderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::CheckoutHeader': typeof CheckoutHeaderComponent;
    'signup/teams/pricing5/components/checkout-header': typeof CheckoutHeaderComponent;
  }
}
