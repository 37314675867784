/* import __COLOCATED_TEMPLATE__ from './trigger-config.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class StandaloneZendeskAssignTriggerConfig extends Component {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare notificationsService: {
    notifyConfirmation(message: string): void;
    notifyResponseError(error: Error, options: { default: string }): void;
  };

  @tracked manageAssignmentTrigger = this.zendeskConfig.ticketsIntegration.manageAssignmentTrigger;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get assignmentTriggerId() {
    return this.ticketsSetupData?.live_trigger?.id;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData;
  }

  get triggerUrl() {
    if (!this.assignmentTriggerId) {
      return null;
    }

    return `https://${this.zendeskConfig.subdomain}.zendesk.com/admin/objects-rules/rules/triggers/${this.assignmentTriggerId}`;
  }

  @action
  editTrigger() {
    safeWindowOpen(this.triggerUrl, '_blank');
  }

  @action
  toggleManageAssignmentTrigger() {
    this.manageAssignmentTrigger = !this.manageAssignmentTrigger;
    this.updateManageAssignmentTrigger();
  }

  @action
  async updateManageAssignmentTrigger() {
    try {
      await this.zendeskConfig.ticketsIntegration.updateManageAssignmentTrigger(
        this.manageAssignmentTrigger,
      );
      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.zendesk.deploy.tickets.assign.trigger-config.update-success'),
      );
    } catch (error) {
      console.error(error.message || error.jqXHR);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'standalone.zendesk.deploy.tickets.assign.trigger-config.update-error',
        ),
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::Assign::TriggerConfig': typeof StandaloneZendeskAssignTriggerConfig;
    'standalone/zendesk/deploy/tickets/assign/trigger-config': typeof StandaloneZendeskAssignTriggerConfig;
  }
}
