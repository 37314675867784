/* import __COLOCATED_TEMPLATE__ from './connect-card.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type { InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';
import { type ButtonType } from '@intercom/pulse/template-registry';

export interface ConnectionOption {
  value: string;
  icon: InterfaceIconName;
  label: string;
  isConnected: boolean;
  description: string;
  manage: () => void;
  connect: () => void;
  stampConnected?: string;
  stampNotConnected?: string;
  buttonIcon?: InterfaceIconName;
  buttonConnected?: string;
  buttonNotConnected?: string;
  buttonDisabledTooltip?: string;
}

interface buttonConfig {
  label: string;
  type: ButtonType;
  icon?: InterfaceIconName;
  action: () => void;
  disabledTooltip?: string;
}

interface StampConfig {
  text: string;
  color:
    | 'transparent'
    | 'green'
    | 'sky'
    | 'blue'
    | 'yellow'
    | 'red'
    | 'gray'
    | 'yellow-light'
    | undefined;
}

interface Args {
  connection: ConnectionOption;
}

interface Signature {
  Args: Args;
}

export default class ConnectCard extends Component<Signature> {
  @service declare intl: IntlService;

  get stampConfig(): StampConfig {
    if (this.args.connection.isConnected) {
      return {
        text:
          this.args.connection.stampConnected ||
          this.intl.t('standalone.common.connect-card.status.connected'),
        color: 'green',
      };
    } else {
      return {
        text:
          this.args.connection.stampNotConnected ||
          this.intl.t('standalone.common.connect-card.status.not-connected'),
        color: 'red',
      };
    }
  }

  get buttonConfig(): buttonConfig {
    if (this.args.connection.isConnected) {
      return {
        label:
          this.args.connection.buttonConnected ||
          this.intl.t('standalone.common.connect-card.button.manage'),
        type: 'secondary',
        icon: this.args.connection.buttonIcon || undefined,
        action: this.args.connection.manage,
        disabledTooltip: this.args.connection.buttonDisabledTooltip,
      };
    } else {
      return {
        label:
          this.args.connection.buttonNotConnected ||
          this.intl.t('standalone.common.connect-card.button.connect'),
        type: 'primary',
        icon: this.args.connection.buttonIcon || undefined,
        action: this.args.connection.connect,
        disabledTooltip: this.args.connection.buttonDisabledTooltip,
      };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::ConnectCard': typeof ConnectCard;
  }
}
