/* import __COLOCATED_TEMPLATE__ from './test.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';

interface Args {
  changeTab: (tab: string) => void;
  openSection: string | null;
  setSection?: (section: string | null) => void;
}

export default class StandaloneZendeskDeploySunshineTabsTest extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get isSunshineDisconnected() {
    return !this.finStandaloneService.zendeskConfig?.sunshineIntegration.isSunshineConnected;
  }

  @action
  setSection(section: string | null): void {
    this.args.setSection?.(section);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine::Tabs::Test': typeof StandaloneZendeskDeploySunshineTabsTest;
    'standalone/zendesk/deploy/sunshine/tabs/test': typeof StandaloneZendeskDeploySunshineTabsTest;
  }
}
