/* import __COLOCATED_TEMPLATE__ from './seat-usage-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { isBlank, isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  PRICING_5_X_FULL_SEAT_TYPE as CORE,
  TEAMMATE_USAGE,
  PRICING_5_X_SEAT_TYPES,
  VBP2_SEAT_TYPES,
  COPILOT_SEAT_TYPE as COPILOT,
} from 'embercom/lib/settings/seats/constants';
export default class SeatUsageTooltip extends Component {
  @service customerService;
  @service appService;

  @tracked seatLimits = this.customerService.customer.includedSeatCountPerType || {};
  @tracked availableSeatTypes = this.customerService.customer.availableSeatTypes;

  get app() {
    return this.appService.app;
  }

  get fullSeatList() {
    let seatList;
    if (this.app.onPricing5) {
      seatList = PRICING_5_X_SEAT_TYPES;
    } else {
      seatList = VBP2_SEAT_TYPES;
    }

    let filteredSeatList = seatList.filter(
      (seat) => !this.args.hideCopilotSeat || seat !== COPILOT,
    );

    return filteredSeatList.map((seat) => {
      return {
        type: seat,
        label: this._seatUsageLabel(seat),
      };
    });
  }

  get seatUsageInfos() {
    return this.fullSeatList.filter((seatType) => {
      return isBlank(this.availableSeatTypes) || this._workspaceCanUseSeatType(seatType.type);
    });
  }

  _workspaceCanUseSeatType(seatType) {
    return this.availableSeatTypes.includes(seatType);
  }

  _seatUsageLabel(seatType) {
    let seatLimit = this.seatLimits[seatType];

    if (seatLimit === null) {
      return this._usedSeatsByType(seatType);
    } else if (!this.app.isSalesforceContracted && seatType === CORE) {
      return `${this._usedSeatsByType(seatType)}`;
    } else {
      return `${this._usedSeatsByType(seatType)}/${seatLimit}`;
    }
  }

  _usedSeatsByType(seatType) {
    return this._hasUsedSeatType(seatType) ? this.args.seatsUsed[seatType][TEAMMATE_USAGE] : 0;
  }

  _hasUsedSeatType(seatType) {
    return (
      isPresent(this.args.seatsUsed[seatType]) &&
      isPresent(this.args.seatsUsed[seatType][TEAMMATE_USAGE])
    );
  }
}
