/* import __COLOCATED_TEMPLATE__ from './tickets-connection.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { type ConnectionOption } from 'embercom/components/standalone/common/connect-card';
import type IntlService from 'ember-intl/services/intl';

interface Signature {
  Args: {};
}

export default class TicketsConnectionComponent extends Component<Signature> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @tracked zendeskConnectionDrawerIsOpen = false;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }

  get connectionOption(): ConnectionOption {
    return {
      value: 'zendesk-api',
      icon: 'zendesk',
      label: this.intl.t('standalone.common.connect-card.zendesk.api.title'),
      description: this.intl.t('standalone.common.connect-card.zendesk.api.description'),
      isConnected: !this.zendeskConfig?.isPendingSetupForTickets,
      manage: this.openConnectionDrawer,
      connect: this.openConnectionDrawer,
    };
  }

  @action
  openConnectionDrawer() {
    this.zendeskConnectionDrawerIsOpen = true;
  }

  @action
  onClose() {
    this.zendeskConnectionDrawerIsOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::Integrate::ConnectApi::TicketsConnection': typeof TicketsConnectionComponent;
  }
}
