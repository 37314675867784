/* import __COLOCATED_TEMPLATE__ from './help-centers.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  helpCenters: Array<HelpCenterSite>;
}

export default class HelpCenters extends Component<Args> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Audience::Contents::HelpCenters': typeof HelpCenters;
  }
}
