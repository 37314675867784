/* import __COLOCATED_TEMPLATE__ from './ensure-fin-is-ready.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnly from '@ember/component/template-only';
import { type PulseAccordion } from 'glint/pulse';

export interface Args {
  accordion: PulseAccordion;
  onClose: () => void;
}

let EnsureFinIsReady = templateOnly<Args>();

export default EnsureFinIsReady;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::Test::EnsureFinIsReady': typeof EnsureFinIsReady;
    'standalone/intercom-messenger/deploy/test/ensure-fin-is-ready': typeof EnsureFinIsReady;
  }
}
