/* import __COLOCATED_TEMPLATE__ from './fin-identity.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

interface Args {}

export default class FinIdentity extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.router.on('routeWillChange', this.warnIfUnsavedChangesOnRouteTransition);
  }

  willDestroy() {
    super.willDestroy();
    this._rollBackOperatorIdentity();
    try {
      this.router.off('routeWillChange', this.warnIfUnsavedChangesOnRouteTransition);
    } catch (e) {
      // ignore if this has already been removed to hide the console error
    }
  }

  get operatorIdentity() {
    return this.finStandaloneService.operatorIdentity;
  }

  get operatorIdentityPreviewImgSrc() {
    return this.finStandaloneService.operatorIdentityPreviewImgSrc;
  }

  @action
  async warnIfUnsavedChangesOnRouteTransition(transition: any) {
    if (
      this.operatorIdentity?.hasDirtyAttributes &&
      !transition.isAborted &&
      transition.to?.name !== transition.from?.name
    ) {
      transition.abort();

      let confirmNotSaved = {
        title: this.intl.t('operator.fin.setup.personality.unsaved-changes-modal.title'),
        confirmButtonText: this.intl.t(
          'operator.fin.setup.personality.unsaved-changes-modal.confirm',
        ),
        canceText: this.intl.t('operator.fin.setup.personality.unsaved-changes-modal.cancel'),
        body: this.intl.t('operator.fin.setup.personality.unsaved-changes-modal.body'),
      };

      let confirmed = await this.intercomConfirmService.confirm(confirmNotSaved);

      if (!confirmed) {
        // do not remove - we have to do this to prevent the route from changing
        this.router.off('routeWillChange', this.warnIfUnsavedChangesOnRouteTransition);
        transition.abort();
        this.router.on('routeWillChange', this.warnIfUnsavedChangesOnRouteTransition);
      } else {
        this.router.off('routeWillChange', this.warnIfUnsavedChangesOnRouteTransition);
        transition.retry();
      }
    }
  }

  _rollBackOperatorIdentity() {
    if (this.operatorIdentity) {
      this.operatorIdentity.rollbackAttributes();
      this.finStandaloneService.setOperatorIdentityPreviewImgSrc(
        this.operatorIdentity.avatar?.image_urls?.square_128,
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::FinIdentity': typeof FinIdentity;
    'standalone/common/fin-identity': typeof FinIdentity;
  }
}
