/* import __COLOCATED_TEMPLATE__ from './navbar-adapter.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  isStandaloneRoute,
  expiredSubscriptionRoute,
} from 'embercom/helpers/standalone/standalone-routes';
import type RouterService from '@ember/routing/router-service';

interface Args {}

export default class NavbarAdapter extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  get isBillingOrSettingsRoute() {
    let route = this.router.currentRouteName;
    return route.startsWith('apps.app.billing') || route.startsWith('apps.app.settings');
  }

  get isValidRoute() {
    return (
      isStandaloneRoute(this.router.currentRouteName) ||
      this.router.currentRouteName === expiredSubscriptionRoute
    );
  }

  get isMainAppLoading() {
    return (
      this.router.currentRouteName === 'apps.app.loading' ||
      this.router.currentRouteName === 'inbox_loading'
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Adapters::NavbarAdapter': typeof NavbarAdapter;
  }
}
