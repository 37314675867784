/* import __COLOCATED_TEMPLATE__ from './attribute-descriptor-format.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    displayableDataType: string;
    attributeDescriptor: any;
  };
}

const AttributeDescriptorFormat = templateOnlyComponent<Signature>();
export default AttributeDescriptorFormat;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::AttributeDetails::AttributeDescriptorFormat': typeof AttributeDescriptorFormat;
  }
}
