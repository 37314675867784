/* import __COLOCATED_TEMPLATE__ from './standalone-checkout.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { keepLatestTask } from 'ember-concurrency-decorators';
import { INTENT_TYPES } from 'embercom/components/stripe/stripe-component';
import { post } from 'embercom/lib/ajax';
import { taskFor } from 'ember-concurrency-ts';
import { FIN_FOR_PLATFORMS_BASE_ID } from 'embercom/lib/billing';
import { DEFAULT_REQUEST_PARAMS } from 'embercom/services/quote-service';
import { PricingModelIdentifier } from 'embercom/lib/billing/pricing-models';

import type Router from '@ember/routing/router-service';
import { type TaskGenerator } from 'ember-concurrency';
import type { AfterPaymentMethodSuccess } from 'embercom/components/stripe/stripe-component';
import type QuoteService from 'embercom/services/quote-service';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import moment from 'moment-timezone';

interface Signature {
  Element: any;
}

export default class StandaloneCheckout extends Component<Signature> {
  @service declare appService: any;
  @service declare quoteService: QuoteService;
  @service declare customerService: any;
  @service declare intl: any;
  @service declare notificationsService: any;
  @service declare store: any;
  @service declare router: Router;

  @service declare purchaseAnalyticsService: any;

  @tracked selectedPlanId = FIN_FOR_PLATFORMS_BASE_ID;

  INTENT_TYPES = INTENT_TYPES;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.getQuotes).perform();
  }

  get queryParamsForRedirect() {
    return {
      plan_id: this.selectedPlanId,
      trial_extension: true,
    };
  }

  get redirectUrl() {
    let baseUrl = window.location.origin;
    let url = this.router.urlFor('apps.app.teams-checkout', this.appService.app.id, {
      queryParams: this.queryParamsForRedirect,
    });
    return `${baseUrl}${url}`;
  }

  get loading() {
    return this.quoteService.loading;
  }
  get totalPriceInCents() {
    let totalPrice = this.quote?.baseCostInDollars() || 0;
    return totalPrice * 100;
  }

  get totalPriceInDollars() {
    return this.quote?.baseCostInDollars() || 0;
  }

  get formattedTotalPrice() {
    return this.intl.formatNumber(this.totalPriceInDollars, {
      style: 'currency',
      currency: 'USD',
    });
  }

  get quote() {
    return this.quoteService.getQuoteById([Number(this.selectedPlanId)]);
  }

  get pricePerResolution() {
    return (
      this.quote?.metricPerUnitPrice(
        Number(this.selectedPlanId),
        Metric.resolutions_with_custom_answers,
      ) || 0
    );
  }

  get baseUsage() {
    return (
      this.quote?.metricBaseUsage(
        Number(this.selectedPlanId),
        Metric.resolutions_with_custom_answers,
      ) || 0
    );
  }

  get renewalDate() {
    return moment().add(1, 'month');
  }

  @action
  onBackButtonClick() {
    this.router.transitionTo('apps.app.standalone.deploy.overview');
  }

  @action sendAnalyticsEvent({
    action,
    object,
    context,
  }: {
    action: string;
    object: string;
    context?: Record<string, any>;
  }) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object,
      context,
      place: 'fin-standalone-checkout',
    });
  }

  @keepLatestTask
  *convertSubscription(afterPaymentMethodSuccess: AfterPaymentMethodSuccess): TaskGenerator<void> {
    let { paymentMethod, stripeIntent, address, taxNumber } = afterPaymentMethodSuccess;
    let paramsForAnalytics = {
      plan_ids: [Number(this.selectedPlanId)],
      payment_intent: stripeIntent?.isPaymentIntent,
    };

    let params = {
      ...paramsForAnalytics,
      app_id: this.appService.app.id,
      stripe_payment_method_id: paymentMethod,
      ...(address.streetAddress &&
        address.countryCode && {
          address: {
            country_code: address.countryCode,
            street_address: address.streetAddress,
            ...(address.stateCode && { state_code: address.stateCode }),
            ...(address.postCode && { postcode: address.postCode }),
            ...(address.city && { city: address.city }),
          },
        }),
      ...(stripeIntent?.isPaymentIntent && { stripe_payment_intent_id: stripeIntent.id }),
      ...(taxNumber && { tax_number: taxNumber }),
    };

    try {
      yield post(`/ember/conversions/convert_to_paid_fin_for_platforms`, params);
      this.router.transitionTo('apps.app.checkout.success', this.appService.app.id);
      this.sendAnalyticsEvent({
        action: 'completed',
        object: 'fin_standalone_conversion',
        context: paramsForAnalytics,
      });
    } catch (err) {
      console.error(err);
      if (err && err?.jqXHR?.responseJSON && err?.jqXHR?.responseJSON[0]) {
        this.notificationsService.notifyError(err.jqXHR.responseJSON[0]);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
        );
      }
      throw err;
    }
  }

  @keepLatestTask
  *getQuotes(): TaskGenerator<void> {
    let params = [
      {
        ...DEFAULT_REQUEST_PARAMS,
        planIds: [Number(this.selectedPlanId)],
        pricingModelIdentifier: PricingModelIdentifier.FIN_FOR_PLATFORM,
        source: 'fin-standalone-checkout',
      },
    ];
    yield taskFor(this.quoteService.getQuotes).perform(params);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Checkouts::StandaloneCheckout': typeof StandaloneCheckout;
  }
}
