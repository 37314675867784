/* import __COLOCATED_TEMPLATE__ from './workflow-preview.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';

interface Args {
  workflowId: string | number;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class StandaloneDeployWorkflowPreview extends Component<Signature> {
  @service declare store: Store;

  @tracked ruleset?: $TSFixMe;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadRuleset).perform();
  }

  @task({ drop: true })
  *loadRuleset(): TaskGenerator<void> {
    if (!this.args.workflowId) {
      return;
    }

    let ruleset = yield this.store.peekRecord('matching-system/ruleset', this.args.workflowId);
    if (!ruleset) {
      ruleset = yield this.store.findRecord('matching-system/ruleset', this.args.workflowId);
    }
    this.ruleset = ruleset;
  }

  get isLoading() {
    return taskFor(this.loadRuleset).isRunning;
  }

  get workflow() {
    return this.ruleset?.rulesetLinks.firstObject?.object;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Deploy::WorkflowPreview': typeof StandaloneDeployWorkflowPreview;
    'standalone/deploy/workflow-preview': typeof StandaloneDeployWorkflowPreview;
  }
}
