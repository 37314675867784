/* import __COLOCATED_TEMPLATE__ from './trial-extension-checkout.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { keepLatestTask } from 'ember-concurrency-decorators';
import { INTENT_TYPES } from 'embercom/components/stripe/stripe-component';
import { post } from 'embercom/lib/ajax';
import {
  PRICING_5_X_CORE_ADVANCED_ID,
  EXTENDED_TRIAL_PRICE_IN_DOLLARS,
  PLAN_DATA,
} from 'embercom/lib/billing';

import type Router from '@ember/routing/router-service';
import { type TaskGenerator } from 'ember-concurrency';
import type { AfterPaymentMethodSuccess } from 'embercom/components/stripe/stripe-component';
import type QuoteService from 'embercom/services/quote-service';

interface Args {
  planId: number;
}
interface Signature {
  Element: any;
  Args: Args;
}

export default class TrialExtensionCheckout extends Component<Signature> {
  @service declare appService: any;
  @service declare quoteService: QuoteService;
  @service declare customerService: any;
  @service declare intl: any;
  @service declare notificationsService: any;
  @service declare store: any;
  @service declare router: Router;

  @service declare purchaseAnalyticsService: any;

  @tracked selectedPlanId = this.args.planId || PRICING_5_X_CORE_ADVANCED_ID;

  INTENT_TYPES = INTENT_TYPES;
  EXTENDED_TRIAL_PRICE_IN_DOLLARS = EXTENDED_TRIAL_PRICE_IN_DOLLARS;

  get planName() {
    return PLAN_DATA[this.selectedPlanId].name;
  }

  get queryParamsForRedirect() {
    return {
      plan_id: this.selectedPlanId,
      trial_extension: true,
    };
  }

  get redirectUrl() {
    let baseUrl = window.location.origin;
    let url = this.router.urlFor('apps.app.teams-checkout', this.appService.app.id, {
      queryParams: this.queryParamsForRedirect,
    });
    return `${baseUrl}${url}`;
  }

  get loading() {
    return this.quoteService.loading;
  }

  get totalPriceInCents() {
    return this.EXTENDED_TRIAL_PRICE_IN_DOLLARS * 100;
  }

  get backButtonLabel() {
    return this.intl.t('signup.teams.header.build_your_plan');
  }

  get formattedTrialPrice() {
    return this.intl.formatNumber(this.EXTENDED_TRIAL_PRICE_IN_DOLLARS, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  @action
  onBackButtonClick() {
    let queryParams = { action: 'buy_intercom' };
    this.router.transitionTo(this.appService.app.onboardingHomeRoute, { queryParams });
  }

  @action sendAnalyticsEvent({
    action,
    object,
    context,
  }: {
    action: string;
    object: string;
    context?: Record<string, any>;
  }) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object,
      context,
      place: 'p5-expired-trial-extension-extension-checkout',
    });
  }

  @keepLatestTask
  *convertSubscription(afterPaymentMethodSuccess: AfterPaymentMethodSuccess): TaskGenerator<void> {
    let { paymentMethod, stripeIntent, address, taxNumber } = afterPaymentMethodSuccess;
    let paramsForAnalytics = {
      plan_ids: [Number(this.selectedPlanId)],
      payment_intent: stripeIntent?.isPaymentIntent,
    };

    let params = {
      ...paramsForAnalytics,
      app_id: this.appService.app.id,
      stripe_payment_method_id: paymentMethod,
      ...(address.streetAddress &&
        address.countryCode && {
          address: {
            country_code: address.countryCode,
            street_address: address.streetAddress,
            ...(address.stateCode && { state_code: address.stateCode }),
            ...(address.postCode && { postcode: address.postCode }),
            ...(address.city && { city: address.city }),
          },
        }),
      ...(stripeIntent?.isPaymentIntent && { stripe_payment_intent_id: stripeIntent.id }),
      ...(taxNumber && { tax_number: taxNumber }),
    };

    try {
      yield post(`/ember/conversions/start_trial_extension`, params);
      this.router.transitionTo('apps.app.checkout.success', this.appService.app.id);
      this.sendAnalyticsEvent({
        action: 'completed',
        object: 'one_dollar_trial_subscription',
        context: paramsForAnalytics,
      });
    } catch (err) {
      console.error(err);
      if (err && err?.jqXHR?.responseJSON && err?.jqXHR?.responseJSON[0]) {
        this.notificationsService.notifyError(err.jqXHR.responseJSON[0]);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
        );
      }
      throw err;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Checkouts::TrialExtensionCheckout': typeof TrialExtensionCheckout;
  }
}
