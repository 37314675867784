/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { compareBlocks } from 'embercom/lib/developer-hub/diff-calculator';
import { ProsemirrorComposerWrapper } from 'embercom/lib/inbox/saved-replies/prosemirror-composer-wrapper';
import { task, lastValue } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { DEFAULT_MACRO_TYPES } from 'embercom/lib/inbox/constants';

export const MS_IN_DAY = 1000 * 3600 * 24;

export default class Editor extends Component {
  @service appService;
  @service notificationsService;
  @service intl;

  showDeleteConfirm = false;

  editorInserters = [
    {
      name: 'article',
      icon: 'article',
      descriptor: this.intl.t('inbox.manage-macros.editor.tooltips.add-article'),
      hasPopoverContentComponent: true,
    },
    {
      name: 'emoji',
      icon: 'lwr-happy',
      descriptor: this.intl.t('inbox.manage-macros.editor.tooltips.add-emoji'),
      hasPopoverContentComponent: true,
    },
    {
      name: 'gif',
      icon: 'gif',
      descriptor: this.intl.t('inbox.manage-macros.editor.tooltips.add-gif'),
      hasPopoverContentComponent: true,
    },
    {
      name: 'image',
      icon: 'picture',
      descriptor: this.intl.t('inbox.manage-macros.editor.tooltips.add-image'),
      hasPopoverContentComponent: false,
    },
    {
      name: 'attachment',
      icon: 'attachment',
      descriptor: this.intl.t('inbox.manage-macros.editor.tooltips.add-attachment'),
      hasPopoverContentComponent: false,
    },
  ];

  @tracked openInserters = [];
  @tracked updatedReplyName = this.args.selectedReply.name;
  @tracked prosemirrorComposerWrapper;
  @tracked savedReplyComposer;
  @tracked updatedVisibleToTeamIds = this.args.selectedReply.visibleToTeamIds;
  @tracked updatedVisibility = this.args.selectedReply.visibility;
  @tracked selectedTypes = this.initialTypes;

  @lastValue('getUsageForSelectedReply') replyUsage = null;

  get replyAuthor() {
    return this.args.selectedReply.admin;
  }

  get selectedBlocks() {
    return this.args.selectedReply.blocks;
  }

  get initialTypes() {
    return this.getPreselectedTypes();
  }

  get selectedTypeIds() {
    return this.selectedTypes.mapBy('id');
  }

  get publicAPI() {
    return {
      app: this.app,
      actions: {
        registerOpenInserter: (...args) => this.registerOpenInserter(...args),
        clearOpenInserter: (...args) => this.clearOpenInserter(...args),
        paste: (...args) => this.paste(...args),
        uploadImage: (...args) => this.uploadImage(...args),
        uploadAttachment: (...args) => this.uploadAttachment(...args),
        insertBlock: (...args) => this.insertBlock(...args),
      },
    };
  }

  get isDirty() {
    // hackish way of comparing if 2 arrays are equal
    let teamVisibilityChanged =
      JSON.stringify(this.updatedVisibleToTeamIds) !==
      JSON.stringify(this.args.selectedReply.visibleToTeamIds);

    let typesChanged =
      JSON.stringify(this.selectedTypes.mapBy('id')) !==
      JSON.stringify(this.args.selectedReply.types);

    return (
      this.updatedReplyName !== this.args.selectedReply.name ||
      compareBlocks(this.savedReplyComposer?.getBlocks(), this.selectedBlocks).hasDifference ||
      this.args.selectedReply.actions.hasDirtyAttributes ||
      teamVisibilityChanged ||
      this.updatedVisibility !== this.args.selectedReply.visibility ||
      typesChanged
    );
  }

  get saveButtonDisabled() {
    return this.isUploading || !this.isDirty;
  }

  get duplicateButtonDisabled() {
    return this.isUploading || this.isDirty;
  }

  get app() {
    return this.appService.app;
  }

  get showCancelButton() {
    return this.args.selectedReply.isNew;
  }

  get selectedReplyHasUpdates() {
    return this.args.selectedReply.hasUpdates;
  }

  get selectedReplyLastUpdatedAt() {
    let timestamp = this.selectedReplyHasUpdates
      ? this.args.selectedReply.updated_at
      : this.args.selectedReply.created_at;

    return this.intl.formatRelative(timestamp);
  }

  get shouldDisplayMacroUsageReporting() {
    return this.replyUsage;
  }

  @task
  *getUsageForSelectedReply() {
    try {
      return this.selectUsageToDisplay(yield this.getStatsForSelectedReply());
    } catch (err) {
      console.error(JSON.stringify(err));
      this.notificationsService.notifyError('Failed to retrieve statistics for macro.');
    }
    return null;
  }

  @action
  setupProsemirrorComposerWrapper() {
    this.prosemirrorComposerWrapper = new ProsemirrorComposerWrapper(this, true);
    this.savedReplyComposer = this.prosemirrorComposerWrapper;
  }

  @action
  setSavedReplyComposerContents() {
    this.savedReplyComposer.send('load', this.args.selectedReply.blocks);
  }

  @action
  setReplyName() {
    this.updatedReplyName = this.args.selectedReply.name;
  }

  @action
  setReplyVisibility() {
    this.updatedVisibility = this.args.selectedReply.visibility;
    this.updatedVisibleToTeamIds = this.args.selectedReply.visibleToTeamIds;
  }

  @action
  replyNameChanged(event) {
    this.updatedReplyName = event.target.value;
    this.notifyDirtyState();
  }

  @action
  contentsChanged() {
    this.notifyDirtyState();
  }

  @action
  visibilityChanged(newVisibleToTeamIds, newVisibility) {
    this.updatedVisibleToTeamIds = newVisibleToTeamIds;
    this.updatedVisibility = newVisibility;
    this.notifyDirtyState();
  }

  @action
  setReplyAvailability() {
    this.selectedTypes = this.getPreselectedTypes();
  }

  @action
  onTypesChange(types) {
    this.selectedTypes = DEFAULT_MACRO_TYPES.filter(({ id }) => types.includes(id));
    this.notifyDirtyState();
  }

  @action
  onComposerReady(composerAPI) {
    if (this.args.isDisabled) {
      composerAPI.composer.disableInteractivity();
    }
    this.prosemirrorComposerWrapper.onReady(composerAPI);
  }

  notifyDirtyState() {
    if (this.args.updateDirtyState) {
      this.args.updateDirtyState(this.isDirty);
    }
  }

  registerOpenInserter(inserterName) {
    this.openInserters.pushObject(inserterName);
  }

  clearOpenInserter(inserterName) {
    if (!this.isDestroying) {
      this.openInserters.removeObject(inserterName);
    }
  }

  paste(text) {
    this.savedReplyComposer.send('paste', text, 'plain');
  }

  uploadImage(files) {
    this.savedReplyComposer.send('uploadImage', files);
  }

  uploadAttachment(files) {
    this.savedReplyComposer.send('uploadAttachment', files);
  }

  insertBlock(type, block) {
    this.savedReplyComposer.send('createBlock', type, block);
  }

  getStatsForSelectedReply() {
    return ajax({
      url: `/ember/saved_replies/${this.args.selectedReply.id}/stats`,
      type: 'GET',
      contentType: 'application/json',
      dataType: 'JSON',
      data: {
        app_id: this.app.id,
      },
    });
  }

  selectUsageToDisplay({ count_30_days, count_7_days, first_used_at }) {
    if (!first_used_at) {
      return { count: 0, days: 0, hasBeenUsed: false };
    }

    let numberOfDaysSinceFirstUsed = this.getNumberOfDaysSince(new Date(first_used_at));

    let timePeriod;
    if (numberOfDaysSinceFirstUsed < 7) {
      timePeriod = 0;
    } else if (numberOfDaysSinceFirstUsed < 30) {
      timePeriod = 7;
    } else {
      timePeriod = 30;
    }

    let count = numberOfDaysSinceFirstUsed < 30 ? count_7_days : count_30_days;

    return {
      count,
      days: timePeriod,
      hasBeenUsed: true,
    };
  }

  getNumberOfDaysSince(sinceDate) {
    return Math.ceil((new Date().getTime() - sinceDate.getTime()) / MS_IN_DAY);
  }

  getPreselectedTypes() {
    let preselectedTypeOptions = [];
    let currentTypes = this.args.selectedReply.types;

    if (currentTypes.length === 0) {
      preselectedTypeOptions = DEFAULT_MACRO_TYPES;
    } else {
      preselectedTypeOptions = currentTypes.map((typeId) =>
        DEFAULT_MACRO_TYPES.findBy('id', typeId),
      );
    }

    return preselectedTypeOptions;
  }
}
