/* import __COLOCATED_TEMPLATE__ from './addon-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';

interface FeatureText {
  text: string;
}

interface Args {
  loading: boolean;
  addonIsSelected: boolean;
  unitPrice?: number;
  preventCheckboxDefault?: boolean;
  selectedUnitsQuantity?: number;
  toggleAddonSelectedState: () => void;
  upgradeFeatures: Array<FeatureText>;
  upgradeLabel: string;
  upgradeDescription: string;
  upgradeTooltipText: string;
  upgradeSubcopy: string;
  numberOfFeatureColumns: number;
  showPricingArticle?: () => void;
  showEditIcon?: boolean;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class AddonCard extends Component<Signature> {
  @service declare intl: IntlService;

  get icon(): InterfaceIconName {
    if (this.args.addonIsSelected) {
      return this.args.showEditIcon ? 'edit' : 'lead';
    } else {
      return 'new';
    }
  }

  @action
  noop(e: Event) {
    e.preventDefault();
  }

  get actionLabel(): string {
    return this.intl.t(this.actionLabelIntlString);
  }

  get actionLabelIntlString(): string {
    if (this.args.addonIsSelected) {
      return this.args.showEditIcon
        ? 'signup.teams.pricing5.annual-plans.upgrades.add-on-card.edit'
        : 'signup.teams.pricing5.annual-plans.upgrades.add-on-card.remove';
    } else {
      return 'signup.teams.pricing5.annual-plans.upgrades.add-on-card.add';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::AddonCard': typeof AddonCard;
  }
}
