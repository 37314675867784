/* import __COLOCATED_TEMPLATE__ from './go-live.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import type { Step } from 'embercom/components/standalone/common/stepper-item';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

export interface Args {}

type App = {
  hasAnyInstalledAtDate: boolean;
  hasStandalonePlatform(platform: string): boolean;
};

export default class GoLive extends Component<Args> {
  @service declare appService: { app: App };
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get app() {
    return this.appService.app;
  }

  get canGoLive() {
    if (
      (this.app.hasStandalonePlatform('zendesk') &&
        !this.finStandaloneService.zendeskConfig?.isConnected) ||
      (this.app.hasStandalonePlatform('salesforce') &&
        !this.finStandaloneService.salesforceConfig?.isConnected)
    ) {
      // We want to allow Sales reps and customers to go live without connecting Zendesk/Salesforce so that they aren't
      // blocked demoing or testing Fin in the Intercom Messenger while waiting on API keys.
      return this.intercomMessengerConfig.isWorkflowValid && this.app.hasAnyInstalledAtDate;
    } else {
      return !this.intercomMessengerConfig.isMessengerNotConfigured;
    }
  }

  get config() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get intercomMessengerConfig() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get workflowId() {
    return this.intercomMessengerConfig?.workflowRulesetId;
  }

  get isFinEnabled() {
    return this.intercomMessengerConfig?.isMessengerEnabled;
  }

  get steps(): Array<Step> {
    if (this.app.hasStandalonePlatform('zendesk')) {
      return this.zendeskSteps.concat(this.sharedSteps);
    }

    return this.sharedSteps;
  }

  get sharedSteps(): Array<Step> {
    return [
      {
        id: 'training',
        title: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.training.title'),
        button: {
          icon: 'knowledge',
          text: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.training.button'),
          href: this.router.urlFor('apps.app.knowledge-hub.overview'),
        },
      },
      {
        id: 'workflow',
        title: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.workflow.title'),
        button: {
          icon: 'workflows',
          text: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.workflow.button'),
          href: this.router.urlFor(
            'apps.app.standalone.deploy.intercom-messenger.workflow.show',
            this.workflowId,
            {
              queryParams: { returnPath: this.router.currentRouteName },
            },
          ),
        },
      },
      {
        id: 'preferences',
        title: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.preferences.title'),
        button: {
          icon: 'edit',
          text: this.intl.t(
            'standalone.intercom-messenger.deploy.go-live.steps.preferences.button',
          ),
          href: this.router.urlFor('apps.app.standalone.deploy.intercom-messenger.integrate', {
            queryParams: {
              section: 'fin-identity',
            },
          }),
        },
      },
      {
        id: 'messenger',
        title: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.messenger.title'),
        button: {
          icon: 'messenger',
          text: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.messenger.button'),
          href: this.router.urlFor('apps.app.settings.channels.messenger.general'),
        },
      },
    ];
  }

  get titleForFinIsLive() {
    if (!this.config.isIntegrated) {
      if (this.app.hasStandalonePlatform('zendesk')) {
        return this.intl.t(
          'standalone.intercom-messenger.activation.fin-live-but-not-connected-zendesk',
        );
      } else if (this.app.hasStandalonePlatform('salesforce')) {
        return this.intl.t(
          'standalone.intercom-messenger.activation.fin-live-but-not-connected-salesforce',
        );
      }
    }

    return this.intl.t('standalone.intercom-messenger.activation.fin-live');
  }

  get zendeskSteps(): Array<Step> {
    if (this.finStandaloneService.zendeskConfig?.sunshineIntegration.isSunshineConnected) {
      return [
        {
          id: 'zendesk',
          title: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.zendesk.title'),
          description: this.intl.t(
            'standalone.intercom-messenger.deploy.go-live.steps.zendesk.description',
          ),
        },
      ];
    } else {
      return [];
    }
  }

  @action
  async activate() {
    if (this.confirmOptions) {
      let isConfirmed: boolean = await this.intercomConfirmService.confirm(this.confirmOptions);
      if (!isConfirmed) {
        return false;
      }
    }

    await this.finStandaloneService.intercomMessengerConfig.activate();
    return true;
  }

  get confirmOptions() {
    // If the messenger is fully configured, we don't need to show a warning.
    if (!this.intercomMessengerConfig.isMessengerNotConfigured) {
      return undefined;
    }

    let body: string;
    if (this.app.hasStandalonePlatform('zendesk')) {
      body = this.intl.t(
        'standalone.intercom-messenger.deploy.go-live.warning.no-zendesk-hand-off',
      );
    } else if (this.app.hasStandalonePlatform('salesforce')) {
      body = this.intl.t(
        'standalone.intercom-messenger.deploy.go-live.warning.no-salesforce-hand-off',
      );
    } else {
      return undefined;
    }

    return {
      title: this.intl.t('standalone.intercom-messenger.deploy.go-live.warning.title'),
      body,
      confirmButtonText: this.intl.t(
        'standalone.intercom-messenger.deploy.go-live.warning.go-live',
      ),
      primaryButtonType: 'primary-live',
    };
  }

  @action
  async deactivate() {
    await this.finStandaloneService.intercomMessengerConfig.deactivate();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::GoLive': typeof GoLive;
    'standalone/intercom-messenger/deploy/go-live': typeof GoLive;
  }
}
