/* import __COLOCATED_TEMPLATE__ from './deploy.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Tab } from 'embercom/components/standalone/common/tab-control';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';

type DeployStep = Required<Tab> & {
  value: 'integrate' | 'workflow' | 'install' | 'test' | 'go-live';
};

export interface Args {
  activeStep: DeployStep['value'];
  section: string | null;
  setSection?: (section: string | null) => void;
}

export default class Deploy extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @tracked selectedStepValue = this.args.activeStep;

  get selectedStep() {
    return (
      this.deploySteps.find((step) => step.value === this.selectedStepValue) || this.deploySteps[0]
    );
  }

  get app() {
    return this.finStandaloneService.app;
  }

  get config() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get isWorkflowStepComplete() {
    return (
      this.config.isWorkflowValid &&
      (this.selectedStepValue === 'install' || this.app.hasAnyInstalledAtDate)
    );
  }

  get deploySteps(): DeployStep[] {
    return [
      {
        stepNumber: 1,
        label: this.intl.t('standalone.intercom-messenger.deploy.tabs.integrate'),
        value: 'integrate',
        path: 'apps.app.standalone.deploy.intercom-messenger.integrate',
        isCompleted: this.config.isIntegrated,
      },
      {
        stepNumber: 2,
        label: this.intl.t('standalone.intercom-messenger.deploy.tabs.workflow'),
        value: 'workflow',
        path: 'apps.app.standalone.deploy.intercom-messenger.workflow',
        isCompleted: this.isWorkflowStepComplete,
      },
      {
        stepNumber: 3,
        label: this.intl.t('standalone.intercom-messenger.deploy.tabs.install'),
        value: 'install',
        path: 'apps.app.standalone.deploy.intercom-messenger.install',
        isCompleted: this.app.hasAnyInstalledAtDate,
      },
      {
        stepNumber: 4,
        label: this.intl.t('standalone.intercom-messenger.deploy.tabs.test'),
        value: 'test',
        path: 'apps.app.standalone.deploy.intercom-messenger.test',
        isCompleted: this.config.isMessengerInTestMode || this.config.isMessengerEnabled,
      },
      {
        stepNumber: 5,
        label: this.intl.t('standalone.intercom-messenger.deploy.tabs.go-live'),
        value: 'go-live',
        path: 'apps.app.standalone.deploy.intercom-messenger.go-live',
        isCompleted: this.config.isMessengerEnabled,
      },
    ];
  }

  @action
  setStep(stepValue: DeployStep['value']) {
    this.selectedStepValue = stepValue;
    if (this.selectedStep) {
      this.router.transitionTo(this.selectedStep.path);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy': typeof Deploy;
    'standalone/intercom-messenger/deploy': typeof Deploy;
  }
}
