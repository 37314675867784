/* import __COLOCATED_TEMPLATE__ from './copilot-usage-tooltip-content.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { COPILOT_FREE_CREDIT_LIMIT } from 'embercom/services/copilot-usage-service';

interface Signature {
  Args: {};
  Element: HTMLDivElement;
}

export default class CopilotUsageTooltipContent extends Component<Signature> {
  @service declare appService: $TSFixMe;

  get includedLimit() {
    return COPILOT_FREE_CREDIT_LIMIT;
  }

  get unlimitedUsageCount() {
    return this.appService.app.adminsWithUnlimitedCopilotAccess.length;
  }

  get includedUsageCount() {
    return this.appService.app.adminsWithLimitedCopilotAccess.length;
  }

  get copilotIsEnabled() {
    return this.appService.app.hasCopilotEnabled;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Copilot::CopilotUsageTooltipContent': typeof CopilotUsageTooltipContent;
    'settings/copilot/copilot-usage-tooltip-content': typeof CopilotUsageTooltipContent;
  }
}
