/* import __COLOCATED_TEMPLATE__ from './standalone-row.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    row: any;
  };
}

const StandaloneRow = templateOnlyComponent<Signature>();
export default StandaloneRow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::ConversationAttributes::StandaloneRow': typeof StandaloneRow;
  }
}
