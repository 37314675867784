/* import __COLOCATED_TEMPLATE__ from './ai-assist-options.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import ToolbarAiAssist from 'embercom/components/inbox2/composer/toolbar-ai-assist';

// eslint-disable-next-line @intercom/intercom/no-component-inheritance
export default class AiAssistOptions extends ToolbarAiAssist {
  // We don't currently support paywalls within the Copilot Frame so lets
  // remove the paywalled options from the list.
  get options() {
    return super.options.filter((option) => !option.paywalled);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Copilot::AiAssistOptions': typeof AiAssistOptions;
    'standalone/copilot/ai-assist-options': typeof AiAssistOptions;
  }
}
