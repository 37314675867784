/* import __COLOCATED_TEMPLATE__ from './fin-messenger-identity.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type PulseAccordion } from 'glint/pulse';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type RouterService from '@ember/routing/router-service';

interface Args {
  accordion: PulseAccordion;
  closeAccordion: () => void;
}

export default class StandaloneZendeskFinMessengerIdentity extends Component<Args> {
  @service declare router: RouterService;
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  get isIncomplete() {
    return (
      this.zendeskConfig.isPendingSetupForTickets ||
      !this.zendeskConfig.sunshineIntegration.isSunshineConnected ||
      !this.finStandaloneService.operatorIdentity
    );
  }

  get intercomMessengerUrl() {
    return this.router.urlFor('apps.app.standalone.deploy.intercom-messenger.integrate');
  }

  @action async saveOperatorIdentity() {
    await this.finStandaloneService.updateOperatorIdentity();
    this.args.closeAccordion();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine::FinMessengerIdentity': typeof StandaloneZendeskFinMessengerIdentity;
    'standalone/zendesk/deploy/sunshine/fin-messenger-identity': typeof StandaloneZendeskFinMessengerIdentity;
  }
}
