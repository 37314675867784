/* import __COLOCATED_TEMPLATE__ from './list-options-csv-import.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { Promise as EmberPromise } from 'rsvp';
import csvParser from 'papaparse';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface ListOptionsCsvImportArgs {
  onOptionsImported: (options: Array<{ label: string; description: string }>) => void;
  optionsLimit: number;
  isOpen: boolean;
  onClose: () => void;
  existingOptions?: Array<{ label: string; description: string }>;
  isTicketDescriptor: boolean;
}

export default class ListOptionsCsvImport extends Component<ListOptionsCsvImportArgs> {
  @service declare intl: any;
  @service declare notificationsService: any;
  @service declare appService: $TSFixMe;

  @tracked selectedFile: File | null = null;
  @tracked parsingErrorMessage: string | null = null;
  @tracked fileIsValid = true;
  @tracked declare openSectionId: string | null;

  get showExampleCSV() {
    return this.appService.app.canUseAiConversationClassification && !this.args.isTicketDescriptor;
  }

  get exampleColumns() {
    return [
      {
        label: this.intl.t('settings.conversation-attributes.import-modal.example.table.name'),
        valuePath: 'name',
        minWidth: '200px',
      },
      {
        label: this.intl.t(
          'settings.conversation-attributes.import-modal.example.table.description',
        ),
        valuePath: 'description',
        minWidth: '300px',
      },
    ];
  }

  get exampleData() {
    return [
      {
        name: this.intl.t(
          'settings.conversation-attributes.import-modal.example.sample_data.feature_request.name',
        ),
        description: this.intl.t(
          'settings.conversation-attributes.import-modal.example.sample_data.feature_request.description',
        ),
      },
      {
        name: this.intl.t(
          'settings.conversation-attributes.import-modal.example.sample_data.product_bug.name',
        ),
        description: this.intl.t(
          'settings.conversation-attributes.import-modal.example.sample_data.product_bug.description',
        ),
      },
    ];
  }

  get isUploadDisabled() {
    return !this.fileIsValid || !this.selectedFile;
  }

  @action
  onOpenSectionChange(sectionId: string): void {
    this.openSectionId = sectionId;
  }

  @action
  setFile(file: File) {
    this.selectedFile = file;
    this.fileIsValid = true;
    this.parsingErrorMessage = null;
  }

  @action
  async handleCsvUpload() {
    if (!this.selectedFile) {
      this.notificationsService.notifyError(
        this.intl.t('settings.conversation-attributes.import-modal.no-file-error'),
      );
      return;
    }

    try {
      let results = (await this._parseCsvFile(this.selectedFile)) as any;
      let validOptions = this.validateCsvData(results.data);

      if (validOptions.length === 0) {
        throw new Error(this.intl.t('settings.conversation-attributes.import-modal.no-valid-rows'));
      }

      this.args.onOptionsImported(validOptions);
      this.args.onClose();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.conversation-attributes.import-modal.success', {
          count: validOptions.length,
        }),
      );
    } catch (error) {
      this.parsingErrorMessage =
        error.message || this.intl.t('settings.conversation-attributes.import-modal.error');
      this.fileIsValid = false;
    }
  }

  async _parseCsvFile(file: File) {
    return new EmberPromise((resolve, reject) => {
      csvParser.parse(file, {
        complete: (results) => {
          if (
            results.errors.length &&
            results.errors.some((error) => error.code !== 'UndetectableDelimiter')
          ) {
            reject(results.errors[0]);
            return;
          }
          resolve(results);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  validateCsvData(data: Array<Array<string>>) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      throw new Error(this.intl.t('settings.conversation-attributes.import-modal.no-data-error'));
    }

    if (data.some((row) => !Array.isArray(row))) {
      throw new Error(
        this.intl.t('settings.conversation-attributes.import-modal.formatting-error'),
      );
    }

    let dataWithoutHeader = data.slice(1);

    if (dataWithoutHeader.length === 0) {
      throw new Error(this.intl.t('settings.conversation-attributes.import-modal.no-valid-rows'));
    }

    this.validateOptionsLimit(dataWithoutHeader);
    let importedLabels = this.validateDuplicates(dataWithoutHeader);

    return Array.from(importedLabels.values()).map((label, index) => ({
      label,
      description: dataWithoutHeader[index][1]?.trim() || '',
    }));
  }

  private validateOptionsLimit(data: Array<Array<string>>) {
    if (data.length > this.args.optionsLimit) {
      throw new Error(
        this.intl.t('settings.conversation-attributes.import-modal.csv-limit-error', {
          limit: this.args.optionsLimit,
        }),
      );
    }

    let existingOptionsLength =
      this.args.existingOptions?.filter((opt) => opt.label.trim())?.length || 0;
    let newOptionsLength = data.filter((row) => row[0]?.trim()).length;
    let totalOptions = existingOptionsLength + newOptionsLength;

    if (totalOptions > this.args.optionsLimit) {
      throw new Error(
        this.intl.t('settings.conversation-attributes.import-modal.combined-limit-error', {
          limit: this.args.optionsLimit,
        }),
      );
    }
  }

  private validateDuplicates(data: Array<Array<string>>) {
    let importedLabels = new Map<string, string>();
    let existingLabels = new Set(
      this.args.existingOptions?.filter((opt) => opt.label.trim()).map((opt) => opt.label) || [],
    );
    let duplicatesInCsv = new Set<string>();
    let duplicatesWithExisting = new Set<string>();

    data.forEach((row) => {
      if (!row[0]?.trim()) {
        return;
      }

      let label = row[0].trim();

      if (importedLabels.has(label)) {
        duplicatesInCsv.add(label);
      } else if (existingLabels.has(label)) {
        duplicatesWithExisting.add(label);
      } else {
        importedLabels.set(label, label);
      }
    });

    if (duplicatesInCsv.size > 0) {
      let duplicatesArray = Array.from(duplicatesInCsv);
      throw new Error(
        this.intl.t('settings.conversation-attributes.import-modal.duplicate-in-csv-error', {
          firstFourDuplicates: duplicatesArray.slice(0, 4).join(', '),
          remainingCount: duplicatesArray.length > 4 ? duplicatesArray.length - 4 : 0,
        }),
      );
    }

    if (duplicatesWithExisting.size > 0) {
      let duplicatesArray = Array.from(duplicatesWithExisting);
      throw new Error(
        this.intl.t('settings.conversation-attributes.import-modal.duplicate-with-existing-error', {
          firstFourDuplicates: duplicatesArray.slice(0, 4).join(', '),
          remainingCount: duplicatesArray.length > 4 ? duplicatesArray.length - 4 : 0,
        }),
      );
    }

    return importedLabels;
  }

  @action
  openHelpArticle() {
    let articleUrl = this.appService.app.isStandaloneApp
      ? 'https://fin.ai/help/en/articles/10751980-using-conversation-data-in-the-inbox'
      : 'https://www.intercom.com/help/en/articles/6546210';

    safeWindowOpen(articleUrl, '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::ConversationAttributes::ListOptionsCsvImport': typeof ListOptionsCsvImport;
    'settings/conversation-attributes/list-options-csv-import': typeof ListOptionsCsvImport;
  }
}
