/* import __COLOCATED_TEMPLATE__ from './channel-icon.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    channelIcon: InterfaceIconName;
  };
  Element: HTMLDivElement;
}

const StandaloneCommonChannelIcon = templateOnlyComponent<Signature>();
export default StandaloneCommonChannelIcon;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::ChannelIcon': typeof StandaloneCommonChannelIcon;
  }
}
