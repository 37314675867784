/* import __COLOCATED_TEMPLATE__ from './conditions-csv-import.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type { ParseResult } from 'papaparse';
import csvParser from 'papaparse';
import { Promise as EmberPromise } from 'rsvp';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type ListOption from 'embercom/models/conversation-attributes/list-option';
import type Condition from 'embercom/models/conversation-attributes/condition';

interface CsvRow {
  controllingListOptionName: string;
  dependentAttributeName: string;
  dependentListOptionName: string;
}

interface ParsedCondition {
  controllingListOptionName: string;
  dependentAttributeName: string;
  dependentListOptionNames: string[];
}

interface CSVCondition {
  controllingListOptionId: string;
  descriptorId: string;
  descriptorListOptionIds: string[];
}

interface ConditionsCsvImportArgs {
  isOpen: boolean;
  onClose: () => void;
  onConditionsImported: (conditions: CSVCondition[]) => void;
  targetAttribute: ConversationAttributeDescriptor;
  allAttributes: ConversationAttributeDescriptor[];
}

export default class ConditionsCsvImport extends Component<ConditionsCsvImportArgs> {
  @service declare intl: IntlService;

  @tracked file: File | null = null;
  @tracked fileIsValid = true;
  @tracked parsingErrorMessage: string | null = null;

  MAX_CONDITIONS = 100;

  get exampleColumns() {
    return [
      {
        label: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.table.if_option',
        ),
        valuePath: 'if_option',
        minWidth: '150px',
      },
      {
        label: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.table.then_show',
        ),
        valuePath: 'then_show',
        minWidth: '150px',
      },
      {
        label: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.table.with_options',
        ),
        valuePath: 'with_options',
        minWidth: '200px',
      },
    ];
  }

  get exampleData() {
    return [
      {
        if_option: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.sample_data.emea.name',
        ),
        then_show: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.sample_data.country.name',
        ),
        with_options: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.sample_data.france.name',
        ),
      },
      {
        if_option: '',
        then_show: '',
        with_options: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.sample_data.greece.name',
        ),
      },
      {
        if_option: '',
        then_show: '',
        with_options: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.sample_data.italy.name',
        ),
      },
      {
        if_option: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.sample_data.apac.name',
        ),
        then_show: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.sample_data.country.name',
        ),
        with_options: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.sample_data.japan.name',
        ),
      },
      {
        if_option: '',
        then_show: '',
        with_options: this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.example.sample_data.australia.name',
        ),
      },
    ];
  }

  get isUploadDisabled() {
    return !this.fileIsValid || !this.file;
  }

  @action
  setFile(file: File): void {
    this.file = file;
    this.fileIsValid = true;
    this.parsingErrorMessage = null;
  }

  @action
  async handleCsvUpload(): Promise<void> {
    if (!this.file) {
      this.parsingErrorMessage = this.intl.t(
        'settings.conversation-attributes.conditions-import-modal.no-file-error',
      );
      return;
    }

    try {
      let results = await this._parseCsvFile(this.file);
      let validConditions = this.validateCsvData(results.data);

      if (validConditions.length === 0) {
        throw new Error(
          this.intl.t('settings.conversation-attributes.conditions-import-modal.no-valid-rows'),
        );
      }

      this.args.onConditionsImported(validConditions);
      this.args.onClose();
    } catch (error) {
      this.fileIsValid = false;
      this.parsingErrorMessage =
        error.message ||
        this.intl.t('settings.conversation-attributes.conditions-import-modal.processing-error');
    }
  }

  private validateCsvData(rows: string[][]): CSVCondition[] {
    if (!rows || !Array.isArray(rows) || rows.length === 0) {
      throw new Error(
        this.intl.t('settings.conversation-attributes.conditions-import-modal.no-data-error'),
      );
    }

    // Skip header row
    let dataRows = rows.slice(1);

    // Parse rows into structured format
    let parsedRows: CsvRow[] = dataRows.map((row) => ({
      controllingListOptionName: (row[0] || '').trim(),
      dependentAttributeName: (row[1] || '').trim(),
      dependentListOptionName: (row[2] || '').trim(),
    }));

    // Group rows into conditions and validate existence
    let groupedConditions: ParsedCondition[] = [];
    let currentCondition: ParsedCondition | null = null;

    for (let row of parsedRows) {
      // Start new condition if we have controlling option and dependent attribute
      if (row.controllingListOptionName && row.dependentAttributeName) {
        // Validate controlling option exists
        let controllingListOption = this.args.targetAttribute.listOptions.find(
          (option: ListOption) =>
            option.label.trim() === row.controllingListOptionName && !option.archived,
        );
        if (!controllingListOption) {
          throw new Error(
            this.intl.t(
              'settings.conversation-attributes.conditions-import-modal.option-not-found',
              {
                option: row.controllingListOptionName,
              },
            ),
          );
        }

        // Validate dependent attribute exists and is not the target attribute
        let dependentAttribute = this.args.allAttributes.find(
          (attr) =>
            attr.name.trim() === row.dependentAttributeName &&
            !attr.archived &&
            attr.id !== this.args.targetAttribute.id,
        );
        if (!dependentAttribute) {
          if (row.dependentAttributeName.trim() === this.args.targetAttribute.name.trim()) {
            throw new Error(
              this.intl.t(
                'settings.conversation-attributes.conditions-import-modal.self-dependency-error',
                {
                  attribute: row.dependentAttributeName,
                },
              ),
            );
          }

          throw new Error(
            this.intl.t(
              'settings.conversation-attributes.conditions-import-modal.attribute-not-found',
              {
                attribute: row.dependentAttributeName,
              },
            ),
          );
        }

        if (currentCondition) {
          groupedConditions.push(currentCondition);
        }
        currentCondition = {
          controllingListOptionName: row.controllingListOptionName,
          dependentAttributeName: row.dependentAttributeName,
          dependentListOptionNames: row.dependentListOptionName
            ? [row.dependentListOptionName]
            : [],
        };
      }
      // Continue current condition if we only have an option
      else if (currentCondition && row.dependentListOptionName) {
        currentCondition.dependentListOptionNames.push(row.dependentListOptionName);
      }
      // Error if we have partial data
      else if (row.controllingListOptionName || row.dependentAttributeName) {
        throw new Error(
          this.intl.t('settings.conversation-attributes.conditions-import-modal.invalid-format'),
        );
      }
    }

    // Add last condition if exists
    if (currentCondition) {
      groupedConditions.push(currentCondition);
    }

    // Validate condition limits
    this.validateConditionLimits(groupedConditions);

    // Validate duplicates before converting to final format
    this.validateDuplicates(groupedConditions);

    // Convert parsed conditions to final format
    return groupedConditions.map((condition) => {
      let dependentAttribute = this.args.allAttributes.find(
        (attr) => attr.name.trim() === condition.dependentAttributeName && !attr.archived,
      );
      let controllingListOption = this.args.targetAttribute.listOptions.find(
        (option: ListOption) =>
          option.label.trim() === condition.controllingListOptionName && !option.archived,
      );

      // Find dependent list option IDs if applicable
      let descriptorListOptionIds: string[] = [];
      if (
        dependentAttribute?.dataType === 'list' &&
        condition.dependentListOptionNames.length > 0
      ) {
        let invalidOptions = condition.dependentListOptionNames.filter(
          (optionName) =>
            !dependentAttribute?.listOptions.find(
              (option: ListOption) => option.label.trim() === optionName.trim() && !option.archived,
            ),
        );

        if (invalidOptions.length > 0) {
          throw new Error(
            this.intl.t(
              'settings.conversation-attributes.conditions-import-modal.invalid-list-options',
              {
                options: invalidOptions.join(', '),
                attribute: condition.dependentAttributeName,
                count: invalidOptions.length,
              },
            ),
          );
        }

        descriptorListOptionIds = dependentAttribute.listOptions
          .filter(
            (option: ListOption) =>
              condition.dependentListOptionNames.includes(option.label.trim()) && !option.archived,
          )
          .map((option: ListOption) => option.listOptionId);
      }

      return {
        controllingListOptionId: controllingListOption.listOptionId,
        descriptorId: dependentAttribute!.id,
        descriptorListOptionIds,
      };
    });
  }

  private validateConditionLimits(conditions: ParsedCondition[]): void {
    if (conditions.length > this.MAX_CONDITIONS) {
      throw new Error(
        this.intl.t('settings.conversation-attributes.conditions-import-modal.csv-limit-error', {
          limit: this.MAX_CONDITIONS,
        }),
      );
    }

    let existingConditionsCount = this.args.targetAttribute.dependentConditions
      .rejectBy('isDeleted')
      .filter((condition: Condition) => !condition.descriptor?.archived).length as number;

    if (existingConditionsCount + conditions.length > this.MAX_CONDITIONS) {
      throw new Error(
        this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.combined-limit-error',
          {
            limit: this.MAX_CONDITIONS,
          },
        ),
      );
    }
  }

  private validateDuplicates(conditions: ParsedCondition[]): void {
    // Check for duplicates within the CSV
    let duplicatesInCsv = new Set<string>();
    let seenInCsv = new Set<string>();

    conditions.forEach((condition) => {
      let key = `${condition.controllingListOptionName}:${condition.dependentAttributeName}`;
      if (seenInCsv.has(key)) {
        duplicatesInCsv.add(key);
      } else {
        seenInCsv.add(key);
      }
    });

    // Check for duplicates with existing conditions
    let duplicatesWithExisting = new Set<string>();
    let existingConditions = this.args.targetAttribute.dependentConditions
      .rejectBy('isDeleted')
      .filter((condition: Condition) => !condition.descriptor?.archived);

    conditions.forEach((newCondition) => {
      let controllingListOption = this.args.targetAttribute.listOptions.find(
        (option: ListOption) =>
          option.label.trim() === newCondition.controllingListOptionName && !option.archived,
      );
      let dependentAttribute = this.args.allAttributes.find(
        (attr) => attr.name.trim() === newCondition.dependentAttributeName && !attr.archived,
      );

      if (controllingListOption && dependentAttribute) {
        let exists = existingConditions.some(
          (existing: Condition) =>
            existing.controllingListOptionId === controllingListOption.listOptionId &&
            existing.descriptorId === (dependentAttribute?.id as unknown as number),
        );

        if (exists) {
          duplicatesWithExisting.add(
            `${newCondition.controllingListOptionName}:${newCondition.dependentAttributeName}`,
          );
        }
      }
    });

    if (duplicatesInCsv.size > 0) {
      let duplicatesArray = Array.from(duplicatesInCsv).map((key) => {
        let [option, attribute] = key.split(':');
        return `${option} → ${attribute}`;
      });
      throw new Error(
        this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.duplicate-in-csv-error',
          {
            firstFourDuplicates: duplicatesArray.slice(0, 4).join(', '),
            remainingCount: duplicatesArray.length > 4 ? duplicatesArray.length - 4 : 0,
          },
        ),
      );
    }

    if (duplicatesWithExisting.size > 0) {
      let duplicatesArray = Array.from(duplicatesWithExisting).map((key) => {
        let [option, attribute] = key.split(':');
        return `${option} → ${attribute}`;
      });
      throw new Error(
        this.intl.t(
          'settings.conversation-attributes.conditions-import-modal.duplicate-with-existing-error',
          {
            firstFourDuplicates: duplicatesArray.slice(0, 4).join(', '),
            remainingCount: duplicatesArray.length > 4 ? duplicatesArray.length - 4 : 0,
          },
        ),
      );
    }
  }

  private async _parseCsvFile(file: File): Promise<ParseResult<any>> {
    return new EmberPromise((resolve, reject) => {
      csvParser.parse(file, {
        complete: (results) => {
          if (
            results.errors.length &&
            results.errors.some((error) => error.code !== 'UndetectableDelimiter')
          ) {
            reject(results.errors[0]);
            return;
          }
          resolve(results);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  @action
  openHelpArticle(): void {
    safeWindowOpen('https://www.intercom.com/help/en/articles/6546210', '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::ConversationAttributes::ConditionsCsvImport': typeof ConditionsCsvImport;
    'settings/conversation-attributes/conditions-csv-import': typeof ConditionsCsvImport;
  }
}
