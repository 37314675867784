/* import __COLOCATED_TEMPLATE__ from './stepper-item.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';
import type { InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface Step {
  id: string;
  title: string;
  description?: string;
  button?: {
    icon: InterfaceIconName;
    text: string;
    disabled?: boolean;
    href: string;
    isExternal?: boolean;
  };
}

interface Signature {
  Args: {
    step: Step;
    isLast: boolean;
  };
}

const StandaloneCommonStepperItem = templateOnlyComponent<Signature>();
export default StandaloneCommonStepperItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::StepperItem': typeof StandaloneCommonStepperItem;
    'standalone/common/stepper-item': typeof StandaloneCommonStepperItem;
  }
}
