/* import __COLOCATED_TEMPLATE__ from './integrate.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Signature {
  Args: {
    onNext?: () => void;
    openSection: string | null;
    setSection?: (section: string | null) => void;
  };
}

export default class IntegrateComponent extends Component<Signature> {
  @tracked isUpdatingFinAgent = false;
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  @action
  goToWorkflow() {
    if (this.args.onNext) {
      this.args.onNext();
    }
  }

  @action
  setSection(section: string | null) {
    this.args.setSection?.(section);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::Integrate': typeof IntegrateComponent;
  }
}
