/* import __COLOCATED_TEMPLATE__ from './basket-year-discount-info.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import templateOnlyComponent from '@ember/component/template-only';

const BasketYearDiscountInfo = templateOnlyComponent<Signature>();

interface Signature {
  Args: {
    programName: string;
    yearNum: number;
    discountAmount: number;
    greenPill: boolean;
  };
}

export default BasketYearDiscountInfo;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::EarlyStage::BasketYearDiscountInfo': typeof BasketYearDiscountInfo;
    'signup/teams/pricing5/components/early-stage/basket-year-discount-info': typeof BasketYearDiscountInfo;
  }
}
